import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandSeparatorWithDecimals'
})
export class ThousandSeparatorWithDecimalsPipe implements PipeTransform {

  transform(nStr: any): any {
    if (nStr === '' || nStr == undefined) { return ''; }
    let x, x1, x2, rgx;

    // Sayıyı önce ondalıklı sayıya çeviriyoruz ki toFixed() ile işlem yapılabilsin
    nStr = parseFloat(nStr).toFixed(2);

    nStr += '';  // String olarak işlem yapıyoruz
    x = nStr.split('.');
    x1 = x[0];
    x2 = x[1];  // Kuruş kısmını (ondalık kısmı) alıyoruz
    rgx = /(\d+)(\d{3})/;

    // Binlik ayraç ekleniyor
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }

    return x1 + ',' + (x2 ? x2 : '00');
  }
}
