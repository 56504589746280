import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError as observableThrowError, Observable } from "rxjs";
import { Device } from "../../models/entity/device";
import { DevicePreference } from "app/models/entity/devicePreference";
import { BaseRestService } from "../base-rest.service";
import { SessionStorageService } from "../session-storage.service";

@Injectable({
  providedIn: "root"
})
export class DeviceService extends BaseRestService {
  constructor(protected httpClient: HttpClient, protected sessionStorageService: SessionStorageService) {
    super(httpClient, sessionStorageService);
  }


  getDevices(): Observable<Device[]> {
    const url = `/devices`;
    return this.httpGet<Device[]>(url).pipe(catchError(this.errorHandler));
  }

  getDeviceById(id) {
    const url = `/devices/${id}`;
    return this.httpGet(url).pipe(catchError(this.errorHandler));
  }

  getCurrentDevice() {
    const url = `/devices/current-device`;
    return this.httpGet(url).pipe(catchError(this.errorHandler));
  }

  getCurrentDeviceVoipInternal() {
    const url = `/devices/current-device/voip-internal`;
    return this.httpGet(url).pipe(catchError(this.errorHandler));
  }

  addDevice(data) {
    const url = `/devices`;
    return this.httpPost(url, data).pipe(catchError(this.errorHandler));
  }

  updateDevice(id, data): Observable<Device> {
    const url = `/devices/${id}`;
    return this.httpPut<Device>(url, data)
      .pipe(catchError(this.errorHandler));
  }
  getCurrentDevicePreferences(id): Observable<DevicePreference[]> {
    const url = `/devices/${id}/preferences`;
    return this.httpGet<DevicePreference[]>(url).pipe(catchError(this.errorHandler));
  }

  updateDevicePreference(id, data): Observable<DevicePreference> {
    const url = `/devices/${id}/preferences/merge`;
    return this.httpPost<DevicePreference>(url, data)
      .pipe(catchError(this.errorHandler));
  }

  deleteDevice(id) {
    const url = `/devices/${id}`;
    return this.httpDelete(url).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    if (error.message) return observableThrowError(error);
    else return observableThrowError(error || " Server Error");
  }
}
