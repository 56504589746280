export enum WebImageUrlCategoryTypeEnum {
  BANNER = "BANNER",
  CAMPAIGN = "CAMPAIGN",
  SERVICES = "SERVICES",
  SPLASH_SLIDE = "SPLASH_SLIDE"
}
export const WebImageUrlCategoryTypeEnumValues = [
  WebImageUrlCategoryTypeEnum.BANNER,
  WebImageUrlCategoryTypeEnum.CAMPAIGN,
  WebImageUrlCategoryTypeEnum.SERVICES,
  WebImageUrlCategoryTypeEnum.SPLASH_SLIDE,
]
