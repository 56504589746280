import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { throwError as observableThrowError, Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { PosDevice } from "../../models/entity/posDevice";
import { SelectItemIdDto } from "../../models/dto/selectItemIdDto";
import { RequestCacheService } from "../core/request-cache.service";
import { StoreView } from "../../models/dto/storeView";
import { SmsSettingEntity } from "../../models/entity/sms/sms-setting-entity";
import { ReceiptSetting } from "../../models/entity/receiptSetting";
import { BaseRestService } from "../base-rest.service";
import { SmsTemplateEntity } from "app/models/entity/sms/sms-template-entity";
import { ServiceCar } from "app/models/entity/serviceCar";
import { StoreTemplate } from "app/models/entity/store/store-template";
import { StoreHangerTypeView } from "app/models/dto/store/store-hanger-type-view.model";
import { MailSettingModel } from "app/models/entity/store/mail-setting.model";
import { StockTrackingEntity } from "../../models/entity/order/stock-tracking.entity";
import { BillingSettingModel } from "app/models/entity/billing/billing-setting.model";
import { NotificationPreferenceEntity } from "app/models/entity/notification/notification-preference-entity";
import { PushNotificationTemplateEntity } from "app/models/entity/notification/push-notification-template.entity";
import { SegmentValueEntity } from 'app/models/entity/segment/segment-value.entity';
import { CashRegisterEntity } from 'app/models/entity/cash-register/cash-register-entity';
import { PayrollSettingEntity } from "app/models/entity/payroll/payroll-setting.entity";
import { PaymentType } from "app/models/entity/paymentType";
import { Device } from "app/models/entity/device";
import { SessionStorageService } from "../session-storage.service";
import { OrderItemType } from "app/models/entity/product/orderItemType";
import { StoreSetting } from "app/models/entity/store/storeSetting";
import { Address } from "app/models/entity/address";

@Injectable({
  providedIn: 'root'
})
export class StoreCacheService extends BaseRestService {
  constructor(protected httpClient: HttpClient, protected sessionStorageService: SessionStorageService,
    private cache: RequestCacheService) {
    super(httpClient, sessionStorageService);
  }

  private getCachedResponse<T>(url: string): Observable<T> {
    const cachedResponse = this.cache.get(url);
    if (cachedResponse) {
      return of(cachedResponse);
    }
    return null;
  }

  private fetchAndCache<T>(url: string): Observable<T> {
    return this.httpGet<T>(url).pipe(
      tap((response) => {
        this.cache.put(url, response);
      }),
      catchError(this.errorHandler)
    );
  }

  getStores(): Observable<StoreView[]> {
    const url = `/stores`;
    const cachedResponse = this.getCachedResponse<StoreView[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<StoreView[]>(url);
  }

  getStoreById(id: number): Observable<any> {
    const url = `/stores/${id}`;
    const cachedResponse = this.getCachedResponse<any>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<any>(url);
  }

  getStoreSelectList(storeId: number, type: string): Observable<SelectItemIdDto[]> {
    const url = `/stores/${storeId}/allowed?type=${type}`;
    const cachedResponse = this.getCachedResponse<SelectItemIdDto[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<SelectItemIdDto[]>(url);
  }

  getAllowedStoreSelectList(storeId: number, type: string): Observable<SelectItemIdDto[]> {
    const url = `/stores/${storeId}/store-relation?type=${type}`;
    const cachedResponse = this.getCachedResponse<SelectItemIdDto[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<SelectItemIdDto[]>(url);
  }

  getStoreMainProcessTypes(storeId: number): Observable<SelectItemIdDto[]> {
    const url = `/stores/${storeId}/main-process-types`;
    const cachedResponse = this.getCachedResponse<SelectItemIdDto[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<SelectItemIdDto[]>(url);
  }

  getStoreMainProcessTypesForWeb(storeId: number): Observable<OrderItemType[]> {
    const url = `/stores/${storeId}/main-process-types-web`;
    const cachedResponse = this.getCachedResponse<OrderItemType[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<OrderItemType[]>(url);
  }

  getPosDevices(storeId: number): Observable<PosDevice[]> {
    const url = `/stores/${storeId}/pos-devices`;
    const cachedResponse = this.getCachedResponse<PosDevice[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<PosDevice[]>(url);
  }

  getServiceCars(storeId: number): Observable<ServiceCar[]> {
    const url = `/stores/${storeId}/service-cars`;
    const cachedResponse = this.getCachedResponse<ServiceCar[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<ServiceCar[]>(url);
  }

  getDevices(storeId: number): Observable<Device[]> {
    const url = `/stores/${storeId}/devices`;
    const cachedResponse = this.getCachedResponse<Device[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<Device[]>(url);
  }

  getPaymentTypes(storeId: number): Observable<PaymentType[]> {
    const url = `/stores/${storeId}/payment-types`;
    const cachedResponse = this.getCachedResponse<PaymentType[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<PaymentType[]>(url);
  }

  getPaymentTypesForCashRegister(storeId: number): Observable<PaymentType[]> {
    const url = `/stores/${storeId}/payment-types-cash-register`;
    const cachedResponse = this.getCachedResponse<PaymentType[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<PaymentType[]>(url);
  }

  getSetting(storeId: number): Observable<StoreSetting> {
    const url = `/stores/${storeId}/setting`;
    const cachedResponse = this.getCachedResponse<StoreSetting>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<StoreSetting>(url);
  }

  getPayrollSetting(storeId: number): Observable<PayrollSettingEntity> {
    const url = `/stores/${storeId}/payroll-setting`;
    const cachedResponse = this.getCachedResponse<PayrollSettingEntity>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<PayrollSettingEntity>(url);
  }

  getSmsSetting(storeId: number): Observable<SmsSettingEntity> {
    const url = `/stores/${storeId}/sms-setting`;
    const cachedResponse = this.getCachedResponse<SmsSettingEntity>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<SmsSettingEntity>(url);
  }

  getMailSetting(storeId: number): Observable<MailSettingModel> {
    const url = `/stores/${storeId}/mail-setting`;
    const cachedResponse = this.getCachedResponse<MailSettingModel>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<MailSettingModel>(url);
  }

  getReceiptSettingByReceiptType(storeId: number, receiptType: string): Observable<ReceiptSetting> {
    const url = `/stores/${storeId}/receipt-setting?type=${receiptType}`;
    const cachedResponse = this.getCachedResponse<ReceiptSetting>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<ReceiptSetting>(url);
  }

  getLabelSetting(storeId: number): Observable<ReceiptSetting> {
    const url = `/stores/${storeId}/label-setting`;
    const cachedResponse = this.getCachedResponse<ReceiptSetting>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<ReceiptSetting>(url);
  }

  getSmsTemplates(storeId: number): Observable<SmsTemplateEntity[]> {
    const url = `/stores/${storeId}/sms-templates`;
    const cachedResponse = this.getCachedResponse<SmsTemplateEntity[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<SmsTemplateEntity[]>(url);
  }

  getPushNotificationTemplates(storeId: number): Observable<PushNotificationTemplateEntity[]> {
    const url = `/stores/${storeId}/push-notification-templates`;
    const cachedResponse = this.getCachedResponse<PushNotificationTemplateEntity[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<PushNotificationTemplateEntity[]>(url);
  }

  getNotificationPreferences(storeId: number): Observable<NotificationPreferenceEntity[]> {
    const url = `/stores/${storeId}/notification-preferences`;
    const cachedResponse = this.getCachedResponse<NotificationPreferenceEntity[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<NotificationPreferenceEntity[]>(url);
  }

  getStoreTemplates(storeId: number): Observable<StoreTemplate[]> {
    const url = `/stores/${storeId}/templates`;
    const cachedResponse = this.getCachedResponse<StoreTemplate[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<StoreTemplate[]>(url);
  }

  getStoreHangerTypes(storeId: number): Observable<StoreHangerTypeView[]> {
    const url = `/stores/${storeId}/hanger-types`;
    const cachedResponse = this.getCachedResponse<StoreHangerTypeView[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<StoreHangerTypeView[]>(url);
  }

  getStockTrackings(storeId: number): Observable<StockTrackingEntity[]> {
    const url = `/stores/${storeId}/stock-trackings`;
    const cachedResponse = this.getCachedResponse<StockTrackingEntity[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<StockTrackingEntity[]>(url);
  }

  getBillingSetting(storeId: number): Observable<BillingSettingModel> {
    const url = `/stores/${storeId}/billing-setting`;
    const cachedResponse = this.getCachedResponse<BillingSettingModel>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<BillingSettingModel>(url);
  }

  getSegmentValuesByStoreId(storeId: number, segmentId: number): Observable<SegmentValueEntity[]> {
    const url = `/stores/${storeId}/segment-values?segment-id=${segmentId}`;
    const cachedResponse = this.getCachedResponse<SegmentValueEntity[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<SegmentValueEntity[]>(url);
  }

  getCashRegistersByStoreId(storeId: number): Observable<CashRegisterEntity[]> {
    const url = `/stores/${storeId}/cash-registers`;
    const cachedResponse = this.getCachedResponse<CashRegisterEntity[]>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<CashRegisterEntity[]>(url);
  }

  getAddressByStoreId(storeId: number): Observable<Address> {
    const url = `/stores/${storeId}/address`;
    const cachedResponse = this.getCachedResponse<Address>(url);
    return cachedResponse ? cachedResponse : this.fetchAndCache<Address>(url);
  }

  errorHandler(error: HttpErrorResponse) {
    return observableThrowError(error);
  }
}
