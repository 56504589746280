import { BaseEntity } from "../baseEntity";

export class SmsTemplateEntity extends BaseEntity {
  id?: number;
  storeId?: number;
  name?: string;
  message?: string;
  usageFor?: string;
}

