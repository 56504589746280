
export { };

declare global {
  interface Array<T> {
    empty: () => Array<any>;
    isEmpty: () => boolean;
    each: (callback: Function) => void;
    contains: (partial: string, strict: boolean) => boolean;
    indexOfPartial: (partial: string) => number;
    toObjectArray: (objName: string) => Array<any>;

    sortBy(field: string);
    sortByDesc(field: string);
    sortByValues(field: string, values: string[]);
    sortByMultiple(fields: string[]);
    firstOrDefault(item: any);
    where(item: any);
    replace(item: any, newItem: any);
    remove(item: any);
    removeRange(item: any[]);
    pushRange(item: any[]);
    sumBy<T>(this: T[], selector: (x: T) => number): number;
    sumByField(field: string): number;
    groupAndSum(groupKeys, sumKeys);
    groupBy: (key: string, value: string) => Array<any>;
    groupBySumMultiple: (key: string, value: string[]) => Array<any>;
    groupByKeysSumMultiple: (key: string[], value: string[]) => Array<any>;
    getValuesByKey: (key: string) => Array<any>;
    distinct: () => Array<any>;



    getLastValue: () => any;


  }
}

Array.prototype.empty = function (): Array<any> {
  return this.splice(0, this.length);
};

Array.prototype.isEmpty = function (): any {
  if (this.length === 0) {
    return true;
  }
  return false;
};
Array.prototype.getLastValue = function (): boolean {
  if (this.length === 0) {
    return null;
  }
  return this[this.length - 1];
};



Array.prototype.each = function (callback: Function): void {
  for (let i: number = 0; i < this.length; i++) {
    callback(i, this[i]);
  }
};


Array.prototype.contains = function (partial: string, strict: boolean): boolean {
  for (let i: number = 0; i < this.length; i++) {
    if (!strict && this[i].contains(partial)) {
      return true;
    }
    if (strict && this[i] === partial) {
      return true;
    }
  }
  return false;
};

Array.prototype.indexOfPartial = function (partial: string): number {
  for (let i: number = 0; i < this.length; i++) {
    if (this[i].contains(partial)) {
      return i;
    }
  }
  return -1;
};

Array.prototype.toObjectArray = function (objName: string): Array<any> {
  if (objName === undefined || objName === null) {
    throw "Error: Property name must be provided for conversion.";
  }
  let items: any = this;
  if (
    typeof items[0] === "string" ||
    typeof items[0] === "number" ||
    typeof items[0] === "boolean"
  ) {
    for (let i: number = 0; i < items.length; i++) {
      let val: any = items[i];
      items[i] = {};
      items[i][objName] = val;
    }
    return items;
  } else {
    return this;
  }
};

Array.prototype.sortBy = function (p) {
  return this.slice(0).sort((a, b) => (a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0);
}

Array.prototype.sortByDesc = function (p) {
  return this.slice(0).sort((b, a) => (a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0);
}

Array.prototype.sortByValues = function (field, values) {
  return this.sort((a, b) => values.indexOf(a[field]) - values.indexOf(b[field]));
}

Array.prototype.sortByMultiple = function (fields: string[]): any[] {
  return this.sort((a, b) => {
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];

      if (a[field] > b[field]) {
        return 1; // Artan sırada sıralama
      }
      if (a[field] < b[field]) {
        return -1; // Artan sırada sıralama
      }
    }
    return 0; // Eğer tüm alanlar eşitse sıralama yapılmaz
  });
};


if (!Array.prototype.firstOrDefault) {
  Array.prototype.firstOrDefault = function (predicate: (item: any) => boolean) {
    for (var i = 0; i < (<Array<any>>this).length; i++) {
      let item = (<Array<any>>this)[i];
      if (predicate(item)) {
        return item;
      }
    }
    return null;
  }
}

if (!Array.prototype.where) {
  Array.prototype.where = function (predicate: (item: any) => boolean) {
    let result = [];
    for (var i = 0; i < (<Array<any>>this).length; i++) {
      let item = (<Array<any>>this)[i];
      if (predicate(item)) {
        result.push(item);
      }
    }
    return result;
  }
}

if (!Array.prototype.remove) {
  Array.prototype.remove = function (item: any): boolean {
    let index = (<Array<any>>this).indexOf(item);
    if (index >= 0) {
      (<Array<any>>this).splice(index, 1);
      return true;
    }
    return false;
  }
}

if (!Array.prototype.replace) {
  Array.prototype.replace = function (item: any, newItem: any): boolean {
    let index = (<Array<any>>this).indexOf(item);
    if (index >= 0) {
      (<Array<any>>this)[index] = newItem;
      return true;
    }
    return false;
  }
}

if (!Array.prototype.removeRange) {
  Array.prototype.removeRange = function (items: any[]): void {
    for (var i = 0; i < items.length; i++) {
      (<Array<any>>this).remove(items[i]);
    }
  }
}



if (!Array.prototype.pushRange) {
  Array.prototype.pushRange = function (items: any[]): void {
    for (var i = 0; i < items.length; i++) {
      (<Array<any>>this).push(items[i]);
    }
  }
}


Array.prototype.sumBy = function <T>(this: T[], selector: (x: T) => number) {
  return this.reduce((pre, cur) => pre + selector(cur), 0);
}
Array.prototype.sumByField = function (p) {

  let sum: number = 0;
  this.forEach(a => sum += a[p] ?? 0);

  return sum;
}


Array.prototype.getValuesByKey = function (key: string): any[] {
  var list = [];

  this.forEach(function (element) {
    list.push(element[key]);
  });

  return list;
};

Array.prototype.distinct = function (): any[] {

  var list = [];
  for (var i = 0, l = this.length; i < l; i++)
    if (list.indexOf(this[i]) === -1 && this[i] !== '')
      list.push(this[i]);
  return list;
};


Array.prototype.groupBy = function (groupKey: string, sumKey: string): any[] {
  var list = [];

  this.reduce(function (res, value) {
    if (!res[value[groupKey]]) {
      res[value[groupKey]] = { key: value[groupKey], sumValue: 0 };
      list.push(res[value[groupKey]])
    }
    res[value[groupKey]].sumValue += value[sumKey];
    return res;
  }, {});
  return list;
};

Array.prototype.groupBySumMultiple = function (groupKey: string, sumKeys: string[]): any[] {
  var list = [];

  this.reduce(function (res, value) {
    if (!res[value[groupKey]]) {
      res[value[groupKey]] = { key: value[groupKey] };
      sumKeys.forEach(function (k) { res[value[groupKey]][k] = 0; });

      list.push(res[value[groupKey]])
    }

    sumKeys.forEach(function (k) { res[value[groupKey]][k] += value[k]; });

    return res;
  }, {});
  return list;
};

Array.prototype.groupByKeysSumMultiple = function (groupKeys: string[], sumKeys: string[]): any[] {
  var list = [];

  this.reduce(function (res, value) {
    var key = groupKeys.map(function (k) { return value[k]; }).join('|');

    if (!res[key]) {
      res[key] = { key: key };
      groupKeys.forEach(function (k) { res[key][k] = value[k]; });
      sumKeys.forEach(function (k) { res[key][k] = 0; });

      list.push(res[key])
    }

    sumKeys.forEach(function (k) { res[key][k] += value[k] ?? 0; });

    return res;
  }, {});
  return list;
};


Array.prototype.groupAndSum = function (groupKeys, sumKeys): any[] {
  var hash = Object.create(null),
    grouped = [];
  this.forEach(function (o) {
    var key = groupKeys.map(function (k) { return o[k]; }).join('|');
    if (!hash[key]) {
      hash[key] = Object.keys(o).reduce((result, key) => {
        result[key] = o[key];
        if (sumKeys.includes(key))
          result[key] = 0;
        return result;
      }, {}); //map_(o) //{ shape: o.shape, color: o.color, used: 0, instances: 0 };
      grouped.push(hash[key]);
    }
    sumKeys.forEach(function (k) { hash[key][k] += o[k]; });
  });
  return grouped;
};


// if (!Array.prototype.orderBy) {
//   Array.prototype.orderBy = function (propertyExpression: (item: any) => any) {
//     let result = [];
//     var compareFunction = (item1: any, item2: any): number => {
//       if (propertyExpression(item1) > propertyExpression(item2)) return 1;
//       if (propertyExpression(item2) > propertyExpression(item1)) return -1;
//       return 0;
//     }
//     for (var i = 0; i < (<Array<any>>this).length; i++) {
//       return (<Array<any>>this).sort(compareFunction);

//     }
//     return result;
//   }
// }

// if (!Array.prototype.orderByDescending) {
//   Array.prototype.orderByDescending = function (propertyExpression: (item: any) => any) {
//     let result = [];
//     var compareFunction = (item1: any, item2: any): number => {
//       if (propertyExpression(item1) > propertyExpression(item2)) return -1;
//       if (propertyExpression(item2) > propertyExpression(item1)) return 1;
//       return 0;
//     }
//     for (var i = 0; i < (<Array<any>>this).length; i++) {
//       return (<Array<any>>this).sort(compareFunction);
//     }
//     return result;
//   }
// }

// if (!Array.prototype.orderByMany) {
//   Array.prototype.orderByMany = function (propertyExpressions: [(item: any) => any]) {
//     let result = [];
//     var compareFunction = (item1: any, item2: any): number => {
//       for (var i = 0; i < propertyExpressions.length; i++) {
//         let propertyExpression = propertyExpressions[i];
//         if (propertyExpression(item1) > propertyExpression(item2)) return 1;
//         if (propertyExpression(item2) > propertyExpression(item1)) return -1;
//       }
//       return 0;
//     }
//     for (var i = 0; i < (<Array<any>>this).length; i++) {
//       return (<Array<any>>this).sort(compareFunction);
//     }
//     return result;
//   }
// }

// if (!Array.prototype.orderByManyDescending) {
//   Array.prototype.orderByManyDescending = function (propertyExpressions: [(item: any) => any]) {
//     let result = [];
//     var compareFunction = (item1: any, item2: any): number => {
//       for (var i = 0; i < propertyExpressions.length; i++) {
//         let propertyExpression = propertyExpressions[i];
//         if (propertyExpression(item1) > propertyExpression(item2)) return -1;
//         if (propertyExpression(item2) > propertyExpression(item1)) return 1;
//       }
//       return 0;
//     }
//     for (var i = 0; i < (<Array<any>>this).length; i++) {
//       return (<Array<any>>this).sort(compareFunction);
//     }
//     return result;
//   }
// }
