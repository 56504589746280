import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-unit-badge',
  templateUrl: './unit-badge.component.html',
  styleUrls: ['./unit-badge.component.css']
})
export class UnitBadgeComponent implements OnInit {

  @Input() id:number;
  constructor() { }

  ngOnInit() {
  }

}
