<!-- format.align==='right'?'text-end':'text-center'" -->

<div class="d-flex bd-highlight justify-content-between">
  <!-- todo özhan -->
  <!-- <div class="d-block d-sm-none flex-fill bd-highlight fw-bold">{{ format.header }}:</div> -->

  <div [ngSwitch]="format.dataType" [ngClass]="{'text-left':format.align==='left',
                'text-center':(format.align==='center' || format.align===undefined),
                'fw-bold':format.isBold,
                'flex-fill bd-highlight text-end' :true
              }">


    <span *ngSwitchCase="'date'">{{ data | date: format.dateFormat }}</span>
    <span *ngSwitchCase="'phone'" data-private>{{ data | phoneDisplayFormat }}</span>
    <span *ngSwitchCase="'number'">{{(data| thousandSeparator)}}</span>

    <span *ngSwitchCase="'decimal'">{{data.round(format.roundDecimal?format.roundDecimal:2)| thousandSeparatorWithDecimals}}</span>
    <span *ngSwitchCase="'decimal_or_int'">{{data.round(format.roundDecimal?format.roundDecimal:2)| thousandSeparator}}</span>
    <span *ngSwitchCase="'money'">{{data | thousandSeparatorWithDecimals }}</span>
    <span *ngSwitchCase="'translate'">{{ data? (format.translateKey+'.'+data | translate):'' }}</span>

    <app-boolean-badge *ngSwitchCase="'boolean'" [value]="data"> </app-boolean-badge>

    <app-status-badge *ngSwitchCase="'status'" [id]="data"> </app-status-badge>

    <app-unit-badge *ngSwitchCase="'unitId'" [id]="data"></app-unit-badge>

    <app-order-status-badge *ngSwitchCase="'orderStatus'" [id]="data"></app-order-status-badge>
    <app-order-item-status-badge *ngSwitchCase="'orderItemStatus'" [id]="data"></app-order-item-status-badge>
    <app-pack-status-badge *ngSwitchCase="'packStatus'" [id]="data"></app-pack-status-badge>

    <span *ngSwitchCase="'imageUrl'"><img *ngIf="data" src="{{data?.startsWith('http')?data:(format.imageBaseUrl+data)}}" height="50" />
    </span>
    <span *ngSwitchCase="'color'" [style.background-color]="data">{{ data }}
    </span>

    <app-sms-status-badge *ngSwitchCase="'smsStatus'" [value]="data"> </app-sms-status-badge>
    <app-billing-sender-state-badge *ngSwitchCase="'billingSenderState'" [value]="data"> </app-billing-sender-state-badge>
    <app-development-demand-status-badge *ngSwitchCase="'developmentStatus'" [value]="data"></app-development-demand-status-badge>

    <app-tags-badge *ngSwitchCase="'tags'" [value]="data"> </app-tags-badge>


    <app-online-collection-status-badge *ngSwitchCase="'onlineCollectionStatus'" [value]="data"> </app-online-collection-status-badge>

    <app-dispatch-status-badge *ngSwitchCase="'dispatchStatus'" [value]="data"> </app-dispatch-status-badge>

    <app-employee-payment-payment-category-badge *ngSwitchCase="'employeePaymentTransactionType'" [value]="data"></app-employee-payment-payment-category-badge>
    <span *ngSwitchCase="'truncate'" [ngbPopover]="data" triggers="mouseenter:mouseleave" placement="top" container="body">
      {{ data | truncate : format.maxDisplayLength }}
    </span>

    <span *ngSwitchDefault>
      <span *ngIf="format?.isPrivateData">{{ data }}</span>
      <span *ngIf="!format?.isPrivateData">{{ data }}</span>
    </span>
  </div>

</div>