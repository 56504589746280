<div class="btn-group btn-group-sm m-1" dropdown>
  <button id="button-nested-print" dropdownToggle type="button" class="btn btn-outline-primary dropdown-toggle" aria-controls="dropdown-nested-print">
    <i class="fa fa-phone" aria-hidden="true"></i>
  </button>

  <ul id="dropdown-nested-print" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-nested-print">

    <app-whatsapp-widget [phoneNumber]="phoneNumber" [customerId]="customerId"></app-whatsapp-widget>
    <app-voip-call-start-widget [phoneNumber]="phoneNumber" [customerId]="customerId"></app-voip-call-start-widget>

  </ul>
</div>