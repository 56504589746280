import { Component, OnInit } from '@angular/core';
import { PrinterService } from 'app/services/core/printer.service';
import { LocalStorageService } from 'app/services/local-storage.service';


@Component({
  selector: 'app-local-app-download',
  templateUrl: './local-app-download.component.html',
  styleUrls: ['./local-app-download.component.css']
})
export class LocalAppDownloadComponent implements OnInit {
  showErrorMessage: boolean = false;
  isWindowsUser: boolean = this.isWindows();

  constructor(private printerService: PrinterService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    if (this.isWindowsUser)
      this.initCheck();
  }

  async initCheck() {
    const today = new Date().toISOString().slice(0, 10); // YYYY-MM-DD formatında bugünün tarihi
    const lastCheck = this.localStorageService.getString('lastLocalAppCheck');

    if (!lastCheck || lastCheck !== today) {
      await this.checkDeviceCode();
      this.localStorageService.setString('lastLocalAppCheck', today);
    }
  }

  async checkDeviceCode() {
    if (this.isWindowsUser) {
      try {
        this.showErrorMessage = false;
        const res = await this.printerService.getComputerIdentifier().toPromise();
        // Servis aktifse ek işlemler burada yapılabilir.
      } catch (err) {
        this.showErrorMessage = true;
      }
    }
  }

  isWindows(): boolean {
    return navigator.platform.indexOf('Win') > -1;
  }

  downloadAndInstall() {
    const downloadUrl = 'https://aktifmusteri.com/download/setup/aktifmusteri.exe';
    window.open(downloadUrl, '_blank');
  }

  closeErrorMessage() {
    this.showErrorMessage = false;
  }
}
