export enum SmsSpecialFieldEnum {
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  CUSTOMER_MOBILE_PHONE = 'CUSTOMER_MOBILE_PHONE',
  CUSTOMER_PHONE1 = 'CUSTOMER_PHONE1',
  CUSTOMER_PHONE2 = 'CUSTOMER_PHONE2',
  CUSTOMER_DISCOUNT_RATE = 'CUSTOMER_DISCOUNT_RATE',

  // CUSTOMER_AVAILABLE_BALANCE = 'CUSTOMER_AVAILABLE_BALANCE',
  CUSTOMER_ORDER_DEBT = 'CUSTOMER_ORDER_DEBT',
  CUSTOMER_BONUS_BALANCE = 'CUSTOMER_BONUS_BALANCE',
  DEBTED_ORDER_COUNT = 'DEBTED_ORDER_COUNT',
  UNDELIVERED_ORDER_COUNT = 'UNDELIVERED_ORDER_COUNT',


  CUSTOMER_AVAILABLE_BALANCE_WITH_LABEL = 'CUSTOMER_AVAILABLE_BALANCE_WITH_LABEL',
  CURRENT_ACCOUNT_BALANCE = 'CURRENT_ACCOUNT_BALANCE',
  CUSTOMER_ORDER_DEBT_WITH_LABEL = 'CUSTOMER_ORDER_DEBT_WITH_LABEL',

  STORE_NAME = 'STORE_NAME',
  STORE_WEB_ADDRESS = 'STORE_WEB_ADDRESS',
  STORE_MOBILE_PHONE = 'STORE_MOBILE_PHONE',
  STORE_PHONE1 = 'STORE_PHONE1',
  STORE_PHONE2 = 'STORE_PHONE2',
  STORE_ADDRESS = 'STORE_ADDRESS',
  STORE_WORKING_START_HOUR = 'STORE_WORKING_START_HOUR',
  STORE_WORKING_END_HOUR = 'STORE_WORKING_END_HOUR',

  PACKAGE_CAMPAIGN_REMAINING = 'PACKAGE_CAMPAIGN_REMAINING',

  ORDER_ID = 'ORDER_ID',
  ORDER_TOTAL_PRICE = 'ORDER_TOTAL_PRICE',
  ORDER_NET_PRICE = 'ORDER_NET_PRICE',
  ORDER_DISCOUNT_PRICE = 'ORDER_DISCOUNT_PRICE',
  ORDER_SERVICE_FEE = 'ORDER_SERVICE_FEE',
  ORDER_HANGER_PRICE = 'ORDER_HANGER_PRICE',
  ORDER_FINAL_TOTAL_PRICE = 'ORDER_FINAL_TOTAL_PRICE',

  ORDER_DISCOUNT_RATE = 'ORDER_DISCOUNT_RATE',
  ORDER_PRICE_SUMMARY = 'ORDER_PRICE_SUMMARY',
  ORDER_PAID_AMOUNT = 'ORDER_PAID_AMOUNT',
  ORDER_REMAINING_AMOUNT = 'ORDER_REMAINING_AMOUNT',
  ORDER_DELIVERY_DATE = 'ORDER_DELIVERY_DATE',
  ORDER_PICKUP_DATE = 'ORDER_PICKUP_DATE',
  ORDER_DELIVERED_DATE = 'ORDER_DELIVERED_DATE',
  ORDER_PICKUP_ADDRESS = 'ORDER_PICKUP_ADDRESS',
  ORDER_ITEM_QUANTITY = 'ORDER_ITEM_QUANTITY',
  ORDER_ITEM_UNDELIVERED_COUNT = 'ORDER_ITEM_UNDELIVERED_COUNT',
  ORDER_ITEM_DELIVERED_COUNT = 'ORDER_ITEM_DELIVERED_COUNT',

  PRODUCT_NAME = 'PRODUCT_NAME',
  ORDER_TRACKING_LINK = 'ORDER_TRACKING_LINK',
  ORDER_RECEIPT_LINK = 'ORDER_RECEIPT_LINK',

  LOYALTY_CARD_TYPE = "LOYALTY_CARD_TYPE",
  LOYALTY_CARD_MINUMUM_CREDIT_AMOUNT = "LOYALTY_CARD_MINUMUM_CREDIT_AMOUNT",
  LOYALTY_CARD_DISCOUNT_RATE = "LOYALTY_CARD_DISCOUNT_RATE",
  LOYALTY_CARD_VALIDITY_DAY = "LOYALTY_CARD_VALIDITY_DAY"

  // List<string> keys = new List<string>() { TemplateKeywordConstant.PRODUCT_NAME,
  //   TemplateKeywordConstant.PRODUCT_QUANTITY,
  //   TemplateKeywordConstant.PRODUCT_NUMBER_OF_UNIT,
  //   TemplateKeywordConstant.PRODUCT_NUMBER_OF_UNIT_TOTAL,
  //   TemplateKeywordConstant.PRODUCT_TOTAL_PRICE,
  //   TemplateKeywordConstant.PROCESS_TYPE,
  //   TemplateKeywordConstant.PROCESS_TYPE_WITH_PRICE,
  //   TemplateKeywordConstant.PROCESS_TYPE_WITH_TOTAL_PRICE




}
export const SmsSpecialFieldEnumForCustomValues = [
  SmsSpecialFieldEnum.CUSTOMER_NAME,
  SmsSpecialFieldEnum.CUSTOMER_MOBILE_PHONE,
  SmsSpecialFieldEnum.CUSTOMER_PHONE1,
  SmsSpecialFieldEnum.CUSTOMER_PHONE2,
  SmsSpecialFieldEnum.CUSTOMER_DISCOUNT_RATE,
  SmsSpecialFieldEnum.CUSTOMER_ORDER_DEBT,
  SmsSpecialFieldEnum.CUSTOMER_BONUS_BALANCE,
  SmsSpecialFieldEnum.DEBTED_ORDER_COUNT,
  SmsSpecialFieldEnum.STORE_NAME,
  SmsSpecialFieldEnum.STORE_WEB_ADDRESS,
  SmsSpecialFieldEnum.STORE_MOBILE_PHONE,
  SmsSpecialFieldEnum.STORE_PHONE1,
  SmsSpecialFieldEnum.STORE_PHONE2,
  SmsSpecialFieldEnum.STORE_ADDRESS,
  SmsSpecialFieldEnum.STORE_WORKING_START_HOUR,
  SmsSpecialFieldEnum.STORE_WORKING_END_HOUR,

  SmsSpecialFieldEnum.CUSTOMER_AVAILABLE_BALANCE_WITH_LABEL,
  SmsSpecialFieldEnum.CURRENT_ACCOUNT_BALANCE,
  SmsSpecialFieldEnum.CUSTOMER_ORDER_DEBT_WITH_LABEL,
  SmsSpecialFieldEnum.PACKAGE_CAMPAIGN_REMAINING,

  SmsSpecialFieldEnum.LOYALTY_CARD_TYPE,
  SmsSpecialFieldEnum.LOYALTY_CARD_MINUMUM_CREDIT_AMOUNT,
  SmsSpecialFieldEnum.LOYALTY_CARD_DISCOUNT_RATE,
  SmsSpecialFieldEnum.LOYALTY_CARD_VALIDITY_DAY,
]


export const SmsSpecialFieldEnumForOrderValues = [
  SmsSpecialFieldEnum.ORDER_ID,
  SmsSpecialFieldEnum.ORDER_TOTAL_PRICE,
  SmsSpecialFieldEnum.ORDER_NET_PRICE,
  SmsSpecialFieldEnum.ORDER_DISCOUNT_PRICE,
  SmsSpecialFieldEnum.ORDER_SERVICE_FEE,
  SmsSpecialFieldEnum.ORDER_HANGER_PRICE,
  SmsSpecialFieldEnum.ORDER_FINAL_TOTAL_PRICE,
  SmsSpecialFieldEnum.ORDER_DISCOUNT_RATE,
  SmsSpecialFieldEnum.ORDER_PRICE_SUMMARY,
  SmsSpecialFieldEnum.ORDER_PAID_AMOUNT,
  SmsSpecialFieldEnum.ORDER_REMAINING_AMOUNT,
  SmsSpecialFieldEnum.ORDER_DELIVERY_DATE,
  SmsSpecialFieldEnum.ORDER_PICKUP_DATE,
  SmsSpecialFieldEnum.ORDER_DELIVERED_DATE,
  SmsSpecialFieldEnum.ORDER_PICKUP_ADDRESS,
  SmsSpecialFieldEnum.ORDER_ITEM_QUANTITY,
  SmsSpecialFieldEnum.ORDER_ITEM_DELIVERED_COUNT,
  SmsSpecialFieldEnum.ORDER_ITEM_UNDELIVERED_COUNT,
  SmsSpecialFieldEnum.ORDER_RECEIPT_LINK,
  SmsSpecialFieldEnum.ORDER_TRACKING_LINK,
]



