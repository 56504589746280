<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" [ngClass]="
    {
        'defaultdark': color == 'defaultdark',
        'greendark': color == 'greendark',
        'reddark': color == 'reddark',
        'bluedark': color == 'bluedark',
        'purpledark': color == 'purpledark',
        'megnadark': color == 'megnadark',
        'default': color == 'default',
        'green': color == 'green',
        'red': color == 'red',
        'blue': color == 'blue',
        'purple': color == 'purple',
        'megna': color == 'megna',
        'mini-sidebar': showMinisidebar,
        'dark-theme': showDarktheme,
        'rtl-theme': showRtl
    }
">
  <!-- ============================================================== -->
  <!-- Topbar header - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <app-local-app-download></app-local-app-download>


  <app-navigation class="w-100" (toggleSidebar)="toggleSidebar()"></app-navigation>
  <!-- ============================================================== -->
  <!-- Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar" [perfectScrollbar]="config">
      <app-sidebar></app-sidebar>
    </div>
    <!-- End Sidebar scroll-->
  </aside>
  <!-- ============================================================== -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="page-wrapper">
    <!-- <app-breadcrumb></app-breadcrumb> -->
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">

      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
      <!-- ============================================================== -->
      <!-- End Start Page Content -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- Setting Right sidebar -->
      <!-- ============================================================== -->
      <button class="right-side-toggle btn-inverse btn btn-circle btn-lg" (click)="showSettings = !showSettings" *ngIf="false">
        <i class="ti-settings text-white fa-spin"></i>
      </button>
      <div class="right-sidebar" [ngClass]="{'shw-rside': showSettings}" *ngIf="false">
        <div class="slimscrollright">
          <div class="rpanel-title"> Settings
            <span>
              <i class="ti-close right-side-toggle" (click)="showSettings = !showSettings"></i>
            </span>
          </div>
          <div class="r-panel-body" [perfectScrollbar]="config">

            <ul>
              <li class="m-b-20 m-t-20">
                <b>Light Sidebar / Colors</b>
              </li>
              <li>

                <div class="m-b-10">
                  <div class="form-check form-switch">
                    <input class="form-check-input" id="customCheck11" name="radio-stacked" type="radio" value="default" [(ngModel)]="color">
                    <label class="form-check-label" for="customCheck11">Default</label>
                  </div>
                </div>
              </li>
              <li>
                <div class="m-b-10">

                  <div class="form-check form-switch">
                    <input class="form-check-input" id="customCheck22" name="radio-stacked" type="radio" value="green" [(ngModel)]="color">
                    <label class="form-check-label" for="customCheck22">Green</label>
                  </div>
                </div>
              </li>
              <li>
                <div class="m-b-10">
                  <div class="form-check form-switch">
                    <input class="form-check-input" id="customCheck33" name="radio-stacked" type="radio" value="red" [(ngModel)]="color">
                    <label class="form-check-label" for="customCheck33">Red</label>
                  </div>
                </div>
              </li>
              <li>
                <div class="m-b-10">

                  <div class="form-check form-switch">
                    <input class="form-check-input" id="customCheck44" name="radio-stacked" type="radio" value="blue" [(ngModel)]="color">
                    <label class="form-check-label" for="customCheck44">Blue</label>
                  </div>
                </div>
              </li>
              <li>
                <div class="m-b-10">

                  <div class="form-check form-switch">
                    <input class="form-check-input" id="customCheck55" name="radio-stacked" type="radio" value="purple" [(ngModel)]="color">
                    <label class="form-check-label" for="customCheck55">Purple</label>
                  </div>
                </div>
              </li>
              <li>
                <div class="m-b-10">
                  <div class="form-check form-switch">
                    <input class="form-check-input" id="customCheck66" name="radio-stacked" type="radio" value="megna" [(ngModel)]="color">
                    <label class="form-check-label" for="customCheck66">Megna</label>
                  </div>
                </div>
              </li>
            </ul>
            <hr>
            <ul>
              <li class="m-b-20">
                <b>Dark Sidebar / Colors</b>
              </li>
              <li>
                <div class="m-b-10">
                  <div class="form-check form-switch">
                    <input class="form-check-input" id="customCheck77" name="radio-stacked" type="radio" value="defaultdark" [(ngModel)]="color">
                    <label class="form-check-label" for="customCheck77">Default Dark</label>
                  </div>
                </div>
              </li>
              <li>
                <div class="m-b-10">
                  <div class="form-check form-switch">
                    <input class="form-check-input" id="customCheck88" name="radio-stacked" type="radio" value="greendark" [(ngModel)]="color">
                    <label class="form-check-label" for="customCheck88">Green Dark</label>
                  </div>
                </div>
              </li>
              <li>
                <div class="m-b-10">
                  <div class="form-check form-switch">
                    <input class="form-check-input" id="customCheck99" name="radio-stacked" type="radio" value="reddark" [(ngModel)]="color">
                    <label class="form-check-label" for="customCheck99">Red Dark</label>
                  </div>
                </div>
              </li>
              <li>
                <div class="m-b-10">
                  <div class="form-check form-switch">
                    <input class="form-check-input" id="customCheck10" name="radio-stacked" type="radio" value="bluedark" [(ngModel)]="color">
                    <label class="form-check-label" for="customCheck10">Blue Dark</label>
                  </div>
                </div>
              </li>
              <li>
                <div class="m-b-10">
                  <div class="form-check form-switch">
                    <input class="form-check-input" id="customCheck111" name="radio-stacked" type="radio" value="purpledark" [(ngModel)]="color">
                    <label class="form-check-label" for="customCheck111">Purple Dark</label>
                  </div>
                </div>
              </li>
              <li>
                <div class="m-b-10">
                  <div class="form-check form-switch">
                    <input class="form-check-input" id="customCheck122" name="radio-stacked" type="radio" value="megnadark" [(ngModel)]="color">
                    <label class="form-check-label" for="customCheck122">Megna Dark</label>
                  </div>
                </div>
              </li>
            </ul>
            <hr>
            <ul>
              <li class="m-b-20 m-t-20">
                <b>General Setting</b>
              </li>
              <li>
                <div class="m-b-10">

                  <div class="form-check form-switch">
                    <input class="custom-control-input sidebartoggler" id="customCheck133" type="checkbox" [(ngModel)]="showMinisidebar">
                    <label class="form-check-label" for="customCheck133">Mini Sidebar</label>
                  </div>
                </div>
              </li>
              <li>
                <div class="m-b-10">

                  <div class="form-check form-switch">
                    <input class="form-check-input" id="customCheck144" type="checkbox" [(ngModel)]="showDarktheme">
                    <label class="form-check-label" for="customCheck144">Dark Theme</label>
                  </div>

                </div>
              </li>
              <li>
                <div class="m-b-10">
                  <div class="form-check form-switch">
                    <input class="form-check-input" id="customCheck145" type="checkbox" [(ngModel)]="showRtl">
                    <label class="form-check-label" for="customCheck145">RTL Theme</label>
                  </div>
                </div>
              </li>
            </ul>
            <hr>
          </div>
        </div>
      </div>
      <!-- ============================================================== -->
      <!-- End Right sidebar -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- footer -->
  <!-- ============================================================== -->
  <!-- <footer class="footer">
        © 2021 Aktif Müşteri
    </footer> -->
  <!-- ============================================================== -->
  <!-- End footer -->
  <!-- ============================================================== -->

</div>