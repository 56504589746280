<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>

<div class="modal-body">
  <!-- Listeleme -->
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6" *ngFor="let option of dataList">
          <div class="d-grid gap-2 mb-2">
            <button type="button" class="btn btn-primary" [ngClass]="{'btn-success': option === initialItem}" (click)="selectItem(option)">
              {{ getDisplayText(option) }}
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>


<div class="modal-footer">

  <button type="button" class="btn btn-secondary" (click)="close()">
    {{'button.close' | translate}}
  </button>

</div>