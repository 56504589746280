export enum ReminderDelayEnum {
  FIVE_MINUTES = 5,
  TEN_MINUTES = 10,
  FIFTEEN_MINUTES = 15,
  THIRTY_MINUTES = 30,
  ONE_HOUR = 60,
  TWO_HOURS = 120,
  THREE_HOURS = 180,
  SIX_HOURS = 360,
  TWELVE_HOURS = 720,
  ONE_DAY = 1440,
  ONE_WEEK = 10080,
  CUSTOM = -1 // Özel durum için
}

export const ReminderDelayEnumValues = [
  ReminderDelayEnum.FIVE_MINUTES,
  ReminderDelayEnum.TEN_MINUTES,
  ReminderDelayEnum.FIFTEEN_MINUTES,
  ReminderDelayEnum.THIRTY_MINUTES,
  ReminderDelayEnum.ONE_HOUR,
  ReminderDelayEnum.TWO_HOURS,
  ReminderDelayEnum.THREE_HOURS,
  ReminderDelayEnum.SIX_HOURS,
  ReminderDelayEnum.TWELVE_HOURS,
  ReminderDelayEnum.ONE_DAY,
  ReminderDelayEnum.ONE_WEEK,
  ReminderDelayEnum.CUSTOM
];
