


import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CommonModule,
  LocationStrategy,
  HashLocationStrategy
} from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { HttpResponseInterceptor } from './interceptor/http-response.interceptor';
import { AlertifyService } from './services/core/alertify.service';
import { ToastrModule } from 'ngx-toastr';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { CalendarModule } from 'primeng/calendar';


// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppPrivacyPolicyComponent } from './app-privacy-policy/app-privacy-policy.component';
import { DataRangeSelectModalComponent } from './core/data-range-select-modal/data-range-select-modal.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { LockScreenComponent } from './pages/core/lock-screen/lock-screen.component';
import { DistrictSelectModalComponent } from './core/district-select-modal/district-select-modal.component';
import { AutocompleteOffDirective } from './directive/autocomplete-off.directive';
import { ImageUploadModalComponent } from './core/image-upload-modal/image-upload-modal.component';
import { HelpVideoModalComponent } from './core/help-video-modal/help-video-modal.component';
import { AppPipeModule } from './app-pipe/app-pipe.module';
import { AppDirectiveModule } from './app-directive/app-directive.module';
import { HelpVideoAddUpdateComponent } from './core/help-video-add-update/help-video-add-update.component';
import { YesNoModalComponent } from './core/yes-no-modal/yes-no-modal.component';
import { ClockWidgetComponent } from './common/clock-widget/clock-widget.component';
import { LocalAppDownloadComponent } from './common/local-app-download/local-app-download.component';
import { environment } from '../environments/environment';
import LogRocket from 'logrocket';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

LogRocket.init(environment.logRocketId);

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    AppPrivacyPolicyComponent,
    DataRangeSelectModalComponent,
    LockScreenComponent,
    DistrictSelectModalComponent,
    AutocompleteOffDirective,
    ImageUploadModalComponent,
    HelpVideoModalComponent,
    HelpVideoAddUpdateComponent,
    YesNoModalComponent,
    ClockWidgetComponent,
    LocalAppDownloadComponent



  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgIdleKeepaliveModule.forRoot(),
    NgbModule,
    PerfectScrollbarModule,
    AppRoutingModule,
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot(),

    CalendarModule,
    AppPipeModule,
    AppDirectiveModule,

    NgxMaskModule.forRoot(options),

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule, // Firebase Analytics'i ekliyoruz

    AngularFirePerformanceModule // Performance Monitoring'i ekliyoruz

  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    Title,
    AlertifyService,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + environment.VERSION);
}
