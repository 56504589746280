import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNullOrEmpty } from 'app/helper/utils';
import { DistrictSearchDto } from 'app/models/dto/districtSearchDto';
import { City } from 'app/models/entity/city';
import { Country } from 'app/models/entity/country';
import { District } from 'app/models/entity/district';
import { OrderItemType } from 'app/models/entity/product/orderItemType';
import { SubProvince } from 'app/models/entity/sub-province';
import { AlertifyService } from 'app/services/core/alertify.service';
import { CommonService } from 'app/services/core/common.service';

@Component({
  selector: 'app-district-select-modal',
  templateUrl: './district-select-modal.component.html',
  styleUrls: ['./district-select-modal.component.css']
})
export class DistrictSelectModalComponent implements OnInit {
  countries: Country[] = [];
  cities: City[] = [];
  subProvinces: SubProvince[] = [];
  districts: District[] = [];
  @Input() countryId: number;
  @Input() cityId: number;
  @Input() subProvinceId: number;
  @Input() districtId: number;
  @Input() showAllOption: boolean = false;
  countryName: string;
  cityName: string;
  subProvinceName: string;
  districtName: string;
  postCode: string;
  postCodeDistrict: DistrictSearchDto;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  mainOrderItemTypes: OrderItemType[] = [];

  constructor(
    private commonService: CommonService,
    private alertifyService: AlertifyService) { }

  ngOnInit() {
    this.getCountries();
  }

  getCountries() {
    this.commonService.getCountries().subscribe(
      data => {
        this.countries = data;
        if (this.countryId == undefined)
          this.countryId = this.countries[0].id;
        this.getCities();
      },
      err => {
        this.alertifyService.error(err);
      }
    );
  }

  getCities() {

    this.cities = null;
    this.commonService.getCities(this.countryId).subscribe(
      data => {
        this.cities = data;
        if (this.cityId == undefined && !this.cities.isEmpty()) {
          this.cityId = this.cities[0].id;
        }
        this.getSubProvinces();

      },
      err => {
        this.alertifyService.error(err);
      }
    );
  }


  getSubProvinces() {
    this.subProvinces = null;
    if (this.cityId == 0 || !this.cityId)
      return;

    this.commonService.getSubProvinces(this.cityId).subscribe(
      data => {
        this.subProvinces = data;
        if (this.subProvinceId == undefined && !this.subProvinces.isEmpty()) {
          this.subProvinceId = this.subProvinces[0].id;
        }
        this.geDistricts();

      },
      err => {
        this.alertifyService.error(err);
      }
    );
  }


  geDistricts() {

    this.districts = null;
    if (this.subProvinceId == 0 || !this.subProvinceId)
      return;

    this.commonService.getDistricts(this.subProvinceId).subscribe(
      data => {

        this.districts = data;
        if (this.districtId == undefined && !this.districts.isEmpty()) {
          this.districtId = this.districts[0].id;
        }

      },
      err => {
        this.alertifyService.error(err);
      }
    );
  }

  searchPostCode() {
    if (isNullOrEmpty(this.postCode)) {
      this.alertifyService.error("message.error.cannot-be-blank");
      return;
    }

    this.commonService.getDistrictsByPostCode(this.countryId, this.postCode).subscribe(
      data => {
        this.postCodeDistrict = data;

        this.countryName = this.countries.where(x => x.id == this.countryId)[0].name;
        let selected = {
          countryId: this.countryId,
          countryName: this.countryName,
          cityId: this.postCodeDistrict.cityId,
          cityName: this.postCodeDistrict.city,
          subProvinceId: this.postCodeDistrict.subProvinceId,
          subProvinceName: this.postCodeDistrict.subProvince,
          districtId: this.postCodeDistrict.districtId,
          districtName: this.postCodeDistrict.district
        };
        this.passEntry.emit(selected);


      },
      err => {
        this.alertifyService.error(err);
      }
    );
  }

  countryChangeEvent() {
    this.cityId = null;
    this.subProvinceId = null;
    this.districtId = null;
    this.getCountries();
  }

  cityChangeEvent() {
    this.subProvinceId = null;
    this.districtId = null;
    this.getSubProvinces();
  }

  subProvinceChangeEvent() {
    this.districtId = null;
    this.geDistricts();
  }


  close() {
    this.passEntry.emit();
  }

  //kontroller
  validateSave() {
    if (!this.showAllOption && (this.districtId == null || this.districtId == 0) && this.districts.length > 0) {
      this.alertifyService.warning("message.warning.please-select-district");
      return false;
    }

    this.countryName = this.countries.where(x => x.id == this.countryId)[0].name;

    if (this.cityId > 0 && this.cities.length > 0)
      this.cityName = this.cities.where(x => x.id == this.cityId)[0].name;
    else {
      this.cityId = 0;
      this.cityName = null;
    }

    if (this.subProvinceId > 0 && this.subProvinces.length > 0)
      this.subProvinceName = this.subProvinces.where(x => x.id == this.subProvinceId)[0].name;
    else {
      this.subProvinceId = 0;
      this.subProvinceName = null;
    }
    if (this.districtId > 0 && this.districts.length > 0)
      this.districtName = this.districts.where(x => x.id == this.districtId)[0].name;
    else {
      this.districtId = 0;
      this.districtName = null;
    }

    return true;
  }
  save() {

    if (!this.validateSave())
      return;

    let data = {
      countryId: this.countryId,
      countryName: this.countryName,
      cityId: this.cityId,
      cityName: this.cityName,
      subProvinceId: this.subProvinceId,
      subProvinceName: this.subProvinceName,
      districtId: this.districtId,
      districtName: this.districtName
    };
    this.passEntry.emit(data);
  }
}
