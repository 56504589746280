<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'button.search-district' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>

<div class="modal-body">
  <!-- Güncelleme  -->
  <div>
    <form #createForm="ngForm" class="needs-validation" novalidate>
      <div class="card">
        <div class="card-body">


          <!-- Ülke -->
          <div class="col">
            <label class="custom-form-label" for="countryInput">{{'address.country' | translate}}</label>
            <select id="countryInput" name="countryInput" [(ngModel)]="countryId" class="form-control" (ngModelChange)="countryChangeEvent()">
              <option *ngFor="let option of countries" [ngValue]="option.id">
                {{ option.name }}
              </option>
            </select>
          </div>

          <!-- İl -->
          <div class="col">
            <label class="custom-form-label" for="cityInput">{{'address.city' | translate}}</label>
            <select id="cityInput" name="cityInput" [(ngModel)]="cityId" class="form-control" (ngModelChange)="cityChangeEvent()">
              <option *ngFor="let option of cities" [ngValue]="option.id">
                {{ option.name }}
              </option>
            </select>
          </div>

          <!-- ilçe -->
          <div class="col">
            <label class="custom-form-label" for="subProvinceInput">{{'address.subProvince' | translate}}</label>
            <select id="subProvinceInput" name="subProvinceInput" [(ngModel)]="subProvinceId" class="form-control" (ngModelChange)="subProvinceChangeEvent()">
              <option [ngValue]="0" *ngIf="showAllOption">
                {{ 'dropdown.all-sub-provinces' | translate }}
              </option>
              <option *ngFor="let option of subProvinces" [ngValue]="option.id">
                {{ option.name }}
              </option>
            </select>
          </div>

          <!-- Semt -->
          <div class="col" *ngIf="districts?.length>0">
            <label class="custom-form-label" for="districtInput">{{'address.district' | translate}}</label>
            <select id="districtInput" name="districtInput" [(ngModel)]="districtId" class="form-control">
              <option [ngValue]="0" *ngIf="showAllOption">
                {{ 'dropdown.all-districts' | translate }}
              </option>
              <option *ngFor="let option of districts" [ngValue]="option.id">
                {{ option.name }}
              </option>
            </select>
          </div>

          <div class="col">
            <label class="custom-form-label" for="customertype">{{'address.postalCode' | translate}}</label>
            <div class="input-group">
              <input class="form-control" id="inputPostCode" #inputPostCode name="inputPostCode" type="text" [(ngModel)]="postCode" (keydown.enter)="searchPostCode()" />
              <div class="input-group-append">
                <button class="btn btn-primary btn-input-group-append" type="button" (click)="searchPostCode()">
                  <span>F5</span> {{'button.search' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">
    {{'button.close' | translate}}
  </button>

  <button type="button" [disabled]="createForm.form.invalid" class="btn btn-primary" (click)="save()">
    {{'button.save' | translate}}
  </button>
</div>