import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSizeEnum } from 'app/enum/modal-size.enum';
import { RoleCodeEnum } from 'app/enum/role-code.enum';
import { clone } from 'app/helper/utils';
import { HelpVideoUrlModel } from 'app/models/entity/help-video-url.model';
import { AlertifyService } from 'app/services/core/alertify.service';
import { HelpVideoUrlService } from 'app/services/core/help-video-url.service';
import { HelpVideoAddUpdateComponent } from '../help-video-add-update/help-video-add-update.component';

@Component({
  selector: 'app-help-video-modal',
  templateUrl: './help-video-modal.component.html',
  styleUrls: ['./help-video-modal.component.css']
})
export class HelpVideoModalComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  dataList: HelpVideoUrlModel[] = [];
  loading = false;

  data: HelpVideoUrlModel = new HelpVideoUrlModel();

  isNewData: boolean;

  searchText: string;
  page = 1;
  pageSize = 12;
  roleCodeEnum = RoleCodeEnum;

  constructor(

    private modalService: NgbModal,
    private alertifyService: AlertifyService,
    private helpVideoUrlService: HelpVideoUrlService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.loading = true;
    this.helpVideoUrlService.getVideoUrls().subscribe(
      data => {
        this.dataList = data;
        this.dataList = this.dataList.sortBy("screenOrderNo");
        this.loading = false;
      },

      err => {
        this.alertifyService.error(err);
        this.loading = false;
      }
    );
  }

  // Modal ekleme güncelleme
  openAddUpdateModal(rowData) {

    const modalRef = this.modalService.open(HelpVideoAddUpdateComponent, {
      backdrop: "static", centered: true, scrollable: true, size: ModalSizeEnum.MEDIUM
    });

    // eğer güncelleme ise bu değer dolu geliyor
    if (rowData) {
      this.isNewData = false;
      this.data = rowData;
    }
    else {
      this.isNewData = true;
      this.data = new HelpVideoUrlModel();
    }

    //modal  veri geçişi
    modalRef.componentInstance.data = clone(this.data);

    //modal cevap döndüğünde
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      // Cevap boş değilse
      if (receivedEntry) {

        if (this.isNewData)
          this.dataList = this.dataList.concat(receivedEntry);
        else {
          // this.dataList.replace(this.data, receivedEntry);
          this.dataList = this.dataList.slice();
        }
        this.data = receivedEntry;
      }

      // modal kapat
      modalRef.close();
    });

  }



  close() {
    this.passEntry.emit();
  }
}
