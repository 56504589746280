<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'title.incoming-call' | translate}} {{ data?.incomingNo | phoneDisplayFormat}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>

<div class="modal-body">
  <div *ngIf="data?.customerId && data.customerId > 0; else noCustomer">
    <app-customer-info-widget [customer]="data?.customer" [address]="data?.address" [currentAccountBalance]="data?.currentAccountBalance" [bonusBalance]="data?.bonusBalance"
      [totalDebt]="data?.totalOrderDebt">
    </app-customer-info-widget>

  </div>
  <ng-template #noCustomer>
    <h3>{{'common.unknown-phone-number' | translate}}</h3>
  </ng-template>
</div>



<div class="modal-footer d-flex justify-content-end">

  <button class="btn btn-outline-secondary m-1" (click)="close()">
    {{'button.close' | translate}}
  </button>

  <div *ngIf="data?.customerId && data.customerId > 0">


    <button *ngxPermissionsOnly="[roleCodeEnum.ORDER_CREATE]" class="btn btn-primary m-1 pull-right hidden-sm-down" type="button" (click)="goNewOrder(routeEnum.ORDER_DETAIL)">

      {{'button.new-order' | translate}}
    </button>
    <button *ngxPermissionsOnly="[roleCodeEnum.ORDER_CREATE]" class="btn btn-outline-primary m-1 pull-right hidden-sm-down" type="button" (click)="goNewOrder(routeEnum.ORDER_PICKUP_ADD)">

      {{'button.new-pickup-order' | translate}}
    </button>

    <button *ngxPermissionsOnly="[roleCodeEnum.CUSTOMER_VIEW]" class="btn btn-outline-primary m-1 pull-right hidden-sm-down" type="button" (click)="goCustomerDetail()">

      {{'button.customer-detail' | translate}}
    </button>
  </div>
  <div *ngIf="data.customerId == 0">
    <button *ngxPermissionsOnly="[roleCodeEnum.MENU_CUSTOMER_ADD]" class="btn btn-primary m-1 pull-right" type="button" (click)="goNewCustomer()">
      {{'button.new-customer' | translate}}
    </button>
  </div>
</div>