export class VoipSocketStartRequest {
  voipVendorId?: number;
  url?: string;
  port?: number;
  userName?: string;
  password?: string;
  crmId?: string;
  internalNo?: string;
  internalNoList: string[];
}
