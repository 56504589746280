import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { YesNoModalTypeEnum } from 'app/enum/type/yes_no_modal_type.enum';
import { YesNoModalInputTypeEnum } from 'app/enum/type/yes_no_modal_input_type.enum';

@Component({
  selector: 'app-yes-no-modal',
  templateUrl: './yes-no-modal.component.html',
  styleUrls: ['./yes-no-modal.component.css']
})
export class YesNoModalComponent implements OnInit {

  @Input() title;
  @Input() message;
  @Input() placeholder?: string = "";
  @Input() modalType = YesNoModalTypeEnum.PRIMARY;
  @Input() modalInputType = YesNoModalInputTypeEnum.NONE;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  yesNoModalTypeEnum = YesNoModalTypeEnum;
  yesNoModalInputTypeEnum = YesNoModalInputTypeEnum;
  notes: string;
  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.passEntry.emit();
  }

  accept() {
    this.passEntry.emit({ result: true, notes: this.notes });
  }

}
