import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pack-status-badge',
  templateUrl: './pack-status-badge.component.html',
  styleUrls: ['./pack-status-badge.component.css']
})
export class PackStatusBadgeComponent implements OnInit {

  @Input() id: number;
  constructor() { }

  ngOnInit(): void {
  }

}
