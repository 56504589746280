import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateFormatEnum } from 'app/enum/date-format.enum';
import { DateRangeEnum } from 'app/enum/date-range.enum';
import { getStartEndDate } from 'app/helper/utils';
import { AlertifyService } from 'app/services/core/alertify.service';

@Component({
  selector: 'app-data-range-select-modal',
  templateUrl: './data-range-select-modal.component.html',
  styleUrls: ['./data-range-select-modal.component.css']
})
export class DataRangeSelectModalComponent implements OnInit {
  @Input() hideTomorrow = false;
  @Input() hidePast = false;
  @Input() startDate = new Date();
  @Input() endDate = new Date();
  @Input() dateRange: string;
  @Input() dateTitle: string;
  isDateActive: Boolean;

  dateTitleTemp: string;
  dateRangeEnum = DateRangeEnum;
  dateFormatEnum = DateFormatEnum;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  months: number[] = [];
  numOfMonths: number
  isCustomSelected: Boolean = false;

  constructor(private translate: TranslateService,
    private alertifyService: AlertifyService) { }

  ngOnInit(): void {
    if (this.hideTomorrow) {
      for (let i = 0; i < 13; i++) {
        // Ay eklemek için array'e indeksleme yaparak eriş
        this.months.push(-1 * i);
      }
    }
    else if (this.hidePast) {
      for (let i = 0; i < 13; i++) {
        // Ay eklemek için array'e indeksleme yaparak eriş
        this.months.push(i);
      }
    }
    else {

      // for (let i = 3; i > 0; i--) {
      //   // Ay eklemek için array'e indeksleme yaparak eriş
      //   this.months.push(i);
      // }
      for (let i = 0; i < 13; i++) {
        // Ay eklemek için array'e indeksleme yaparak eriş
        this.months.push(-1 * i);
      }

    }

  }

  selectRange(range: string): void {
    this.dateRange = range;
    if ((this.dateRange != this.dateRangeEnum.CUSTOM)) {
      let returnValue = getStartEndDate(this.dateRange);
      this.startDate = returnValue.startDate;
      this.endDate = returnValue.endDate;
      this.isDateActive = returnValue.isDateActive;
      this.dateTitleTemp = this.translate.instant('dateRangeEnumValue.' + returnValue.title);
      this.apply();
    }
    else {

      this.dateTitleTemp = "";
      this.isDateActive = true;
      this.startDate = new Date().clearTime();
      this.endDate = new Date().clearTime();

      this.isCustomSelected = true;
    }
  }

  clearCustom() {
    this.isCustomSelected = false;
  }

  apply() {
    if (this.dateRange == this.dateRangeEnum.CUSTOM && this.startDate.isAfter(this.endDate)) {
      this.alertifyService.error(this.translate.instant("message.warning.the-start-date-shouldnt-be-after-than-the-end-date"));
      return;

    }
    this.setDate();

    this.passEntry.emit({
      isInitial: false,
      startDate: this.startDate,
      endDate: this.endDate,
      isDateActive: this.isDateActive,
      dateTitle: this.dateTitle,
      dateRange: this.dateRange

    });
  }

  setDate() {
    this.startDate.clearTime();
    this.endDate.clearTime();
    this.dateTitle = this.dateTitleTemp;
  }
  selectMonth(value) {
    this.startDate = new Date().clearTime().addMonths(value).firstMonthDay();
    this.endDate = new Date().clearTime().addMonths(value).lastMonthDay();
    this.dateTitleTemp = this.getMonthName(value);
    this.isDateActive = true;
    this.dateRange = this.dateRangeEnum.CUSTOM;
    this.apply();
  }

  getMonthName(value: number) {
    let date = new Date().addMonths(value);
    let year = date.getFullYear();
    let month = this.translate.instant('primeng.monthNames.' + date.getMonth() + '');
    return month + " " + year;
  }

  close() {
    this.passEntry.emit();
  }
}
