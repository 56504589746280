
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { throwError as observableThrowError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { SendSmsPreviewResponse } from "../../models/rest/sms/send-sms-preview-response";
import { SendSmsForOrderRequest } from "../../models/rest/sms/send-sms-for-order-request";
import { SendSmsForOrderBulkRequest } from "../../models/rest/sms/send-sms-for-order-bulk-request";
import { SendSmsForOrderResponse } from "../../models/rest/sms/send-sms-for-order-response";
import { SendSmsForOrderBulkResponse } from "../../models/rest/sms/send-sms-for-order-bulk-response";
import { SmsResendRequest } from "../../models/rest/sms/sms-resend-request";
import { BaseRestService } from "../base-rest.service";
import { SessionStorageService } from "../session-storage.service";

@Injectable({
  providedIn: 'root'
})
export class SmsService extends BaseRestService {
  constructor(protected httpClient: HttpClient, protected sessionStorageService: SessionStorageService) {
    super(httpClient, sessionStorageService);
  }


  sendSmsBulk(request) {
    const url = `/sms/send-sms-bulk`;
    return this.httpPost(url, request)
      .pipe(catchError(this.errorHandler));
  }

  getSmsPreview(request): Observable<SendSmsPreviewResponse> {
    const url = `/sms/preview-sms`;
    return this.httpPost<SendSmsPreviewResponse>(url, request)
      .pipe(catchError(this.errorHandler));
  }

  sendOrderSms(request: SendSmsForOrderRequest): Observable<SendSmsForOrderResponse> {
    const url = `/sms/send-order-sms`;
    return this.httpPost(url, request)
      .pipe(catchError(this.errorHandler));
  }

  sendOrderBulkSms(request: SendSmsForOrderBulkRequest): Observable<SendSmsForOrderBulkResponse> {
    const url = `/sms/send-order-sms-bulk`;
    return this.httpPost(url, request)
      .pipe(catchError(this.errorHandler));
  }

  resendSms(request: SmsResendRequest) {
    const url = `/sms/resend`;
    return this.httpPost(url, request)
      .pipe(catchError(this.errorHandler));
  }



  errorHandler(error: HttpErrorResponse) {
    // console.log(error);


    return observableThrowError(error);
  }
}
