<!-- User profile -->
<div class="user-profile" *ngIf="currentUser">
  <!-- User profile image -->
  <div class="profile-img">
    <img [src]="currentUser.avatarUrl" alt="user" />
    <!-- this is blinking heartbit-->
    <div class="notify setpos">
      <span class="heartbit"></span>
      <span class="point"></span>
    </div>
  </div>
  <!-- User profile text-->
  <div class="profile-text">
    <h5>{{currentUser.screenName}}</h5>
    <h6>{{currentUser.storeName}}</h6>

  </div>
</div>
<!-- End User profile text-->
<hr>
<!-- Sidebar navigation-->
<nav class="sidebar-nav">
  <ul id="sidebarnav" *ngIf="currentUser">
    <!-- First level menu -->
    <li [class.active]="showMenu === sidebarnavItem.title" *ngFor="let sidebarnavItem of currentUser.menuList" [routerLinkActive]="sidebarnavItem.items.length != 0 ? '' : 'active'">
      <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">{{sidebarnavItem.translateKey | translate}}
      </div>
      <a [skipLocationChange]="false" [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.routerLink] : null" [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;"
        (click)="addExpandClass(sidebarnavItem.title)">
        <i [ngClass]="[sidebarnavItem.icon]"></i>
        <span class="hide-menu">{{sidebarnavItem.translateKey | translate}}
          <span *ngIf="sidebarnavItem.label != '' " [ngClass]="[sidebarnavItem.labelClass]">{{sidebarnavItem.label}}</span>
        </span>
      </a>
      <!-- Second level menu -->
      <ul class="collapse" *ngIf="sidebarnavItem.items.length > 0" [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
        <li *ngFor="let sidebarnavSubItem of sidebarnavItem.items" [class.active]="showSubMenu === sidebarnavSubItem.title" [routerLinkActive]="sidebarnavSubItem.items.length > 0 ? '' : 'active'">

          <a [skipLocationChange]="false" [routerLink]="sidebarnavSubItem.items.length > 0 ? null : [sidebarnavSubItem.routerLink]"
            [routerLinkActive]="sidebarnavSubItem.items.length > 0 ? '' : 'router-link-active'" [ngClass]="[sidebarnavSubItem.class ??'']" *ngIf="!sidebarnavSubItem.extralink;"
            (click)="addActiveClass(sidebarnavSubItem.title)">
            <i [ngClass]="[sidebarnavSubItem.icon]" *ngIf="sidebarnavSubItem.icon!=null"></i>
            {{sidebarnavSubItem.translateKey | translate}}
          </a>
          <!-- Third level menu -->
          <ul class="collapse" *ngIf="sidebarnavSubItem.items.length > 0" [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }">
            <li *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.items" routerLinkActive="active" [ngClass]="[sidebarnavSubsubItem.class]">
              <a [skipLocationChange]="false" [routerLink]="[sidebarnavSubsubItem.routerLink]" *ngIf="!sidebarnavSubsubItem.extralink;"
                [routerLinkActive]="sidebarnavSubsubItem.items.length > 0 ? '' : 'router-link-active'">
                <i [ngClass]="[sidebarnavSubsubItem.icon]" *ngIf="sidebarnavSubsubItem.icon!= null"></i>
                {{sidebarnavSubsubItem.translateKey | translate}}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</nav>
<!-- End Sidebar navigation -->
