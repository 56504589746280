export enum RouteEnum {

  HOME = '/',
  LOGIN = '/authentication/login',

  CUSTOMER_LOGIN = '/customer-login',
  CUSTOMER_SEARCH = '/pages/customer-search',
  CUSTOMER_DETAIL = '/pages/customer-detail',
  CUSTOMER_ADD = '/pages/customer-add',
  CUSTOMER_SUB_LIST = '/pages/customer-sub-list',


  ORDER_DETAIL = '/pages/order-view',
  ORDER_PICKUP_ADD = '/pages/order-pickup-add',
  PRODUCT_PACK = '/pages/product-pack',
  CALLERID_HISTORY_REPORT = '/pages/callerid-history-report',

  CUSTOMER_ORDER_REPORT = '/pages/customer-orders',
  PRODUCT_SALES_BY_ORDER_ITEM_TYPE_REPORT = '/pages/product-sales-by-order-item-type-report',
  PACK_LIST = '/pages/pack',

  SEARCH_ORDER_ITEMS_REPORT = "/pages/search-order-items",
  BILLING = "/pages/billing",
  SUPPLIER_PRODUCT_PRICE = "/pages/supplier-product-price",

  OPERATIONAL_DEMANDS = '/pages/operational-demands',
  EMPLOYEE_DETAIL = '/pages/employee-detail',
  OWNER_SALES_PRODUCT = '/pages/owner-sales-product',
}
