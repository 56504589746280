export enum VoipVendorEnum {

  COMMON = 1,
  ESANTRAL = 2,
  NETGSM = 3,
  TELSAM = 4,
  SESAL = 5

}
export const VoipVendorEnumValues = [
  VoipVendorEnum.COMMON,
  VoipVendorEnum.ESANTRAL,
  VoipVendorEnum.NETGSM,
  VoipVendorEnum.TELSAM,
  VoipVendorEnum.SESAL,
]

export const VoipVendorEnumTelsamValues = [
  VoipVendorEnum.ESANTRAL,
  VoipVendorEnum.TELSAM,
  VoipVendorEnum.SESAL,
]
