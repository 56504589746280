import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-employee-payment-payment-category-badge',
  templateUrl: './employee-payment-payment-category-badge.component.html',
  styleUrls: ['./employee-payment-payment-category-badge.component.css']
})
export class EmployeePaymentPaymentCategoryBadgeComponent implements OnInit {

  @Input() value: string;
  constructor() { }

  ngOnInit(): void {
  }

}
