import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError as observableThrowError, Observable } from "rxjs";
import { BaseRestService } from "../base-rest.service";
import { VoipInternalModel } from "../../models/entity/voip-internal.model";
import { VoipInternalSettingFileResponse } from "../../models/rest/voip/voip-internal-setting-file-response.model";
import { VoipImportFromSantralRequest } from "app/models/rest/voip/voip-import-from-santral-request";
import { VoipCallStartRequest } from "app/models/rest/voip/voip-call-start-request";
import { SessionStorageService } from "../session-storage.service";


@Injectable({
  providedIn: 'root'
})
export class VoipInternalService extends BaseRestService {
  constructor(protected httpClient: HttpClient, protected sessionStorageService: SessionStorageService) {
    super(httpClient, sessionStorageService);
  }


  // Müşteri tipleri
  getVoipInternals(): Observable<VoipInternalModel[]> {
    const url = `/voip-internals`;
    return this.httpGet<VoipInternalModel[]>(url)
      .pipe(catchError(this.errorHandler));
  }


  getVoipInternalById(id) {
    const url = `/voip-internals/${id}`;
    return this.httpGet(url).pipe(catchError(this.errorHandler));
  }

  //Müşteri tipi ekle
  addVoipInternal(data) {
    const url = `/voip-internals`;
    return this.httpPost<VoipInternalModel>(url, data)
      .pipe(catchError(this.errorHandler));
  }

  //Müşteri tipi güncelle
  updateVoipInternal(id, data): Observable<VoipInternalModel> {
    const url = `/voip-internals/${id}`;
    return this.httpPut<VoipInternalModel>(url, data)
      .pipe(catchError(this.errorHandler));
  }


  //Müşteri tipi sil
  deleteVoipInternal(id) {
    const url = `/voip-internals/${id}`;
    return this.httpDelete(url).pipe(catchError(this.errorHandler));
  }

  getSettingForFile(id): Observable<VoipInternalSettingFileResponse> {
    const url = `/voip-internals/${id}/setting-for-file`;
    return this.httpGet(url).pipe(catchError(this.errorHandler));
  }

  //santral ekle
  importFromSantral(data: VoipImportFromSantralRequest) {
    const url = `/voip-internals/import-from-santral`;
    return this.httpPost(url, data)
      .pipe(catchError(this.errorHandler));
  }

  startCall(data: VoipCallStartRequest) {
    const url = `/voip-internals/start-call`;
    return this.httpPost(url, data)
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    // console.log("service:"+error);
    return observableThrowError(error);
  }
}
