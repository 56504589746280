import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-upload-modal',
  templateUrl: './image-upload-modal.component.html',
  styleUrls: ['./image-upload-modal.component.css']
})
export class ImageUploadModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
