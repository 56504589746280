// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import packageInfo from '../../package.json';
export const environment = {
  production: false,
  VERSION: packageInfo.version,
  appName: 'Aktif Müşteri',
  // apiUrl: 'http://localhost:5001/api/v1',
  // apiUrl: 'https://api.procleansoft.com/api/v1',//proclean api
  // apiUrl: 'https://api.aktifmusteri.com/api/v1', //aktif müşteritest
  apiUrl: 'https://api.aktifmusteri.com/api/v1', //aktif müşteri
  printerUrl: 'http://localhost:8083',
  siteKey: '6Ld6aMcaAAAAAPF1bllfBCAvRPmtmrBLaxYTOCZG',
  productImageBaseUrl: 'https://aktifmusteri.com/download/program_image/product/',
  productNoteImageBaseUrl: 'https://aktifmusteri.com/download/program_image/product_note/',
  oneSignalAppId: 'd60d8724-1c90-492a-b617-9c95bb6499b3',
  logRocketId: 'zte6cq/aktif-musteri-web',
  assetProductImageBaseUrl: '/assets/program_image/product/',
  assetProductNoteImageBaseUrl: '/assets/program_image/product_note/',
  firebase: {
    apiKey: "AIzaSyBZ4mtPps8A7eR_qoUvPx9Txjso7eJ27KA",
    authDomain: "aktif-musteri-web.firebaseapp.com",
    projectId: "aktif-musteri-web",
    storageBucket: "aktif-musteri-web.appspot.com",
    messagingSenderId: "377448472227",
    appId: "1:377448472227:web:4e3f79b342c8d36d9e6916",
    measurementId: "G-F3H1C59NPD"
  }
};
