import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchList'
})
export class SearchListPipe implements PipeTransform {
  transform(list: any[], filterText: string, fieldName: string = 'name'): any {
    if (!filterText || !list) {
      return list;
    }

    filterText = filterText.replace('İ', 'i').toLowerCase();

    return list.filter(item => {
      const fieldValue = item[fieldName].replace('İ', 'i').toLowerCase();

      return fieldValue.includes(filterText);
    });
  }
}

