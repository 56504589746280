<span [ngSwitch]="value">
  <span class="badge bg-info" *ngSwitchCase="0"></span>
  <span class="badge bg-info" *ngSwitchCase="1">{{'dispatchStatusValue.'+value | translate}}</span>
  <span class="badge bg-info" *ngSwitchCase="2">{{'dispatchStatusValue.'+value | translate}}</span>
  <span class="badge order-product-preparation" *ngSwitchCase="3">{{'dispatchStatusValue.'+value | translate}}</span>
  <span class="badge order-prepared " *ngSwitchCase="4">{{'dispatchStatusValue.'+value | translate}}</span>
  <span class="badge bg-info" *ngSwitchCase="5">{{'dispatchStatusValue.'+value | translate}}</span>
  <span class="badge order-prepared" *ngSwitchCase="6">{{'dispatchStatusValue.'+value | translate}}</span>
  <span class="badge bg-info" *ngSwitchDefault>{{'dispatchStatusValue.'+value | translate}}</span>
</span>
