<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ 'title.login-screen' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>

<div class="modal-body">

  <form #createForm="ngForm" class="needs-validation" novalidate>

    <div class="col mb-3">
      <label class="custom-form-label" for="userName">{{'authentication.email' | translate}} </label>
      <select #userName name="userName" class="form-control" [(ngModel)]="selectedUser" required>
        <option *ngFor="let item of users" [ngValue]="item">{{ item.screenName }} - {{item.userName}}
        </option>
      </select>
    </div>

    <div class="col">
      <label class="custom-form-label" for="passwordControl">{{'authentication.password' | translate}} </label>
      <input #passwordControl="ngModel" [(ngModel)]="password" type="password" class="form-control" name="password" required />



      <small class="form-text text-danger" *ngIf="capslockOn">{{'authentication.capslock-on' | translate}}</small>
      <small class="form-text text-danger" *ngIf="!passwordControl.valid && (passwordControl.dirty || passwordControl.touched)">{{'message.error.cannot-be-blank' | translate}}</small>
    </div>

  </form>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">
    {{'button.close' | translate}}
  </button>

  <button [disabled]="loading" type="button" class="btn  btn-primary" (click)="loginUser()" [disabled]="createForm.form.invalid">
    <i [hidden]="!loading" class="fa fa-circle-o-notch fa-spin"></i>
    {{'authentication.login' | translate}} </button>


</div>