export class PrinterSendRequest {
  printType?: string;
  data?: string;
  byteData?: number[];
  printerName?: string;
  count?: number;
  layout?: string;
  height?: number;
  width?: number;
}
