import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouteEnum } from 'app/enum/route.enum';
import { Address } from 'app/models/entity/address';
import { CustomerEntity } from 'app/models/entity/customer/customer.entity';
import { DeviceLocalStorageData } from 'app/models/rest/user/device-local-storage-data.model';
import { SessionStorageService } from 'app/services/session-storage.service';


@Component({
  selector: 'app-customer-info-widget',
  templateUrl: './customer-info-widget.component.html',
  styleUrls: ['./customer-info-widget.component.css']
})
export class CustomerInfoWidgetComponent implements OnInit {

  @Input() address: Address;
  @Input() customer: CustomerEntity;
  @Input() currentAccountBalance: number;
  @Input() bonusBalance: number;
  @Input() totalDebt: number;
  @Input() customerTypeName?: string;
  @Input() communityName?: string;
  @Input() showBillingDetail?: boolean = false;
  @Input() showParentCustomer?: boolean = false;

  deviceLocalStorageData: DeviceLocalStorageData;

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.deviceLocalStorageData = this.sessionStorageService.getDeviceStorageData();
  }

  goParentCustomer() {

    this.router.navigate([`${RouteEnum.CUSTOMER_DETAIL}/${this.customer.parentCustomerId}`], { skipLocationChange: false });
  }


}
