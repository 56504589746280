<div class="modal-header  bg-primary">
  <h4 class="modal-title text-white" id="modal-basic-title">{{ 'title.user-request-permission' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>

<div class="modal-body">
  <div class="card">
    <div class="card-body">
      <form #createForm="ngForm" class="needs-validation" novalidate>
        <!--Kullanıcı -->
        <div class="row form-group">
          <div class="col-sm-12">
            <label class="custom-form-label">{{'user.demandedUser' | translate}}</label>
          </div>
          <div class="col-sm-12">
            <select name="user" [(ngModel)]="data.demandedUser" class="form-control">
              <option [ngValue]="0">
                {{'dropdown.select' | translate}}
              </option>
              <option *ngFor="let option of users" [ngValue]="option.id">
                {{ option.screenName }}
              </option>
            </select>
          </div>
          <div class="col-sm-12">
            <label class="custom-form-label" for="notesInput">{{'common.notes' | translate}}</label>
            <textarea #notesInput="ngModel" name="notesInput" type="text" class="form-control" [(ngModel)]="data.message" style="height: 100px" required ngbAutoFocus
              [class.is-invalid]="notesInput.invalid && notesInput.touched"></textarea>


            <app-field-error-display [displayError]="notesInput.invalid && notesInput.touched" errorMsg="{{'message.error.cannot-be-blank' | translate}}">
            </app-field-error-display>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="alert alert-success" role="alert">
    {{ 'authentication.permanentRoleRequest' | translate }}
  </div>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">
    {{'button.close' | translate}}
  </button>
  <button type="button" class="btn btn-primary" ngbAutofocus [disabled]="createForm.form.invalid||isProgressingPayment" (click)="save()">

    <i [hidden]="!isProgressingPayment" class="fa fa-circle-o-notch fa-spin"></i>
    <span [hidden]="isProgressingPayment"> {{ 'button.request-permission' | translate}}</span>
    <span [hidden]="!isProgressingPayment"> {{'progress.please-wait' | translate}}</span>
  </button>
</div>