<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ 'menuitem.printer' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>

<div class="modal-body">
  <!-- Güncelleme  -->
  <div>
    <div class="card">
      <div class="card-body">
        <div class="col">
          <label class="custom-form-label" for="receiptInput">{{'printer.receipt' | translate}}</label>
          <select id="receiptInput" name="receiptInput" [(ngModel)]="receipt" class="form-control">
            <option [ngValue]="">
              {{'dropdown.no-preference' | translate}}
            </option>
            <option *ngFor="let option of printers" [ngValue]="option">
              {{ option.name }}
            </option>
          </select>
        </div>

        <div class="col">
          <label class="custom-form-label" for="labelInput">{{'printer.label' | translate}}</label>
          <select id="labelInput" name="labelInput" [(ngModel)]="label" class="form-control">
            <option [ngValue]="">
              {{'dropdown.no-preference' | translate}}
            </option>
            <option *ngFor="let option of printers" [ngValue]="option">
              {{ option.name }}
            </option>
          </select>
        </div>

        <div class="col">
          <label class="custom-form-label" for="additionalReceiptInput">{{'printer.additional-receipt' | translate}}</label>
          <select id="additionalReceiptInput" name="additionalReceiptInput" [(ngModel)]="additionalReceipt" class="form-control">
            <option [ngValue]="">
              {{'dropdown.no-preference' | translate}}
            </option>
            <option *ngFor="let option of printers" [ngValue]="option">
              {{ option.name }}
            </option>
          </select>
        </div>

        <div class="col">
          <label class="custom-form-label" for="packageLabelInput">{{'printer.package-label' | translate}}</label>
          <select id="packageLabelInput" name="packageLabelInput" [(ngModel)]="packageLabel" class="form-control">
            <option [ngValue]="">
              {{'dropdown.no-preference' | translate}}
            </option>
            <option *ngFor="let option of printers" [ngValue]="option">
              {{ option.name }}
            </option>
          </select>
        </div>


        <div class="col">
          <label class="custom-form-label" for="billingInput">{{'printer.billing' | translate}}</label>
          <select id="billingInput" name="billingInput" [(ngModel)]="billing" class="form-control">
            <option [ngValue]="">
              {{'dropdown.no-preference' | translate}}
            </option>
            <option *ngFor="let option of printers" [ngValue]="option">
              {{ option.name }}
            </option>
          </select>
        </div>

      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">
    {{'button.close' | translate}}
  </button>

  <button type="button" class="btn btn-primary" (click)="save()">
    {{'button.save' | translate}}
  </button>
</div>