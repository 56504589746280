import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-phone-call-option-widget',
  templateUrl: './phone-call-option-widget.component.html',
  styleUrls: ['./phone-call-option-widget.component.css']
})
export class PhoneCallOptionWidgetComponent implements OnInit {

  @Input() customerId: number;
  @Input() phoneNumber: string;
  constructor() { }

  ngOnInit(): void {
  }

}
