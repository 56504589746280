import { Component, Input, OnInit } from '@angular/core';
import { CustomerEntity } from 'app/models/entity/customer/customer.entity';

@Component({
  selector: 'app-customer-billing-info-widget',
  templateUrl: './customer-billing-info-widget.component.html',
  styleUrls: ['./customer-billing-info-widget.component.css']
})
export class CustomerBillingInfoWidgetComponent implements OnInit {

  @Input() customer: CustomerEntity;
  constructor() { }

  ngOnInit(): void {
  }

}
