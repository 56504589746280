
export enum DevicePreferenceTypeEnum {
  IS_CALLERID_ACTIVE = "IS_CALLERID_ACTIVE",
  IS_VOIP_ACTIVE = "IS_VOIP_ACTIVE",

  PRINTER_RECEIPT = "PRINTER_RECEIPT",
  PRINTER_LABEL = "PRINTER_LABEL",
  PRINTER_ADDITIONAL_RECEIPT = "PRINTER_ADDITIONAL_RECEIPT",
  PRINTER_PACKAGE_LABEL = "PRINTER_PACKAGE_LABEL",
  PRINTER_BILLING = "PRINTER_BILLING",
  DEVICE_LOCAL_STORAGE = "DEVICE_LOCAL_STORAGE"

}
