import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StorageConstants } from 'app/constant/storage.constant';
import { RoleCodeEnum } from 'app/enum/role-code.enum';
import { RouteEnum } from 'app/enum/route.enum';
import { AllowedStoreTypeEnum } from 'app/enum/type/allowed-store-type.enum';
import { CustomerSearchRequest } from 'app/models/rest/customer/customerSearchRequest';
import { CallerIdLogCreateResponseModel } from 'app/models/rest/voip/caller-id-log-create-response.model';
import { AuthService } from 'app/services/auth.service';
import { SessionStorageService } from 'app/services/session-storage.service';

import { UserOperationalService } from 'app/services/user-operational.service';

@Component({
  selector: 'app-incoming-call-display-modal',
  templateUrl: './incoming-call-display-modal.component.html',
  styleUrls: ['./incoming-call-display-modal.component.css']
})
export class IncomingCallDisplayModalComponent implements OnInit {
  @Input() data: CallerIdLogCreateResponseModel;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  roleCodeEnum = RoleCodeEnum;
  routeEnum = RouteEnum;
  constructor(
    private translate: TranslateService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private authService: AuthService,
    private userOperationalService: UserOperationalService,
  ) { }

  ngOnInit(): void {
  }

  //Yeni sipariş oluşturma ekranına geç
  goNewOrder(route: String) {

    // Yetki kontrolü
    if (!this.userOperationalService.checkAndOpenModalIfNecessary(RoleCodeEnum.ORDER_CREATE, this.authService.getStoreId(), AllowedStoreTypeEnum.ORDER))
      return;

    this.router.navigate([route], {
      queryParams: { customerId: this.data.customerId }, skipLocationChange: false
    });
    this.passEntry.emit();
  }


  async goCustomerDetail() {
    // Yetki kontrolü
    if (! await this.userOperationalService.checkAndOpenModalIfNecessary(RoleCodeEnum.CUSTOMER_VIEW,
      this.authService.getStoreId(), AllowedStoreTypeEnum.CUSTOMER)) {
      return;
    }



    this.router.navigate([`${RouteEnum.CUSTOMER_DETAIL}/${this.data.customerId}`], { skipLocationChange: false });
    this.passEntry.emit();
  }


  goNewCustomer() {

    let customerSearchRequest = new CustomerSearchRequest();
    customerSearchRequest.phone = this.data.incomingNo;
    this.sessionStorageService.setJSONData(StorageConstants.SEARCH_CRITERIA, customerSearchRequest);
    this.router.navigate([RouteEnum.CUSTOMER_ADD], { skipLocationChange: false });
    this.passEntry.emit();
  }

  close() {
    this.passEntry.emit();
  }
}
