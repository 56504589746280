import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router'
import { RouteEnum } from 'app/enum/route.enum';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private history: string[] = []
  private previousUrl: string;

  constructor(private router: Router,
    private location: Location,
    private route: ActivatedRoute,) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.history.getLastValue() != event.urlAfterRedirects)
          this.history.push(event.urlAfterRedirects)
        // console.log(this.history);
      }
    })
  }

  // backOld(): void {

  //   this.removeLast();
  //   if (this.history.length > 0) {
  //     this.previousUrl = this.history.getLastValue();
  //     this.router.navigate([this.previousUrl], { skipLocationChange: false });
  //   }
  //   else {
  //     window.close();
  //   }
  //   // else
  //   //   this.previousUrl = '/';


  // }
  back(): void {

    this.removeLast();
    if (this.history.length > 0) {
      this.previousUrl = this.history.getLastValue();

      const queryParamsIndex = this.previousUrl.indexOf('?');
      let urlWithoutQueryParams = this.previousUrl;
      let queryParams: { [key: string]: any } = {};

      if (queryParamsIndex !== -1) {
        urlWithoutQueryParams = this.previousUrl.substring(0, queryParamsIndex);
        const queryString = this.previousUrl.substring(queryParamsIndex + 1);
        queryParams = this.parseQueryParams(queryString);
      }

      this.router.navigate([urlWithoutQueryParams], { queryParams, skipLocationChange: false });
    } else {
      // window.close();
      this.router.navigate([RouteEnum.CUSTOMER_SEARCH]); // Ana rotanın yolunu buraya ekleyin

    }
  }


  removeLast() {
    this.history.pop();

  }

  updateQueryParameterIfNotExist(parameterName: string, parameterValue: string) {

    if (!this.route.snapshot.queryParamMap.has(parameterName)) {
      this.updateQueryParamer(parameterName, parameterValue);
    }
  }

  updateQueryParamer(parameterName: string, parameterValue: string) {

    const queryParams = { ...this.route.snapshot.queryParams };
    queryParams[parameterName] = parameterValue;

    const newUrl = this.location.path().split('?')[0] + '?' + this.serializeQueryParams(queryParams);
    this.location.replaceState(newUrl);
    console.log(newUrl);


    this.history[this.history.length - 1] = newUrl;
  }

  clearQueryParams() {
    // Aktif rotanın yalnızca temel yolunu alın (sorgu parametreleri olmadan)
    const baseUrl = this.location.path().split('?')[0];

    // replaceState metodu ile URL'i sorgu parametreleri olmadan güncelleyin
    this.location.replaceState(baseUrl);
    this.history[this.history.length - 1] = baseUrl;

  }
  private serializeQueryParams(params: { [key: string]: any }): string {
    const queryParamArr = [];
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(params[key]);
        queryParamArr.push(`${encodedKey}=${encodedValue}`);
      }
    }
    return queryParamArr.join('&');
  }



  private parseQueryParams(queryString: string): { [key: string]: any } {
    const queryParams: { [key: string]: any } = {};
    const pairs = queryString.split('&');
    for (const pair of pairs) {
      const [key, value] = pair.split('=');
      queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
    }
    return queryParams;
  }

}
