import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FilterColSelectedPipe } from './pipes/filter-col-selected.pipe';
import { NumberOnlyPipe } from './pipes/number-only.pipe';
import { SearchGlobalPipe } from './pipes/search-global.pipe';
import { SearchListPipe } from './pipes/search-list.pipe';
import { NumberArrayPipe } from './pipes/number-array.pipe';
import { TrimPipe } from './pipes/trim.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ThousandSeparatorPipe } from './pipes/thousand-separator.pipe';
import { ThousandSeparatorWithDecimalsPipe } from './pipes/thousand-separator-with-decimals.pipe';
import { PhoneDisplayFormatPipe } from './pipes/phone-display-format.pipe';
import { CardNumberFormatPipe } from './pipes/card-number-format.pipe';
import { NewlinePipe } from './pipes/new-line.pipe';





@NgModule({
  declarations: [
    SafeUrlPipe,
    SafeHtmlPipe,
    NumberOnlyPipe,
    FilterColSelectedPipe,
    SearchGlobalPipe,
    SearchListPipe,
    NumberArrayPipe,
    TrimPipe,
    CapitalizePipe,
    TruncatePipe,
    ThousandSeparatorPipe,
    ThousandSeparatorWithDecimalsPipe,
    PhoneDisplayFormatPipe,
    CardNumberFormatPipe,
    NewlinePipe

  ],
  exports: [
    SafeUrlPipe,
    SafeHtmlPipe,
    NumberOnlyPipe,
    FilterColSelectedPipe,
    SearchGlobalPipe,
    SearchListPipe,
    NumberArrayPipe,
    TrimPipe,
    CapitalizePipe,
    TruncatePipe,
    ThousandSeparatorPipe,
    ThousandSeparatorWithDecimalsPipe,
    PhoneDisplayFormatPipe,
    CardNumberFormatPipe,
    NewlinePipe
  ],
  imports: [
    CommonModule
  ]
})
export class AppPipeModule { }
