import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DevicePreference } from 'app/models/entity/devicePreference';
import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from "rxjs";
import { BaseRestService } from '../base-rest.service';
import { SessionStorageService } from "../session-storage.service";

@Injectable({
  providedIn: 'root'
})
export class DevicePreferenceService extends BaseRestService {
  constructor(protected httpClient: HttpClient, protected sessionStorageService: SessionStorageService) {
    super(httpClient, sessionStorageService);
  }

  getDevicePreferences(): Observable<DevicePreference[]> {
    const url = `/device-preferences`;
    return this.httpGet<DevicePreference[]>(url).pipe(catchError(this.errorHandler));
  }

  getDevicePreferenceById(id) {
    const url = `/device-preferences/${id}`;
    return this.httpGet(url).pipe(catchError(this.errorHandler));
  }

  addDevicePreference(data) {
    const url = `/device-preferences`;
    return this.httpPost(url, data).pipe(catchError(this.errorHandler));
  }

  updateDevicePreference(id, data): Observable<DevicePreference> {
    const url = `/device-preferences/${id}`;
    return this.httpPut<DevicePreference>(url, data)
      .pipe(catchError(this.errorHandler));
  }

  deleteDevicePreference(id) {
    const url = `/device-preferences/${id}`;
    return this.httpDelete(url).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    if (error.message) return observableThrowError(error);
    else return observableThrowError(error || " Server Error");
  }
}
