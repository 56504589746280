import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ModalSizeEnum } from 'app/enum/modal-size.enum';
import { isNullOrEmpty } from 'app/helper/utils';
import { SmsTemplateEntity } from 'app/models/entity/sms/sms-template-entity';
import { SendSmsPreviewRequest } from 'app/models/rest/sms/send-sms-preview-request';
import { DeviceLocalStorageData } from 'app/models/rest/user/device-local-storage-data.model';
import { SmsTemplateListComponent } from 'app/pages/sms/sms-template-list/sms-template-list.component';
import { SmsTemplateSelectModalComponent } from 'app/pages/sms/sms-template-select-modal/sms-template-select-modal.component';
import { AlertifyService } from 'app/services/core/alertify.service';
import { CommunicationService } from 'app/services/core/communication.service';
import { SessionStorageService } from 'app/services/session-storage.service';

import { SmsService } from 'app/services/sms/sms.service';

@Component({
  selector: 'app-whatsapp-widget',
  templateUrl: './whatsapp-widget.component.html',
  styleUrls: ['./whatsapp-widget.component.css']
})
export class WhatsappWidgetComponent implements OnInit {
  @Input() customerId: number;
  @Input() phoneNumber: string;
  deviceLocalStorageData: DeviceLocalStorageData;
  constructor(
    private modalService: NgbModal,
    private smsService: SmsService,
    private translate: TranslateService,
    private alertifyService: AlertifyService,
    private sessionStorageService: SessionStorageService,
    private communicationService: CommunicationService) { }

  ngOnInit(): void {
    this.deviceLocalStorageData = this.sessionStorageService.getDeviceStorageData();
  }

  validateSave() {
    if (isNullOrEmpty(this.phoneNumber)) {
      this.alertifyService.warning(this.translate.instant("message.error.phone-number-cannot-be-blank"))
      return false;
    }

    return true;
  }

  openWhatsApp() {

    if (!this.validateSave()) return;

    this.openSmsTemplateSelectModal();
  }

  openSmsTemplateSelectModal() {

    const modalRef = this.modalService.open(SmsTemplateSelectModalComponent, {
      backdrop: "static", centered: true, scrollable: true, size: ModalSizeEnum.LARGE
    });

    //modal cevap döndüğünde
    modalRef.componentInstance.passEntry.subscribe(async (receivedEntry: SmsTemplateEntity) => {
      // Cevap boş değilse
      if (receivedEntry) {
        if (this.customerId == undefined) {
          this.communicationService.openWhatsApp(this.phoneNumber, "");
          return;
        }
        let previewRequest = new SendSmsPreviewRequest();
        previewRequest.smsMessageType = receivedEntry.usageFor;
        previewRequest.message = receivedEntry.message;
        previewRequest.customerId = this.customerId;

        try {
          const res = await this.smsService.getSmsPreview(previewRequest).toPromise();
          this.communicationService.openWhatsApp(this.phoneNumber, res.message);
        }
        catch (err) {
          this.alertifyService.error(err);
        }
      }
      else {
        this.communicationService.openWhatsApp(this.phoneNumber, "");
      }

      // modal kapat
      modalRef.close();
    });

    modalRef.result.then(
      (success) => {
        this.communicationService.openWhatsApp(this.phoneNumber, "");
      },
      (cancel) => {
        this.communicationService.openWhatsApp(this.phoneNumber, "");
      }
    );

  }

}
