import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { User } from "../../../models/entity/user";
import { UserService } from "../../../services/user/user.service";
import { AlertifyService } from "../../../services/core/alertify.service";
import { TranslateService } from "@ngx-translate/core";
import { UsersForRoleCodeRequest } from "app/models/rest/user/user-for-role-code-request";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserRequestPermissionModalComponent } from "../user-request-permission-modal/user-request-permission-modal.component";
import { ModalSizeEnum } from "app/enum/modal-size.enum";
import { isNullOrEmpty } from "app/helper/utils";
@Component({
  selector: 'app-user-check-permission-modal',
  templateUrl: './user-check-permission-modal.component.html',
  styleUrls: ['./user-check-permission-modal.component.css']
})
export class UserCheckPermissionModalComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() roleCode: string;
  @Input() relatedStoreId: number;
  @Input() allowedType: string;

  users: User[] = [];
  selectedUserId: number = 0;
  password: string;
  isProgressingPayment: boolean = false;
  hasUsers: boolean = false;
  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private alertifyService: AlertifyService,
    private modalService: NgbModal
  ) { }
  ngOnInit() {
    this.getUsers();
  }


  private getUsers() {
    let request = new UsersForRoleCodeRequest();
    request.roleCode = this.roleCode;
    request.relatedStoreId = this.relatedStoreId;
    request.allowedType = this.allowedType;
    this.userService.getUsersByRoleCode(request).subscribe(
      res => {
        this.users = res;
        this.hasUsers = this.users?.length > 0;
        if (this.hasUsers)
          this.selectedUserId = this.users[0].id;

      },
      err => {
        this.alertifyService.error(err);
      }
    );
  }

  validateSave() {
    if (this.selectedUserId == 0) {
      this.alertifyService.warning(this.translate.instant('message.warning.please-select-user'));
      return false;
    }

    if (isNullOrEmpty(this.password)) {
      this.alertifyService.warning(this.translate.instant("authentication.password-can-not-be-empty"))
      return false;
    }
    return true;
  }
  check() {
    try {
      if (this.isProgressingPayment) {
        return;
      }
      this.isProgressingPayment = true;

      if (!this.validateSave())
        return false;

      let user = this.users.filter(x => x.id == this.selectedUserId)[0];

      this.userService.checkPassword(user.userName, this.password).subscribe(
        res => {
          this.password = null;
          this.passEntry.emit(true);
        },
        err => this.alertifyService.error(err)
      );
    }
    catch (err) {
      this.alertifyService.error(err);
    } finally {

      this.isProgressingPayment = false;
    }
  }


  openRequestPermissionModal() {
    const modalRef = this.modalService.open(UserRequestPermissionModalComponent, {
      backdrop: "static", centered: true, scrollable: false, size: ModalSizeEnum.MEDIUM
    });
    modalRef.componentInstance.roleCode = this.roleCode;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      // Cevap boş değilse
      if (receivedEntry) {
      }

      // modal kapat
      modalRef.close();
    });

  }

  close() {
    this.passEntry.emit(false);
  }

}
