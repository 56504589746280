<span [ngSwitch]="value">
  <span class="badge bg-warning" *ngSwitchCase="'PENDING'"> {{'billingSenderStateValue.'+value | translate}}</span>
  <span class="badge bg-success" *ngSwitchCase="'DELIVERED_PORTAL'"> {{'billingSenderStateValue.'+value | translate}}</span>
  <span class="badge bg-success" *ngSwitchCase="'DELIVERED'"> {{'billingSenderStateValue.'+value | translate}}</span>
  <span class="badge bg-warning" *ngSwitchCase="'PENDING_FOR_CANCEL'"> {{'billingSenderStateValue.'+value | translate}}</span>
  <span class="badge bg-danger" *ngSwitchCase="'CANCELED'"> {{'billingSenderStateValue.'+value | translate}}</span>
  <span class="badge bg-danger" *ngSwitchCase="'MANUALLY_CANCELED'"> {{'billingSenderStateValue.'+value | translate}}</span>
  <span class="badge bg-danger" *ngSwitchCase="'DELETED'"> {{'billingSenderStateValue.'+value | translate}}</span>
  <span class="badge bg-danger" *ngSwitchCase="'ERROR'"> {{'billingSenderStateValue.'+value | translate}}</span>
  <span class="badge bg-danger" *ngSwitchCase="'REJECTED'"> {{'billingSenderStateValue.'+value | translate}}</span>
  <span class="badge bg-success" *ngSwitchCase="'ACCEPTED'"> {{'billingSenderStateValue.'+value | translate}}</span>
  <span class="badge bg-info" *ngSwitchDefault>
    <span *ngIf="value">
      {{'billingSenderStateValue.'+value | translate}}</span>

  </span>
</span>