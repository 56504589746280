import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-development-demand-status-badge',
  templateUrl: './development-demand-status-badge.component.html',
  styleUrls: ['./development-demand-status-badge.component.css']
})
export class DevelopmentDemandStatusBadgeComponent implements OnInit {

  @Input() value: string;
  constructor() { }

  ngOnInit(): void {
  }

}
