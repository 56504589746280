import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RoleCodeEnum } from 'app/enum/role-code.enum';
import { OperationalDemandTypeEnum } from 'app/enum/core/operational-demand-type.enum';
import { AllowedStoreTypeEnum } from 'app/enum/type/allowed-store-type.enum';
import { User } from 'app/models/entity/user';
import { UsersForRoleCodeRequest } from 'app/models/rest/user/user-for-role-code-request';
import { AuthService } from 'app/services/auth.service';
import { AlertifyService } from 'app/services/core/alertify.service';
import { UserService } from 'app/services/user/user.service';
import { OperationalDemandService } from 'app/services/core/operational-demand.service';
import { OperationalDemandEntity } from 'app/models/entity/core/operational-demand-entity';

@Component({
  selector: 'app-user-request-permission-modal',
  templateUrl: './user-request-permission-modal.component.html',
  styleUrls: ['./user-request-permission-modal.component.css']
})
export class UserRequestPermissionModalComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() roleCode: string;

  users: User[];
  isProgressingPayment: boolean = false;
  data: OperationalDemandEntity = new OperationalDemandEntity();
  constructor(
    private auhtService: AuthService,
    private translate: TranslateService,
    private userService: UserService,
    private alertifyService: AlertifyService,
    private operationalDemandservice: OperationalDemandService
  ) { }
  ngOnInit() {
    this.initialLoad();
  }


  private initialLoad() {
    this.data.subject = this.translate.instant("title.user-request-permission");
    this.data.value = this.roleCode;
    this.data.storeId = this.auhtService.getStoreId(),
      this.data.demandTypeId = OperationalDemandTypeEnum.GRANT_ROLE_CODE_TO_USER;
    this.data.message = "Talep Edilen Yetki: " + this.translate.instant("roleCodeValue." + this.roleCode);

    this.getUsers();
  }

  private getUsers() {
    let request = new UsersForRoleCodeRequest();
    request.roleCode = RoleCodeEnum.USER_ROLE_CODE_CREATE;
    request.relatedStoreId = this.auhtService.getStoreId();
    request.allowedType = AllowedStoreTypeEnum.ALLOWED_MANAGE_STORE;
    this.userService.getUsersByRoleCode(request).subscribe(
      res => {
        this.users = res;
        if (this.users.length > 0)
          this.data.demandedUser = this.users[0].id;
      },
      err => {
        this.alertifyService.error(err);
      }
    );
  }

  validateSave() {
    if (this.data.demandedUser == 0) {
      this.alertifyService.warning(this.translate.instant('message.warning.please-select-user'));
      return false;
    }
    return true;
  }
  async save() {
    try {
      if (this.isProgressingPayment) {
        return;
      }
      this.isProgressingPayment = true;

      if (!this.validateSave())
        return false;

      const res = await this.operationalDemandservice.addOperationalDemand(this.data).toPromise();

      this.alertifyService.success(this.translate.instant("message.info.operational-demand-has-been-created"));

      this.passEntry.emit(true);
    }
    catch (err) {
      this.alertifyService.error(err);
    } finally {

      this.isProgressingPayment = false;
    }
  }


  close() {
    this.passEntry.emit(false);
  }

}
