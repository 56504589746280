import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError, BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";
import jwt_decode from 'jwt-decode';
import { NgxPermissionsService } from "ngx-permissions";
import { UserLoginRequest } from "../models/rest/user/userLoginRequest";
import { UserLoginResponse } from "../models/rest/user/userLoginResponse";
import { RefreshTokenRequest } from "../models/rest/user/refresh-token-request";
import { RequestCacheService } from "./core/request-cache.service";
import { StorageConstants } from "app/constant/storage.constant";
import { RouteEnum } from 'app/enum/route.enum';
import { DevicePreferenceTypeEnum } from 'app/enum/type/device-preference-type.enum';
import { SessionStorageService } from "./session-storage.service";
import { LocalStorageService } from "./local-storage.service";
import { isNotNull, isNullOrEmpty } from "app/helper/utils";

const url = `${environment.apiUrl}/auth/login`;
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<UserLoginResponse>;
  public currentUser: Observable<UserLoginResponse>;
  private isScreenLockedSubject: BehaviorSubject<boolean>;
  public isScreenLocked: Observable<boolean>;
  private refreshTokenTimeout;

  constructor(
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private http: HttpClient,
    public router: Router,
    private permissionsService: NgxPermissionsService,
    private cache: RequestCacheService,
  ) {
    this.currentUserSubject = new BehaviorSubject<UserLoginResponse>(
      this.sessionStorageService.getJSONData(StorageConstants.CURRENT_USER) //TODO
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.isScreenLockedSubject = new BehaviorSubject<boolean>(false);
    this.isScreenLocked = this.isScreenLockedSubject.asObservable();
  }

  public get currentUserValue(): UserLoginResponse {
    return this.currentUserSubject.value;
  }
  login(request: UserLoginRequest): Observable<any> {
    const url = `${environment.apiUrl}/auth/login`;

    return this.http.post(url, request);
  }


  refreshToken() {
    const url = `${environment.apiUrl}/auth/refresh-token`;
    let request = new RefreshTokenRequest();
    request.token = this.getToken();
    request.refreshToken = this.getRefreshToken();
    request.appVersion = environment.VERSION;
    return this.http.post<UserLoginResponse>(url, request)
  }


  loginForCustomer(key: string, phoneNumber: string): Observable<any> {
    const url = `${environment.apiUrl}/auth/customer-login/key=${key}&phone-number=${phoneNumber}`;

    return this.http.get(url);
  }

  register(user): Observable<any> {
    const url = `${environment.apiUrl}/auth/register`;
    return this.http.post(url, user);
  }
  createDemo(demo): Observable<any> {
    const url = `${environment.apiUrl}/auth/demo-link`;
    return this.http.post(url, demo);
  }

  async saveAfterLogin(user: UserLoginResponse) {
    this.cache.clear();
    this.sessionStorageService.setJSONData(StorageConstants.CURRENT_USER, user);
    this.sessionStorageService.remove(StorageConstants.IS_IDDLE);
    this.currentUserSubject.next(user);
    this.isScreenLockedSubject.next(null);
    this.setDevicePreferencesToStorage();

    this.setSessionStorageToLocal();
    let isRememberMe = this.localStorageService.getBoolean(StorageConstants.IS_REMEMBER_ME, false);
    if (isRememberMe) {
      this.localStorageService.setString(StorageConstants.LAST_LOCAL_USERNAME, user.userName);
      this.localStorageService.setString(StorageConstants.LAST_LOCAL_TOKEN, this.getToken());
      this.localStorageService.setString(StorageConstants.LAST_LOCAL_REFRESH_TOKEN, this.getRefreshToken());
    }
    else {
      this.localStorageService.remove(StorageConstants.LAST_LOCAL_USERNAME);
      this.localStorageService.remove(StorageConstants.LAST_LOCAL_TOKEN);
      this.localStorageService.remove(StorageConstants.LAST_LOCAL_REFRESH_TOKEN);
    }
    // console.log(user);
    // this.getRoleCodes();
  }

  doLogout() {
    this.clearStorageForLogin();
    this.router.navigate([RouteEnum.LOGIN], { skipLocationChange: false });
  }

  clearStorageForLogin() {
    this.cache.clear();
    this.stopRefreshTokenTimer();
    this.sessionStorageService.remove(StorageConstants.OWNER_ID);
    this.sessionStorageService.remove(StorageConstants.TOKEN_KEY);
    this.sessionStorageService.remove(StorageConstants.REFRESH_TOKEN_KEY);
    this.sessionStorageService.remove(StorageConstants.CURRENT_USER);
    this.localStorageService.remove(StorageConstants.IS_REMEMBER_ME);
    this.localStorageService.remove(StorageConstants.LAST_LOCAL_USERNAME);
    this.localStorageService.remove(StorageConstants.LAST_LOCAL_TOKEN);
    this.localStorageService.remove(StorageConstants.LAST_LOCAL_REFRESH_TOKEN);
    this.currentUserSubject.next(null);
    this.unlockScreen();

  }
  async saveAfterRefreshToken(res: UserLoginResponse) {

    this.setOwnerId(res.ownerId);
    this.saveToken(res.token);
    this.saveRefreshToken(res.refreshToken);

    let isRememberMe = this.localStorageService.getBoolean(StorageConstants.IS_REMEMBER_ME, false);

    if (isRememberMe) {
      let lastLocalUserName = this.localStorageService.getString(StorageConstants.LAST_LOCAL_USERNAME);

      if (lastLocalUserName == res.userName) {
        this.localStorageService.setString(StorageConstants.LAST_LOCAL_TOKEN, res.token);
        this.localStorageService.setString(StorageConstants.LAST_LOCAL_REFRESH_TOKEN, res.refreshToken);
      }
    }
  }

  //token ı local storage yaz
  saveToken(token) {
    this.sessionStorageService.setString(StorageConstants.TOKEN_KEY, token);

  }

  setOwnerId(ownerId?: number) {
    this.sessionStorageService.setString(StorageConstants.OWNER_ID, ownerId?.toString());

  }

  removeToken() {
    this.sessionStorageService.remove(StorageConstants.TOKEN_KEY);
  }
  saveRefreshToken(token) {
    this.sessionStorageService.setString(StorageConstants.REFRESH_TOKEN_KEY, token);
  }
  //token ı local storage yaz
  saveCustomerKey(key) {
    this.sessionStorageService.setString(StorageConstants.CUSTOMER_KEY, key);
  }
  getToken() {
    return this.sessionStorageService.getString(StorageConstants.TOKEN_KEY);
  }
  getRefreshToken() {
    return this.sessionStorageService.getString(StorageConstants.REFRESH_TOKEN_KEY);
  }
  get isLoggedIn(): boolean {

    let authToken = this.sessionStorageService.getString(StorageConstants.TOKEN_KEY);

    return (isNotNull(authToken)) ? true : false;
  }

  //token decode et
  getDecodedToken() {
    //  console.log(this.getToken());
    return jwt_decode(this.getToken());
  }

  public getRoleCodes() {
    this.permissionsService.flushPermissions();
    if (this.currentUserValue && this.currentUserValue.roleCodes)
      this.permissionsService.loadPermissions(this.currentUserValue.roleCodes);
    // console.log(this.permissionsService.permissions$);
  }

  public setDevicePreferencesToStorage() {

    if (this.currentUserValue && this.currentUserValue.devicePreferences) {
      for (let item of this.currentUserValue.devicePreferences) {
        if (item.shortCode === DevicePreferenceTypeEnum.PRINTER_RECEIPT) {
          this.sessionStorageService.setString(StorageConstants.PRINTER_RECEIPT, item.value);
        }
        else if (item.shortCode === DevicePreferenceTypeEnum.PRINTER_LABEL) {
          this.sessionStorageService.setString(StorageConstants.PRINTER_LABEL, item.value);
        }
        else if (item.shortCode === DevicePreferenceTypeEnum.PRINTER_ADDITIONAL_RECEIPT) {
          this.sessionStorageService.setString(StorageConstants.PRINTER_ADDITIONAL_RECEIPT, item.value);
        }
        else if (item.shortCode === DevicePreferenceTypeEnum.PRINTER_BILLING) {
          this.sessionStorageService.setString(StorageConstants.PRINTER_BILLING, item.value);
        }
        else if (item.shortCode === DevicePreferenceTypeEnum.PRINTER_PACKAGE_LABEL) {
          this.sessionStorageService.setString(StorageConstants.PRINTER_PACKAGE_LABEL, item.value);
        }
        else if (item.shortCode === DevicePreferenceTypeEnum.DEVICE_LOCAL_STORAGE) {

          this.sessionStorageService.setString(StorageConstants.DEVICE_LOCAL_STORAGE, item.value);
        }
      }
    }
  }

  public setSessionStorageToLocal() {

    if (this.currentUserValue && this.currentUserValue.sessionStorages) {
      for (let item of this.currentUserValue.sessionStorages) {
        this.sessionStorageService.setString(item.key, item.value);
      }
    }
  }

  getCurrentUserId() {
    return this.currentUserValue.externalUserId ?? this.currentUserValue.id;
  }
  getCurrentUserName() {
    return this.currentUserValue.userName;
  }
  getCurrentUserScreenName() {
    return this.currentUserValue.screenName;
  }
  getStoreId() {
    return this.currentUserValue.storeId;
  }
  getCountryId() {
    return this.currentUserValue.countryId;
  }
  getCurrenDeviceId() {
    return this.currentUserValue.deviceId;
  }

  getCurrenDeviceCode() {
    return this.currentUserValue.deviceCode;
  }
  getCustomerId() {
    return this.currentUserValue.customerId;
  }
  getStoreAddressId() {
    return this.currentUserValue.storeAddressId ?? 0;
  }
  getDevicePreferences() {
    return this.currentUserValue.devicePreferences;
  }
  getDevicePreference(shortCode: string) {
    return this.currentUserValue.devicePreferences.filter(x => x.shortCode == shortCode)[0] ?? null;
  }

  getCountryPhoneCode() {
    return this.currentUserValue.countryPhoneCode;
  }
  getHasPaid() {
    return this.currentUserValue.hasPaid;
  }
  sendPasswordResetLink(username) {
    const url = `${environment.apiUrl}/auth/password-reset-link/username=${username}`;
    return this.http.get(url).pipe(catchError(this.errorHandler));
  }

  getLinkStatus(key) {
    const url = `${environment.apiUrl}/auth/link-status/key=${key}`;
    return this.http.get(url).pipe(catchError(this.errorHandler));
  }

  confirmEmail(key) {
    const url = `${environment.apiUrl}/auth/confirm/key=${key}`;
    return this.http.get(url, key).pipe(catchError(this.errorHandler));
  }



  resetPassword(resetPasswordRequest) {
    const url = `${environment.apiUrl}/auth/reset-password`;
    return this.http
      .post(url, resetPasswordRequest)
      .pipe(catchError(this.errorHandler));
  }

  getLanguageCode() {
    return localStorage.getItem(StorageConstants.LANGUAGE_CODE);
  }

  setLanguageCode(languageCode) {
    return this.localStorageService.setString(StorageConstants.LANGUAGE_CODE, languageCode);
  }

  // helper methods

  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
  private getTokenExpiredDate(token: string) {
    if (isNullOrEmpty(token)) return new Date(1900);

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    var jwtToken = JSON.parse(window.atob(base64));

    // parse json object from base64 encoded jwt token
    // const jwtToken = JSON.parse(atob(token.split('.')[1]));
    return new Date(jwtToken.exp * 1000);
  }


  public startRefreshTokenTimer() {
    const token = this.getToken();
    if (token == null)
      return;
    // set a timeout to refresh the token a minute before it expires
    const expires = this.getTokenExpiredDate(token);

    let timeout = expires.getTime() - Date.now() - (60 * 1000);
    // Eğer timeout 10 dakikadan küçükse, 10 dakika olarak ayarla
    if (timeout < 600000) {
      timeout = 600000; // 10 dakika = 600000 milisaniye
    }

    // console.log('timeout:' + timeout);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(
      (res) => {
        this.setOwnerId(res.ownerId);
        this.saveToken(res.token);
        this.saveRefreshToken(res.refreshToken);
        this.startRefreshTokenTimer();
      },
    ), timeout);
  }


  public stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  lockScreen() {
    this.isScreenLockedSubject.next(true);
  }
  unlockScreen() {
    this.isScreenLockedSubject.next(null);
  }



  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
