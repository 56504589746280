<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ 'menuitem.help-video' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>

<div class="modal-body">
  <!-- Güncelleme  -->
  <app-preloader *ngIf="loading"></app-preloader>
  <div class="card" *ngIf="!loading">
    <div class="card-body">
      <div class="row">

        <input class="col-sm-12 col-md-4 col-lg-4 form-control" type="text" name="search" [(ngModel)]="searchText" placeholder="{{'hint.search-in-result' | translate}}" appUppercase autocompleteOff
          ngbAutoFocus>

        <ngb-pagination class="col-sm-12 col-md-6 col-lg-8 d-flex justify-content-end" [(page)]="page" [pageSize]="pageSize" [collectionSize]="dataList.length" [maxSize]="3">
        </ngb-pagination>
      </div>

      <div class="row">
        <div class="col-sm-6 col-md-3 col-lg-2" *ngFor="let video of (dataList | searchList: searchText:'title'  | slice: (page-1) * pageSize : page * pageSize)">
          <div class="card">
            <div class="card-headar">
              <iframe [src]="video.url | safeUrl" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
            </div>

            <div class="card-body">
              <label class="custom-form-label">{{video.title}}</label>
              <button *ngxPermissionsOnly="[roleCodeEnum.DEVELOPER]" class="btn btn-warning btn-sm m-1 pull-right" (click)="openAddUpdateModal(video)">
                <i class="fa fa-edit"></i>
                <!-- {{'button.update' | translate}} -->
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">

    <button *ngxPermissionsOnly="[roleCodeEnum.DEVELOPER]" class="btn btn-primary m-1 pull-right" (click)="openAddUpdateModal()">
      <i class="fa fa-plus"></i>
      {{'button.add-new' | translate}}
    </button>
    <button type="button" class="btn btn-secondary" (click)="close()">
      {{'button.close' | translate}}
    </button>
  </div>