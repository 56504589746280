import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from "rxjs";
import { catchError } from 'rxjs/operators';
import { BaseRestService } from '../base-rest.service';
import { UserSessionStorageEntity } from 'app/models/entity/user/user-session-storage.entity';
import { UserSessionStorageMergeRequest } from 'app/models/rest/user/user-session-storage-merge-request';
import { UserSessionStorageDeleteRequest } from 'app/models/rest/user/user-session-storage-delete-request';
import { SessionStorageService } from "../session-storage.service";

@Injectable({
  providedIn: 'root'
})
export class UserSessionStorageService extends BaseRestService {
  constructor(protected httpClient: HttpClient, protected sessionStorageService: SessionStorageService) {
    super(httpClient, sessionStorageService);
  }
  //Listele
  getUserSessionStorages(): Observable<UserSessionStorageEntity[]> {
    const url = `/user-session-storages`;
    return this.httpGet<UserSessionStorageEntity[]>(url).pipe(catchError(this.errorHandler));
  }

  // ekle
  addUserSessionStorage(data) {
    const url = `/user-session-storages`;
    return this.httpPost<UserSessionStorageEntity>(url, data)
      .pipe(catchError(this.errorHandler));
  }

  // güncelle
  updateUserSessionStorage(id, data): Observable<UserSessionStorageEntity> {
    const url = `/user-session-storages/${id}`;
    return this.httpPut<UserSessionStorageEntity>(url, data)
      .pipe(catchError(this.errorHandler));
  }

  // sil
  deleteUserSessionStorage(id) {
    const url = `/user-session-storages/${id}`;
    return this.httpDelete(url).pipe(catchError(this.errorHandler));
  }

  deleteUserSessionStorageByRequest(data: UserSessionStorageDeleteRequest) {
    const url = `/user-session-storages/delete`;
    return this.httpPost(url, data)
      .pipe(catchError(this.errorHandler));
  }
  mergeUserSessionStorage(data: UserSessionStorageMergeRequest): Observable<UserSessionStorageEntity> {
    const url = `/user-session-storages/merge`;
    return this.httpPost<UserSessionStorageEntity>(url, data)
      .pipe(catchError(this.errorHandler));
  }

  mergeUserSessionStorageBulk(data: UserSessionStorageMergeRequest[]): Observable<UserSessionStorageEntity[]> {
    const url = `/user-session-storages/merge-bulk`;
    return this.httpPost<UserSessionStorageEntity[]>(url, data)
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    //
    // console.log(error);
    return observableThrowError(error);
  }
}
