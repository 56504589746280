import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneDisplayFormat'
})
export class PhoneDisplayFormatPipe implements PipeTransform {
  transform(phoneNumber: string): string {
    if (!phoneNumber) {
      return '';
    }

    let returnValue: string;
    if (phoneNumber.length == 9) {
      returnValue = `(${phoneNumber.substring(0, 2)})${phoneNumber.substring(2, 5)}-${phoneNumber.substring(5, 7)}-${phoneNumber.substring(7, 9)}`;
    }
    else if (phoneNumber.length == 10) {
      returnValue = `(${phoneNumber.substring(0, 3)})${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6, 8)}-${phoneNumber.substring(8, 10)}`;
    } else if (phoneNumber.length == 11) {
      returnValue = `${phoneNumber.substring(0, 1)}(${phoneNumber.substring(1, 4)})${phoneNumber.substring(4, 7)} ${phoneNumber.substring(7, 9)} ${phoneNumber.substring(9, 11)}`;
    } else {
      returnValue = phoneNumber;
    }

    return returnValue;
  }
}
