import { Injectable } from '@angular/core';
import { isNullOrEmpty } from 'app/helper/utils';
import { StorageConstants } from 'app/constant/storage.constant';
import { LocalLogModel } from 'app/models/dto/local-log.model';
import { DeviceLocalStorageData } from 'app/models/rest/user/device-local-storage-data.model';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }
  getString(key: string, defValue?: string) {
    let value = localStorage.getItem(key);
    return isNullOrEmpty(value) ? defValue : value;
  }
  setString(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getNumber(key: string, defValue?: number) {
    let value = localStorage.getItem(key);
    return isNullOrEmpty(value) ? defValue : Number(value);
  }

  setNumber(key: string, value: number) {
    localStorage.setItem(key, value == null ? null : value.toString());
  }


  getBoolean(key: string, defValue?: boolean) {
    let value = localStorage.getItem(key);
    if (isNullOrEmpty(value) && defValue != null) {
      return defValue;
    } else {
      return value.toLowerCase() === 'true';
    }
  }

  setBoolean(key: string, value: boolean) {
    localStorage.setItem(key, value == null ? null : value.toString());
  }

  getJSONData(key: string) {
    let value = localStorage.getItem(key);

    return JSON.parse(value);
  }
  setJSONData(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getDeviceStorageData() {
    let data = this.getJSONData(StorageConstants.DEVICE_LOCAL_STORAGE);
    if (data == null) {
      data = new DeviceLocalStorageData();
      this.setJSONData(StorageConstants.DEVICE_LOCAL_STORAGE, data);

    }
    return data;
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }


}
