export class UserLoginRequest {
  userName?: string;
  password?: string;
  deviceType?: string;
  deviceCode?: string;
  languageCode?: string;
  appVersion?: string;
  localAppVersion?: string;
  operatingSystem?: string;
}
