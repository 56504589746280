export class CustomerSearchRequest {
  reportType?: string;
  customerId?: number;
  parentCustomerId?: number;
  cardNumber?: string;
  customerName?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  siteName?: string;
  buildingName?: string;
  doorNum?: string;
  addressDescription?: string;
  storeIds?: number[];
  customerTypeIds?: number[];
  communityIds?: number[];
  minDiscount?: number;
  maxDiscount?: number;
  isForeign?: boolean;
  isBilling?: boolean;
  hasCardNumber?: boolean;
  billingProfileId?: string;
  showBalance?: boolean;
  notes?: string;
  billingName?: string;
  taxId?: string;
  taxOffice?: string;
  mobilePhoneNumbers?: string[];
}
