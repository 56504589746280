export enum DateRangeEnum {
  TODAY = "TODAY",
  THIS_WEEK = "THIS_WEEK",
  THIS_MONTH = "THIS_MONTH",
  THIS_YEAR = "THIS_YEAR",

  YESTERDAY = "YESTERDAY",
  LAST_WEEK = "LAST_WEEK",
  LAST_MONTH = "LAST_MONTH",
  LAST_YEAR = "LAST_YEAR",

  TOMORROW = "TOMORROW",
  NEXT_WEEK = "NEXT_WEEK",
  NEXT_MONTH = "NEXT_MONTH",
  NEXT_YEAR = "NEXT_YEAR",

  LAST7DAY = "LAST7DAY",
  LAST30DAY = "LAST30DAY",
  LAST90DAY = "LAST90DAY",

  FIRST_WEEK_OF_MONTH = "FIRST_WEEK_OF_MONTH",
  SECOND_WEEK_OF_MONTH = "SECOND_WEEK_OF_MONTH",
  THIRD_WEEK_OF_MONTH = "THIRD_WEEK_OF_MONTH",
  FOURTH_WEEK_OF_MONTH = "FOURTH_WEEK_OF_MONTH",

  ALL = "ALL",
  CUSTOM = "CUSTOM"
}
