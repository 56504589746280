<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ (data?.id?'title.update-record':'title.new-record') | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>

<div class="modal-body">
  <!-- Güncelleme  -->
  <div>
    <form #createForm="ngForm" class="needs-validation" novalidate>
      <div class="card">
        <div class="card-body">
          <div>

            <!-- Ad-->
            <div class="form-group">
              <label class="custom-form-label" for="title">{{'common.title' | translate}}</label>
              <input #title="ngModel" type="text" class="form-control" name="title" [(ngModel)]="data.title" required [class.is-invalid]="title.invalid && title.touched" ngbAutoFocus appUppercase />
              <app-field-error-display [displayError]="title.invalid && title.touched" errorMsg="{{'message.error.cannot-be-blank' | translate}}">
              </app-field-error-display>
            </div>

            <!-- Açıklama -->
            <div class="form-group">
              <label class="custom-form-label" for="description">{{'common.description' | translate}}</label>
              <textarea type="text" #description="ngModel" name="description" [(ngModel)]="data.description" class="form-control" rows="10"></textarea>
            </div>

            <!-- Ad-->
            <div class="form-group">
              <label class="custom-form-label" for="tags">{{'common.tags' | translate}}</label>
              <input #tags="ngModel" type="text" class="form-control" name="tags" [(ngModel)]="data.tags" />

            </div>
          </div>

          <!-- Ekran Sıra No -->
          <div class="form-group">
            <label class="custom-form-label" for="shortCode">{{'common.screenOrderNo' | translate}}</label>
            <input #screenOrderNo="ngModel" type="number" class="form-control" name="discount" [(ngModel)]="data.screenOrderNo" [class.is-invalid]="screenOrderNo.invalid && screenOrderNo.touched" />
          </div>

          <!-- Resim Url-->
          <div class="form-group">
            <label class="custom-form-label" for="imageUrl">{{'common.imageUrl' | translate}}</label>
            <input #imageUrl="ngModel" type="text" class="form-control" name="imageUrl" [(ngModel)]="data.url" [class.is-invalid]="imageUrl.invalid && imageUrl.touched" />

          </div>
        </div>

      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">
    {{'button.close' | translate}}
  </button>

  <button type="button" [disabled]="createForm.form.invalid" class="btn btn-primary" (click)="save()">
    {{'button.save' | translate}}
  </button>
</div>