import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { AuthService } from "../services/auth.service";
import { UserLoginResponse } from "app/models/rest/user/userLoginResponse";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {

        if (error.status === 0 && !navigator.onLine) {
          // İnternet bağlantısı yoksa özel bir hata mesajı fırlat
          return throwError("İnternet bağlantınız yok. Lütfen bağlantınızı kontrol edin ve tekrar deneyin.");

        }
        else if (error instanceof HttpErrorResponse && error.status === 401) {
          if (error.url && error.url.includes('refresh-token')) {
            // Refresh token isteği başarısız olursa logout yap
            this.authService.doLogout();
            return throwError("Lütfen tekrar giriş yapınız.");
          } else {
            // Diğer 401 hataları için handle401Error metodunu çağır
            return this.handle401Error(request, next);
          }
        } else {
          // Hata mesajlarını formatlama
          let errorMessage = '';


          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `${error.error.message}`;

          } else if (error.error != null) {
            // server-side error
            errorMessage = error.error.message || error.error.Message || error.message || error.statusText;

          } else {
            // server-side error
            errorMessage = error.status + " " + error.message;

          }
          return throwError(errorMessage);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((res: UserLoginResponse) => {
          this.isRefreshing = false;
          this.authService.saveAfterRefreshToken(res);
          this.refreshTokenSubject.next(res.token);
          return next.handle(this.addToken(request, res.token));
        }),
        catchError((error) => {
          // Refresh token isteği başarısız olursa logout yap
          this.isRefreshing = false;
          this.authService.doLogout();
          return throwError("Oturumunuz sonlandırıldı, lütfen tekrar giriş yapınız.");
        })
      );
    } else {
      // Refresh işlemi devam ederken gelen istekleri refreshTokenSubject üzerinden yönet
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(request, token));
        })
      );
    }
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    // Mevcut header'ları kopyala
    const headers = request.headers.set('Authorization', `Bearer ${token}`);

    // Request'i klonla ve güncellenmiş header'ları kullan
    return request.clone({ headers });
  }
}
