import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dispatch-status-badge',
  templateUrl: './dispatch-status-badge.component.html',
  styleUrls: ['./dispatch-status-badge.component.css']
})
export class DispatchStatusBadgeComponent implements OnInit {

  @Input() value: number;
  constructor() { }

  ngOnInit(): void {
  }

}
