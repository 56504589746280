import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appUppercase]'
})
export class UppercaseDirective {
  private previousValue: string = '';

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    const inputElement = this.el.nativeElement;
    const start = inputElement.selectionStart;
    const end = inputElement.selectionEnd;

    // Metni büyük harfe çevir
    const uppercasedValue = inputElement.value.replace('i', 'İ').toUpperCase();

    // Modeli manuel olarak güncelle
    if (inputElement.value !== uppercasedValue) {
      inputElement.value = uppercasedValue;

      // Eğer bir input event tetiklemek gerekirse sadece değişiklik olduğunda yapılmalı
      inputElement.dispatchEvent(new Event('input', { bubbles: true }));
    }

    // İmleç pozisyonunu koru
    inputElement.setSelectionRange(start, end);
  }
}
