import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "numberArray" })
export class NumberArrayPipe implements PipeTransform {
  transform(maxValue, startValue: number, increment: number): any {
    let res = [];
    for (let i = startValue; i <= maxValue;) {
      res.push(i);
      if (increment)
        i = i + increment;
      else
        i++;
    }
    return res;
  }
}
