import { AuthService } from "./../../services/auth.service";
import { Component, EventEmitter, Output, Inject, OnInit } from "@angular/core";

import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { UserLoginResponse } from "../../models/rest/user/userLoginResponse";
import { RoleCodeEnum } from "app/enum/role-code.enum";
import { NavigationService } from "app/services/navigation.service";
import { DownloadService } from "app/services/core/download.service";
import { DOCUMENT } from "@angular/common";
import { DeviceTypeEnum } from "app/enum/type/device-type.enum";
import { PrinterService } from "app/services/core/printer.service";
import { AlertifyService } from "app/services/core/alertify.service";
import { TranslateService } from "@ngx-translate/core";
import { ModalSizeEnum } from "app/enum/modal-size.enum";
import { NgbModal, NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { HelpVideoModalComponent } from "app/core/help-video-modal/help-video-modal.component";
import { LockScreenComponent } from "app/pages/core/lock-screen/lock-screen.component";
import { DeviceLocalStorageData } from "app/models/rest/user/device-local-storage-data.model";
import { YesNoModalComponent } from "app/core/yes-no-modal/yes-no-modal.component";
import { YesNoModalTypeEnum } from "app/enum/type/yes_no_modal_type.enum";
import { environment } from "environments/environment";
import { StorageConstants } from "app/constant/storage.constant";
import { SessionStorageService } from "app/services/session-storage.service";
import { ReminderOperationalService } from "app/services/reminder/reminder-operational.service";
import { DateFormatEnum } from "app/enum/date-format.enum";
import { ReminderViewModalComponent } from "app/pages/core/reminder-view-modal/reminder-view-modal.component";
import { clone } from "app/helper/utils";
import { firstValueFrom, Subscription } from "rxjs";
import { UserOperationalService } from "app/services/user-operational.service";
import { ModalUtilsService } from "app/services/core/modal-utils.service";
import { AppVersionResponse } from "app/models/rest/app/app-version-response";
declare var $: any;

interface FsDocument extends HTMLDocument {
  webkitFullscreenElement: Element;
  webkitExitFullscreen: any;
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
}

export function isFullScreen(): boolean {
  const fsDoc = <FsDocument>document;

  return !!(fsDoc.fullscreenElement || fsDoc.mozFullScreenElement || fsDoc.webkitFullscreenElement || fsDoc.msFullscreenElement);
}

interface FsDocumentElement extends HTMLElement {
  webkitRequestFullscreen: any;
  msRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
}

export function toggleFullScreen(): void {
  const fsDoc = <FsDocument>document;

  if (!isFullScreen()) {
    const fsDocElem = <FsDocumentElement>document.documentElement;

    if (fsDocElem.requestFullscreen)
      fsDocElem.requestFullscreen();
    else if (fsDocElem.msRequestFullscreen)
      fsDocElem.msRequestFullscreen();
    else if (fsDocElem.mozRequestFullScreen)
      fsDocElem.mozRequestFullScreen();
    else if (fsDocElem.webkitRequestFullscreen)
      fsDocElem.webkitRequestFullscreen();
  }
  else if (fsDoc.exitFullscreen)
    fsDoc.exitFullscreen();
  else if (fsDoc.msExitFullscreen)
    fsDoc.msExitFullscreen();
  else if (fsDoc.mozCancelFullScreen)
    fsDoc.mozCancelFullScreen();
  else if (fsDoc.webkitExitFullscreen)
    fsDoc.webkitExitFullscreen();
}

export function setFullScreen(full: boolean): void {
  if (full !== isFullScreen())
    toggleFullScreen();
}


@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  currentUser: UserLoginResponse;
  roleCodeEnum = RoleCodeEnum;
  deviceTypeEnum = DeviceTypeEnum;
  deviceLocalStorageData: DeviceLocalStorageData;
  version;
  dateFormatEnum = DateFormatEnum;
  localAppVersion?: AppVersionResponse;

  reminderOpenModalSubscription: Subscription;

  constructor(@Inject(DOCUMENT) private document: any,
    public downloadService: DownloadService,
    private offcanvasService: NgbOffcanvas,
    private modalService: NgbModal,
    private navigation: NavigationService,
    private authService: AuthService,
    private printerService: PrinterService,
    private alertifyService: AlertifyService,
    private translate: TranslateService,
    private sessionStorageService: SessionStorageService,
    public reminderOperationalService: ReminderOperationalService,
    private userOperationalService: UserOperationalService,
    private modalUtilsService: ModalUtilsService,
  ) {
    this.authService.currentUser.subscribe(async (res) => {
      this.currentUser = res;
      if (this.currentUser) {

        this.reminderOperationalService.startReminderTimer();
        this.reminderOpenModalSubscription = this.reminderOperationalService.onReminderNotifyModalChanged()
          .subscribe((res) => {
            if (res)
              this.openReminderModal(res);
          });
      }
      else {
        this.reminderOperationalService.stopReminderTimer();
      }
    });
  }

  ngOnInit(): void {

    this.version = environment.VERSION;
    this.deviceLocalStorageData = this.sessionStorageService.getDeviceStorageData();


  }

  ngOnDestroy() {

  }

  async getLocalAppVersion() {
    try {

      const res = await firstValueFrom(this.printerService.getAppVersion());

      this.localAppVersion = res
    }
    catch (err) {
      // this.alertifyService.error(err);
    }
  }



  openReminderModal(data) {
    if (this.reminderOperationalService.isReminderModalOpen || !this.authService.isLoggedIn) {
      // Modal zaten açık, yeni bir modal açmayı engelle
      return;
    }

    this.reminderOperationalService.updateModalStatus(true);
    const modalRef = this.modalService.open(ReminderViewModalComponent, {
      backdrop: true, centered: true, scrollable: true, size: ModalSizeEnum.MEDIUM
    });

    //modal  veri geçişi
    modalRef.componentInstance.data = clone(data);
    //modal cevap döndüğünde
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (receivedEntry) {
      }

      // modal kapat
      modalRef.close();

    });
    modalRef.result.then(
      (success) => {
        this.reminderOperationalService.updateModalStatus(false);
      },
      (cancel) => {
        this.reminderOperationalService.updateModalStatus(false);
      }
    );

  }

  async openPrinterSetting() {
    const result = await this.modalUtilsService.openPrinterSettingModal();
    if (!result)
      return;
  }
  // This is for Notifications
  notifications: Object[] = [
    {
      round: "round-danger",
      icon: "ti-link",
      title: "Luanch Admin",
      subject: "Just see the my new admin!",
      time: "9:30 AM",
    },
    {
      round: "round-success",
      icon: "ti-calendar",
      title: "Event today",
      subject: "Just a reminder that you have event",
      time: "9:10 AM",
    },
    {
      round: "round-info",
      icon: "ti-settings",
      title: "Settings",
      subject: "You can customize this template as you want",
      time: "9:08 AM",
    },
    {
      round: "round-primary",
      icon: "ti-user",
      title: "Pavan kumar",
      subject: "Just see the my admin!",
      time: "9:00 AM",
    },
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: "assets/images/users/1.jpg",
      status: "online",
      from: "Pavan kumar",
      subject: "Just see the my admin!",
      time: "9:30 AM",
    },
    {
      useravatar: "assets/images/users/2.jpg",
      status: "busy",
      from: "Sonu Nigam",
      subject: "I have sung a song! See you at",
      time: "9:10 AM",
    },
    {
      useravatar: "assets/images/users/2.jpg",
      status: "away",
      from: "Arijit Sinh",
      subject: "I am a singer!",
      time: "9:08 AM",
    },
    {
      useravatar: "assets/images/users/4.jpg",
      status: "offline",
      from: "Pavan kumar",
      subject: "Just see the my admin!",
      time: "9:00 AM",
    },
  ];


  logout() {

    const modalRef = this.modalService.open(YesNoModalComponent, {
      backdrop: "static", centered: true, scrollable: true, size: ModalSizeEnum.MEDIUM, keyboard: true
    });
    modalRef.componentInstance.modalType = YesNoModalTypeEnum.WARNING;
    modalRef.componentInstance.title = this.translate.instant('title.confirmation');
    modalRef.componentInstance.message = this.translate.instant('confirmation.exit-app');
    //modal cevap döndüğünde
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {

      // modal kapat
      modalRef.close();

      // Cevap boş değilse
      if (receivedEntry) {
        this.offcanvasService.dismiss();
        this.authService.doLogout();
      }
    });

  }
  changeScreenSize() {
    toggleFullScreen();
  }

  goBack() {
    this.navigation.back();
  }

  checkPrinterStatus() {
    this.printerService.getPrinterServiceIsAlive().subscribe(
      (res) => {
        this.alertifyService.success(this.translate.instant('message.info.printer-service-is-active'));

      },
      (err) => {

        this.alertifyService.error(this.translate.instant('message.error.printer-service-is-not-active'));
      }
    )

  }


  openHelpVideoModal() {
    const modalRef = this.modalService.open(HelpVideoModalComponent, {
      backdrop: "static", centered: true, scrollable: true, size: ModalSizeEnum.XLARGE
    });

    //modal cevap döndüğünde
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      // Cevap boş değilse
      if (receivedEntry) {


      }

      // modal kapat
      modalRef.close();
    });
  }

  openCashDrawer() {
    this.printerService.openCashRegister(this.sessionStorageService.getString(StorageConstants.PRINTER_RECEIPT));
  }
  openPin() {
    if (!this.currentUser) {
      return;
    }
    const modalRef = this.modalService.open(LockScreenComponent, {
      backdrop: "static", centered: true, scrollable: true, size: ModalSizeEnum.MEDIUM, keyboard: false
    });

    //modal cevap döndüğünde
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {

      // modal kapat
      modalRef.close();

      // Cevap boş değilse
      // if (receivedEntry) {
      // }
      // else {
      //   this.authService.doLogout();
      // }

    });
  }

  lockScreen() {
    this.authService.lockScreen(); const modalRef = this.modalService.open(LockScreenComponent, {
      backdrop: "static", centered: true, scrollable: true, size: ModalSizeEnum.MEDIUM, keyboard: false
    });

    //modal cevap döndüğünde
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {

      // modal kapat
      modalRef.close();
      // this.reset();

      // Cevap boş değilse
      if (receivedEntry) {
        this.authService.unlockScreen();
      }
      else {
        this.authService.doLogout();
      }

    });
  }

  reloadApp() {
    window.location.reload();
  }

  // Bildirim türüne göre ikon sınıfını döndüren bir metod
  getNotificationIconClass(notificationType: string): string {
    switch (notificationType) {
      case 'REMINDER': return 'fa-clock-o'; // REMINDER için saat ikonu
      case 'OPERATIONAL_DEMAND': return 'fa-cogs'; // OPERATIONAL_DEMAND için dişli ikonu
      case 'ORDER': return 'fa-shopping-cart'; // ORDER için alışveriş sepeti ikonu
      default: return 'fa-bell'; // Varsayılan ikon
    }
  }
  getNotificationClass(notificationDate: Date): string {
    const currentDate = new Date();
    const oneHourLater = new Date(currentDate.getTime() + (60 * 60 * 1000)); // Şu andan 1 saat sonrası

    if (notificationDate < currentDate) {
      return 'past-notification'; // Geçmiş zaman için
    } else if (notificationDate < oneHourLater) {
      return 'soon-notification'; // 1 saat içinde olacaklar için
    } else {
      return ''; // Diğer durumlar için
    }
  }
  openUserProfile(content) {

    if (this.localAppVersion == null) {
      this.getLocalAppVersion();
    }
    this.offcanvasService.open(content, { ariaLabelledBy: 'offcanvas-basic-title' }).result.then((result) => {
      if (result == "Save click") {
      }
    }, (reason) => {
    });
  }

  async saveUserSessionStorage() {
    try {

      await this.userOperationalService.setLocalStorageToDatabase();
      this.alertifyService.success(this.translate.instant("message.info.saved"));
    }
    catch (err) {
      this.alertifyService.error(err);

    }
  }

}
