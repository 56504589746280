<header class="topbar">
  <app-time-check></app-time-check>
  <nav class="d-flex top-navbar navbar-expand-md navbar-light">
    <!-- ============================================================== -->
    <!-- Logo -->
    <!-- ============================================================== -->
    <div class="navbar-header  hidden-lg-down">
      <a class="navbar-brand" [routerLink]="currentUser?.homePage">
        <!-- Logo icon -->
        <b>
          <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
          <!-- Dark Logo icon -->
          <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo" />
          <!-- Light Logo icon -->
          <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo" />
        </b>
        <!--End Logo icon -->
        <!-- Logo text -->
        <span>
          <!-- dark Logo text -->
          <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo" />
          <!-- Light Logo text -->
          <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage" />
        </span>
      </a>
    </div>
    <!-- ============================================================== -->
    <!-- End Logo -->
    <!-- ============================================================== -->
    <div class="navbar-collapse">
      <!-- ============================================================== -->
      <!-- toggle and nav items -->
      <!-- ============================================================== -->
      <ul class="navbar-nav mr-auto mt-md-0 text-center">
        <!-- This is  -->
        <li (click)="toggleSidebar.emit()" class="nav-item m-l-10">
          <a class="nav-link sidebartoggler waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a>
        </li>
        <!-- ============================================================== -->
        <!-- Search -->
        <!-- ============================================================== -->


        <li class="nav-item hidden-sm-down" *ngxPermissionsOnly="[roleCodeEnum.MENU_DASHBOARD]">
          <a class="nav-link text-muted waves-effect waves-dark" [skipLocationChange]="false" routerLink="/pages/dashboard" triggers="mouseenter:mouseleave" placement="top" container="body"
            [ngbPopover]="'menuitem.dashboard'| translate">
            <i class="fa fa-tachometer fa-2x text-white"></i>
          </a>
        </li>

        <li class="nav-item search-box " placement="bottom-right">
          <a class="nav-link text-muted waves-effect waves-dark" [skipLocationChange]="false" routerLink="/pages/home-menu">
            <!-- <i class="fa fa-home"></i> -->
            {{'menuitem.home-menu' | translate}}
          </a>

        </li>

        <li class="nav-item hidden-sm-down search-box " placement="bottom-right" *ngxPermissionsOnly="[roleCodeEnum.MENU_CUSTOMER_SEARCH]">
          <a class="nav-link text-muted waves-effect waves-dark" [skipLocationChange]="false" routerLink="/pages/customer-search">
            <!-- <i class="fa fa-search"></i> -->
            {{'menuitem.customer-search' | translate}}
          </a>
        </li>

        <li class="nav-item hidden-sm-down" *ngxPermissionsOnly="[roleCodeEnum.MENU_ORDER_SEARCH]">
          <a class="nav-link text-muted waves-effect waves-dark" [skipLocationChange]="false" routerLink="/pages/search-order" triggers="mouseenter:mouseleave" placement="top" container="body"
            [ngbPopover]="'menuitem.search-order'| translate">
            <i class="fa fa-search fa-2x"></i>
          </a>
        </li>

        <li class="nav-item hidden-sm-down" *ngxPermissionsOnly="[roleCodeEnum.MENU_ORDER_LIST]">
          <a class="nav-link text-muted waves-effect waves-dark" [skipLocationChange]="false" routerLink="/pages/order-list" triggers="mouseenter:mouseleave" placement="top" container="body"
            [ngbPopover]="'menuitem.order-list'| translate">
            <i class="fa fa-shopping-cart fa-2x"></i>
          </a>
        </li>

        <li class="nav-item hidden-md-down" *ngxPermissionsOnly="[roleCodeEnum.MENU_CASH_REPORT]">
          <a class="nav-link text-muted waves-effect waves-dark" [skipLocationChange]="false" routerLink="/pages/cash-report" triggers="mouseenter:mouseleave" placement="top" container="body"
            [ngbPopover]="'menuitem.cash-report'| translate">
            <i class="fa fa-file-text-o fa-2x"></i>
          </a>
        </li>


        <li class="nav-item hidden-sm-down" *ngxPermissionsOnly="[roleCodeEnum.MENU_DEVICE]">
          <a class="nav-link text-muted waves-effect waves-dark" [skipLocationChange]="false" routerLink="/pages/device-setting" triggers="mouseenter:mouseleave" placement="top" container="body"
            [ngbPopover]="'menuitem.device-settings'| translate">
            <i class="fa fa-cog fa-2x "></i>
          </a>
        </li>
        <li class="nav-item  hidden-sm-down">
          <a class="nav-link text-muted waves-effect waves-dark" (click)="openPrinterSetting()" triggers="mouseenter:mouseleave" placement="top" container="body"
            [ngbPopover]="'menuitem.printer-settings'| translate">
            <i class="fa fa-print fa-2x "></i>
          </a>
        </li>
        <li class="nav-item hidden-lg-down" *ngxPermissionsOnly="[roleCodeEnum.MENU_CALLERID_HISTORY_REPORT]">
          <a class="nav-link text-muted waves-effect waves-dark" [skipLocationChange]="false" routerLink="/pages/callerid-history-report" triggers="mouseenter:mouseleave" placement="top"
            container="body" [ngbPopover]="'menuitem.callerid-history-report'| translate">
            <i class="fa fa-phone fa-2x"></i>
          </a>
        </li>
        <li class="nav-item hidden-lg-down" *ngxPermissionsOnly="[roleCodeEnum.MENU_SMS_LOG_REPORT]">
          <a class="nav-link text-muted waves-effect waves-dark" [skipLocationChange]="false" routerLink="/pages/sms-log-report" triggers="mouseenter:mouseleave" placement="top" container="body"
            [ngbPopover]="'menuitem.sms-log-report'| translate">
            <i class="fa fa-commenting fa-2x"></i>
          </a>
        </li>
        <li class="nav-item  hidden-lg-down">
          <a class="nav-link text-muted waves-effect waves-dark" (click)="openHelpVideoModal()" triggers="mouseenter:mouseleave" placement="top" container="body"
            [ngbPopover]="'menuitem.help-video'| translate">
            <i class="fa fa-question-circle fa-2x "></i>
          </a>
        </li>
        <!-- <li class="nav-item" *ngIf="deviceLocalStorageData?.isOpenCashRegisterAfterPrintReceipt">
          <a class="nav-link hidden-sm-down text-muted waves-effect waves-dark" (click)="openCashDrawer()" triggers="mouseenter:mouseleave" placement="top" container="body"  [ngbPopover]="'button.open-cash-drawer'| translate">

            <i class="fa fa-money  fa-2x"></i>
          </a>
        </li> -->



        <!-- ============================================================== -->
        <!-- Comment -->
        <!-- ============================================================== -->
        <li #reminderDropdown="ngbDropdown" class="nav-item dropdown" ngbDropdown placement="bottom-left">
          <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark" href="javascript:void(0)">
            <i class="fa fa-bell fa-2x"></i>
            <span class=" badge badge-danger notification-badge">{{ reminderOperationalService.reminders?.length }}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-left mailbox" ngbDropdownMenu>
            <ul>
              <li>
                <div class="drop-title">Hatırlatıcılar</div>
              </li>
              <li>
                <div class="message-center" [perfectScrollbar]="config">
                  <a (click)="openReminderModal(notification)" *ngFor="let notification of reminderOperationalService.reminders">
                    <div class="round" [ngClass]="getNotificationClass(notification.notificationDate)">
                      <i class="fa fa-2x {{ getNotificationIconClass(notification.type) }}"></i>
                    </div>
                    <div class="mail-contnet ms-2">
                      <h5>{{ notification.title }}</h5>
                      <span class="mail-desc">{{ notification.message }}</span>
                      <span class="time">{{ notification.remindDate | date: dateFormatEnum.DATE_TIME_FORMAT_DISPLAY }}</span>
                      <span class="notification-date">{{ notification.notificationDate | date: dateFormatEnum.DATE_TIME_FORMAT_DISPLAY }}</span>
                    </div>
                  </a>
                </div>


              </li>
              <li>
                <a class="nav-link text-center" [skipLocationChange]="false" routerLink="/pages/reminder" (click)="reminderDropdown.close()">
                  <strong>{{'button.add-new' | translate}}</strong>
                </a>
              </li>
            </ul>
          </div>
        </li>


        <li class="nav-item">
          <a class="nav-link text-muted waves-effect waves-dark" (click)="goBack()" triggers="mouseenter:mouseleave" placement="top" container="body" [ngbPopover]="'button.back'| translate">
            <i class="fa fa-arrow-left  fa-2x"></i>
            <!-- {{'button.back' | translate}} -->
          </a>
        </li>

        <!-- ============================================================== -->
        <!-- End Comment -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Messages -->
        <!-- ============================================================== -->
        <!-- <li class="nav-item dropdown" ngbDropdown placement="bottom-left">
          <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark" href="javascript:void(0)">
            <i class="mdi mdi-email"></i>
            <div class="notify">
              <span class="heartbit"></span>
              <span class="point"></span>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-left mailbox" ngbDropdownMenu>
            <ul>
              <li>
                <div class="drop-title">You have 4 new messages</div>
              </li>
              <li>
                <div class="message-center" [perfectScrollbar]="config">
                  <a href="#" *ngFor="let mymessage of mymessages">
                    <div class="user-img">
                      <img src="{{ mymessage.useravatar }}" alt="user" class="img-circle" width="40" />
                      <span class="profile-status {{ mymessage.status }} pull-right"></span>
                    </div>
                    <div class="mail-contnet">
                      <h5>{{ mymessage.from }}</h5>
                      <span class="mail-desc">{{ mymessage.subject }}</span>
                      <span class="time">{{ mymessage.time }}</span>
                    </div>
                  </a>
                </div>
              </li>
              <li>
                <a class="nav-link text-center" href="javascript:void(0);">
                  <strong>See all e-Mails</strong>
                  <i class="fa fa-angle-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </li> -->
        <!-- ============================================================== -->
        <!-- End Messages -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Messages -->
        <!-- ============================================================== -->
        <!-- <li class="nav-item dropdown mega-dropdown" ngbDropdown [autoClose]="false">
          <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark" href="javascript:void(0)">
            <i class="mdi mdi-view-grid"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
            <ul class="mega-dropdown-menu row">
              <li class="col-lg-3 col-xlg-2 m-b-30">
                <h4 class="m-b-20">CAROUSEL</h4>
                <ngb-carousel>
                  <ng-template ngbSlide>
                    <img src="assets/images/big/img1.jpg" class="img-responsive" alt="Random first slide" />
                    <div class="carousel-caption">
                      <h3 class="text-white font-bold">First slide label</h3>
                    </div>
                  </ng-template>
                  <ng-template ngbSlide>
                    <img src="assets/images/big/img2.jpg" class="img-responsive" alt="Random second slide" />
                    <div class="carousel-caption">
                      <h3 class="text-white font-bold">Second slide label</h3>
                    </div>
                  </ng-template>
                  <ng-template ngbSlide>
                    <img src="assets/images/big/img3.jpg" class="img-responsive" alt="Random third slide" />
                    <div class="carousel-caption">
                      <h3 class="text-white font-bold">Third slide label</h3>
                    </div>
                  </ng-template>
                </ngb-carousel>
              </li>
              <li class="col-lg-3 m-b-30">
                <h4 class="m-b-20">ACCORDION</h4>
                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" class="accordion nav-accordion">
                  <ngb-panel title="Simple">
                    <ng-template ngbPanelContent>
                      Anim pariatur cliche reprehenderit, enim eiusmod high life
                      accusamus terry richardson ad squid.
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel>
                    <ng-template ngbPanelTitle>
                      <span>&#9733; <b>Fancy</b> title &#9733;</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      Anim pariatur cliche reprehenderit, enim eiusmod high life
                      accusamus terry richardson ad squid.
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel title="Disabled" [disabled]="true">
                    <ng-template ngbPanelContent>
                      Anim pariatur cliche reprehenderit, enim eiusmod high life
                      accusamus terry richardson ad squid.
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </li>

              <li class="col-lg-3 m-b-30">
                <h4 class="m-b-20">CONTACT US</h4>
                <form>
                  <div class="form-group">
                    <input type="text" class="form-control" id="exampleInputname1" placeholder="Enter Name" />
                  </div>
                  <div class="form-group">
                    <input type="email" class="form-control" placeholder="Enter email" />
                  </div>
                  <div class="form-group">
                    <textarea class="form-control" id="exampleTextarea" rows="3" placeholder="Message"></textarea>
                  </div>
                  <button type="submit" class="btn btn-info">Submit</button>
                </form>
              </li>
              <li class="col-lg-3 col-xlg-4 m-b-30">
                <h4 class="m-b-20">List style</h4>
                <ul class="list-style-none">
                  <li>
                    <a href="javascript:void(0)">
                      <i class="fa fa-check text-success"></i> You can give
                      link</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <i class="fa fa-check text-success"></i> Give link</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <i class="fa fa-check text-success"></i> Another Give
                      link</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <i class="fa fa-check text-success"></i> Forth link</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <i class="fa fa-check text-success"></i> Another fifth
                      link</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </li> -->





        <!-- ============================================================== -->
        <!-- End Messages -->
        <!-- ============================================================== -->
      </ul>


      <div class="spacer"></div>
      <!-- ============================================================== -->
      <!-- User profile and search -->
      <!-- ============================================================== -->
      <ul class="navbar-nav my-lg-0">
        <li class="nav-item">
          <a class="nav-link text-muted waves-effect waves-dark" (click)="reloadApp()" triggers="mouseenter:mouseleave" placement="top" container="body" [ngbPopover]="'button.update-app'| translate">
            v{{version}}
          </a>
        </li>
      </ul>

      <ul class="navbar-nav my-lg-0 hidden-sm-down" *ngIf="currentUser">
        <li class="nav-item">
          <a class="nav-link text-muted waves-effect waves-dark" (click)="openPin()" title="{{currentUser.screenName}}">
            <i class="fa fa-user"></i>
            {{currentUser.screenName | slice:0:15}}{{currentUser.screenName.length > 15 ? '...' : ''}}
          </a>
        </li>

      </ul>

      <app-clock-widget *ngIf="deviceLocalStorageData?.showClockOnHeader"></app-clock-widget>


      <ul class="navbar-nav my-lg-0 ">
        <li class="nav-item  hidden-lg-down" *ngIf="currentUser?.deviceType==deviceTypeEnum.WEB">
          <a class="nav-link text-muted waves-effect waves-dark" (click)="lockScreen()" triggers="mouseenter:mouseleave" placement="top" container="body"
            [ngbPopover]="'button.lock-screen'| translate">
            <i class="fa fa-lock  fa-2x"></i>
            <!-- {{'button.change-screen-mode' | translate}} -->
          </a>
        </li>

        <li class="nav-item" *ngIf="currentUser?.deviceType==deviceTypeEnum.WEB">
          <a class="nav-link text-muted waves-effect waves-dark" (click)="changeScreenSize()" triggers="mouseenter:mouseleave" placement="top" container="body"
            [ngbPopover]="'button.change-screen-mode'| translate">
            <i class="fa fa-expand  fa-2x"></i>
            <!-- {{'button.change-screen-mode' | translate}} -->
          </a>
        </li>

        <li class="nav-item hidden-sm-down">
          <a class="nav-link text-muted waves-effect waves-dark " [skipLocationChange]="false" (click)="logout()" triggers="mouseenter:mouseleave" placement="top" container="body"
            [ngbPopover]="'authentication.logout'| translate">
            <i class="fa fa-sign-out  fa-2x text-danger"></i>
            <!-- {{'authentication.logout' | translate}} -->
          </a>
        </li>


        <!-- ============================================================== -->
        <!-- Profile -->
        <!-- ============================================================== -->
        <!-- <li class="nav-item dropdown" ngbDropdown placement="bottom-right" *ngIf="currentUser">
          <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img [src]="currentUser.avatarUrl" alt="user" class="profile-pic" />
          </a>
          <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu>
            <ul class="dropdown-user">
              <li>
                <div class="dw-user-box" *ngIf="currentUser">
                  <div class="u-img">
                    <img [src]="currentUser.avatarUrl" alt="user" />
                  </div>
                  <div class="u-text">
                    <h4>{{currentUser.screenName}}</h4>
                    <h5>{{currentUser.storeName}}</h5>
                    <p class="text-muted">{{currentUser.userName}}</p>
                  </div>
                </div>
              </li>

              <li class="nav-item">
                <a (click)="checkPrinterStatus()">
                  <i class="fa fa-printer"></i>
                  {{'button.check-printer-status' | translate}}
                </a>
              </li>

              <li class="nav-item" *ngxPermissionsOnly="[roleCodeEnum.MENU_DEVICE]">
                <a [skipLocationChange]="false" routerLink="/pages/device-setting">
                  <i class="fa fa-setting"></i>
                  {{'menuitem.device-settings' | translate}}
                </a>
              </li>

              <li class="nav-item" *ngIf="currentUser.deviceType==deviceTypeEnum.WEB">
                <a href="https://aktifmusteri.com/download/setup/destek_al.exe">
                  <i class="fa fa-download"></i>
                  {{'link.download-support-app' | translate}}
                </a>
              </li>

              <li class="nav-item" *ngIf="currentUser.deviceType==deviceTypeEnum.WEB">
                <a href="https://aktifmusteri.com/download/setup/aktifmusteri.exe">
                  <i class="fa fa-download"></i>
                  {{'link.download-windows-app' | translate}}
                </a>
              </li>


              <li class="nav-item" *ngIf="currentUser.deviceType==deviceTypeEnum.WEB">
                <a href="https://aktifmusteri.com/download/setup/JavaSetup8.exe">
                  <i class="fa fa-download"></i>
                  {{'link.download-java' | translate}}
                </a>
              </li>



              <li role="separator" class="divider"></li>
              <li>
                <a (click)="logout()">
                  <i class="fa fa-power-off"></i> Çıkış</a>
              </li>
            </ul>
          </div>
        </li> -->
        <li class="nav-item" *ngIf="currentUser">
          <a class="nav-link text-muted waves-effect waves-dark" [skipLocationChange]="false" (click)="openUserProfile(userProfileContent)" triggers="mouseenter:mouseleave" placement="top"
            container="body" [ngbPopover]="'title.login-user-detail'| translate">
            <i class="fa fa-user text-o fa-2x"></i>
          </a>
        </li>

      </ul>
    </div>
  </nav>
</header>


<ng-template #userProfileContent let-offcanvas>
  <div class="offcanvas-header">
    <h5 class="card-title">{{ 'title.login-user-detail' | translate }}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
  </div>
  <div class="offcanvas-body">
    <div class="card user-details-card">
      <div *ngIf="currentUser" class="card-body user-info">
        <div class="user-avatar">
          <img [src]="currentUser.avatarUrl" alt="user">
        </div>
        <div class="user-text">
          <h4>{{ currentUser.screenName }}</h4>
          <h5>{{ currentUser.storeName }}</h5>
          <p class="text-muted">{{ currentUser.userName }}</p>
        </div>
      </div>
      <div class="card-footer links">

        <div class="nav-item" *ngxPermissionsOnly="[roleCodeEnum.MENU_DEVICE]">
          <a [skipLocationChange]="false" routerLink="/pages/device-setting">
            <i class="fa fa-cogs"></i> {{ 'menuitem.device-settings' | translate }}
          </a>
        </div>
        <div *ngIf="currentUser.deviceType==deviceTypeEnum.WEB" class="nav-item">
          <a (click)="downloadService.downloadSupportApp()">
            <i class="fa fa-download"></i> {{ 'link.download-support-app' | translate }}
          </a>
        </div>
        <div *ngIf="currentUser.deviceType==deviceTypeEnum.WEB" class="nav-item">
          <a (click)="downloadService.downloadAnyDesk()">
            <i class="fa fa-download"></i> {{ 'link.download-any-desk' | translate }}
          </a>
        </div>
        <div *ngIf="currentUser.deviceType==deviceTypeEnum.WEB" class="nav-item">
          <a (click)="downloadService.downloadAktifMusteri()">
            <i class="fa fa-download"></i> {{ 'link.download-windows-app' | translate }}
          </a>
        </div>
        <div *ngIf="currentUser.deviceType==deviceTypeEnum.WEB" class="nav-item">
          <a (click)="downloadService.downloadJava()">
            <i class="fa fa-download"></i> {{ 'link.download-java' | translate }}
          </a>
        </div>
        <div class="nav-item">
          <a [skipLocationChange]="false" routerLink="/pages/owner-sales-product">
            <i class="fa fa-bars"></i> {{ 'menuitem.owner-sales-product' | translate }}
          </a>
        </div>

        <div class="col-sm-12 d-grid gap-2 mt-5">
          <button class="btn btn-outline-primary" type="button" (click)="checkPrinterStatus()">
            {{'button.check-printer-status' | translate}}
          </button>
        </div>
        <div class="col-sm-12 d-grid gap-2 mt-2">
          <button class="btn btn-outline-primary" type="button" (click)="saveUserSessionStorage()">
            {{'button.save-user-session-settings' | translate}}
          </button>
        </div>
        <div class="col-sm-12 d-grid gap-2 mt-2">
          <button class="btn btn-danger" type="button" (click)="logout()">
            {{'authentication.logout' | translate}}
          </button>
        </div>
        <div class="text-center mt-3">
          <a class="nav-link text-muted waves-effect waves-dark" (click)="reloadApp()" triggers="mouseenter:mouseleave" placement="top" container="body" [ngbPopover]="'button.update-app'| translate">
            v{{version}}
          </a>
        </div>

        <div class="text-center mt-3" *ngIf="localAppVersion">
          <a class="nav-link text-muted waves-effect waves-dark" (click)="downloadAktifMusteri()" triggers="mouseenter:mouseleave" placement="top" container="body"
            [ngbPopover]="'link.download-windows-app'| translate">
            Lokal Servis: v{{localAppVersion.version}}
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-template>