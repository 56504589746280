import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError as observableThrowError, Observable, of } from "rxjs";
import { BaseRestService } from "../base-rest.service";
import { HelpVideoUrlModel } from "app/models/entity/help-video-url.model";
import { RequestCacheService } from "./request-cache.service";
import { SessionStorageService } from "../session-storage.service";

@Injectable({
  providedIn: 'root'
})
export class HelpVideoUrlService extends BaseRestService {
  constructor(protected httpClient: HttpClient, protected sessionStorageService: SessionStorageService, private cache: RequestCacheService) {
    super(httpClient, sessionStorageService);
  }

  getVideoUrls(): Observable<HelpVideoUrlModel[]> {
    const url = `/help-video-urls`;
    return this.httpGet<HelpVideoUrlModel[]>(url).pipe(catchError(this.errorHandler));
  }

  getVideoUrlById(id) {
    const url = `/help-video-urls/${id}`;
    return this.httpGet(url).pipe(catchError(this.errorHandler));
  }

  addVideoUrl(data) {
    const url = `/help-video-urls`;
    return this.httpPost<HelpVideoUrlModel>(url, data).pipe(catchError(this.errorHandler));
  }

  updateVideoUrl(id, data): Observable<HelpVideoUrlModel> {
    const url = `/help-video-urls/${id}`;
    return this.httpPut<HelpVideoUrlModel>(url, data)
      .pipe(catchError(this.errorHandler));
  }

  deleteVideoUrl(id) {
    const url = `/help-video-urls/${id}`;
    return this.httpDelete(url).pipe(catchError(this.errorHandler));
  }

  importImage(data) {
    const url = `/help-video-urls/upload-image`;
    return this.httpPost(url, data).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    if (error.message)
      return observableThrowError(error);
    else return observableThrowError(error || " Server Error");
  }
}
