import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tags-badge',
  templateUrl: './tags-badge.component.html',
  styleUrls: ['./tags-badge.component.css']
})
export class TagsBadgeComponent implements OnInit {

  @Input() value: string;
  constructor() { }

  ngOnInit(): void {
  }


}
