<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{data?.title}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>

<div class="modal-body  text-center">
  {{data?.message}}
</div>
<div class="modal-footer d-flex justify-content-end" *ngIf="data.type ==userNotificationTypeEnum.REMINDER">


  <select #selectedDelayInput name="selectedDelayInput" class="form-control w-auto me-2" [(ngModel)]="selectedDelay">
    <option *ngFor="let item of reminderDelayEnumValues" [value]="item">
      {{ "durationMinute."+ item | translate }}
    </option>
  </select>
  <button class="btn btn-warning me-2" (click)="delay()">
    <i class="fa fa-add" aria-hidden="true"></i>
    {{'button.delay' | translate}}
  </button>
  <button class="btn btn-danger" (click)="terminate()">
    <i class="fa fa-add" aria-hidden="true"></i>
    {{'button.terminate' | translate}}
  </button>
  <button class="btn btn-primary ms-2" (click)="goUrl()" *ngIf="data.redirectUrl">
    <i class="fa fa-add" aria-hidden="true"></i>
    {{'button.go-to-related-menu' | translate}}
  </button>
</div>

<div class="modal-footer d-flex justify-content-end" *ngIf="data.type !==userNotificationTypeEnum.REMINDER">

  <button class="btn btn-outline-primary me-2" (click)="goScreen()">
    <i class="fa fa-add" aria-hidden="true"></i>
    {{getGoScreenButtonTitle()}}
  </button>

</div>