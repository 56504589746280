export enum StorageConstants {
  LOCAL_LOG = "localLog",
  DEVICE_LOCAL_STORAGE = "deviceLocalStorage",

  PRINTER_RECEIPT = "printerReceipt",
  PRINTER_LABEL = "printerLabel",
  PRINTER_ADDITIONAL_RECEIPT = "printerAdditionalLabel",
  PRINTER_PACKAGE_LABEL = "printerPackageLabel",
  PRINTER_BILLING = "printerBilling",

  SEARCH_CRITERIA = "searchCriteria",

  // CITIES = "cities",
  // DISTRICTS = "districts",

  OWNER_ID = "ownerId",
  CURRENT_USER = "currentUser",
  TOKEN_KEY = "tokenKey",
  REFRESH_TOKEN_KEY = "refreshTokenKey",
  LANGUAGE_CODE = "lang",
  CUSTOMER_KEY = "customerKey",
  LAST_HOME_MENU_TITLE = "lastHomeMenuTitle",


  // CUSTOMER_SEARCH_FOCUS_FIELD = "customer-search-focus-field",
  // CUSTOMER_SEARCH_ADDRESS_DETAIL = "customer-search-address-detail",

  LAST_REPORT_PARAMETER_FOR_ORDER_LIST = "last-report-parameter-for-order-list",
  LAST_REPORT_PARAMETER_FOR_BONUS_LIST = "last-report-parameter-for-bonus-list",
  LAST_REPORT_PARAMETER_FOR_STORE_DAILY_REPORT = "last-report-parameter-for-store-daily-report",
  LAST_PARAMETER_FOR_CUSTOMER_SEARCH_ADVANCE = "last-parameter-for-customer-advance-search",
  LAST_REPORT_PARAMETER_FOR_ORDER_ITEM_PROCESS_REPORT = "last-report-parameter-for-order-item-process-report",
  LAST_REPORT_PARAMETER_FOR_ORDER_ITEM_DISPATCH_PROCESS_REPORT = "last-report-parameter-for-order-item-dispatch-process-report",
  LAST_REPORT_PARAMETER_FOR_SUPPLIR_DISPATCH_ITEM_REPORT = "last-report-parameter-for-supplier-dispatch-item-report",
  LAST_REPORT_PARAMETER_FOR_STOCK_TRACKING_ORDER_RELATION_LIST = "last-report-parameter-for-stock-tracking-order-relation-list",
  LAST_REPORT_PARAMETER_FOR_ORDER_ITEM_SEARCH_LIST = "last-report-parameter-for-order-order-item-search-list",
  LAST_REPORT_PARAMETER_FOR_PACK_LIST = "last-report-parameter-for-order-pack-list",

  // PAGE_SIZE_ORDER = "page-size-order",
  LAST_PROCESS_ORDER_SEARCH_SCREEN = "last-process-order-search-screen",
  INCOMING_CALL = "incoming-call",
  IS_IDDLE = "is-iddle",

  COLUMN_PREFIX = "column.",
  IS_REMEMBER_ME = "is-remember-me",
  LAST_LOCAL_USERNAME = "last-local-username",
  LAST_LOCAL_TOKEN = "last-local-token",
  LAST_LOCAL_REFRESH_TOKEN = "last-local-refresh-token",
  LAST_PAYMENT_DETAIL_CHANGE_TYPE = "last-payment-detail-change-type"


}

export const StorageConstantsForDatabaseValues: string[] = [
  StorageConstants.LAST_REPORT_PARAMETER_FOR_ORDER_LIST,
  StorageConstants.LAST_REPORT_PARAMETER_FOR_BONUS_LIST,
  StorageConstants.LAST_REPORT_PARAMETER_FOR_STORE_DAILY_REPORT,
  StorageConstants.LAST_PARAMETER_FOR_CUSTOMER_SEARCH_ADVANCE,
  StorageConstants.LAST_REPORT_PARAMETER_FOR_ORDER_ITEM_PROCESS_REPORT,
  StorageConstants.LAST_REPORT_PARAMETER_FOR_ORDER_ITEM_DISPATCH_PROCESS_REPORT,
  StorageConstants.LAST_REPORT_PARAMETER_FOR_SUPPLIR_DISPATCH_ITEM_REPORT,
  StorageConstants.LAST_REPORT_PARAMETER_FOR_STOCK_TRACKING_ORDER_RELATION_LIST,
  StorageConstants.LAST_REPORT_PARAMETER_FOR_ORDER_ITEM_SEARCH_LIST,
  StorageConstants.LAST_REPORT_PARAMETER_FOR_PACK_LIST,
  StorageConstants.LAST_PROCESS_ORDER_SEARCH_SCREEN,
  StorageConstants.COLUMN_PREFIX
]

