import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SoundService {
  private audioNotification = new Audio();
  private intervalNotificationId: any;
  private repeatCount = 0;
  private maxRepeats = 20;

  constructor() {
    this.audioNotification.src = "./assets/sounds/notification-sound-2.mp3";
    this.audioNotification.load();
  }

  async playNotificationSound() {
    if (this.repeatCount < this.maxRepeats) {
      await this.audioNotification.play();
      this.repeatCount++;
    } else {
      this.stopRepeatingSound();
    }
  }

  startRepeatingSound() {
    this.repeatCount = 0;
    this.stopRepeatingSound(); // Önceki tekrarı durdur
    this.intervalNotificationId = setInterval(() => this.playNotificationSound(), 3000);
  }

  stopRepeatingSound() {
    if (this.intervalNotificationId) {
      clearInterval(this.intervalNotificationId);
    }
  }
}
