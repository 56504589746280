export class DeviceLocalStorageData {
  isPrintReceipt?: boolean;
  isPrintReceiptForPickupOrder?: boolean;
  isPrintLabel?: boolean;
  receiptCount?: number;
  deliveryPaymentTypeId?: number;
  newOrderPaymentTypeId?: number;
  showOrdersOnSearch?: boolean;
  showDetailCriteriaOnCustomerSearch?: boolean;
  focusFieldOnCustomerSearch?: string;
  squareMeterType?: string;
  orderListRowsPerPage?: number;
  productRowsPerPage?: number;
  productCountForRow?: number;
  customerSearchRowsPerPage?: number;
  printAdditionalReceiptAfterReady?: boolean;
  isCallerIdActive: boolean;
  isVoipActive: boolean;
  voipInternalId?: number;
  voipInternalNoForStartCall?: string;
  posDeviceId?: number;
  isOpenCashRegisterAfterPrintReceipt?: boolean;
  showUnSawedOrderNotification?: boolean;
  showOperationalDemandNotification?: boolean;
  showCategoryAsSelectMode?: boolean;
  showClockOnHeader?: boolean;
  cashRegisterId?: number;
  goCustomerForOrderOnCustomerSearch?: boolean;
}
