import { UserLoginResponse } from "./../../models/rest/user/userLoginResponse";
import { AuthService } from "./../../services/auth.service";
import { Component, OnInit, HostListener } from "@angular/core";
import { Router } from "@angular/router";

import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { MenuItemWithSubDto } from "../../models/dto/menuItemWithSubDto";
import { RouteEnum } from 'app/enum/route.enum';

@Component({
  selector: "app-full-layout",
  templateUrl: "./full.component.html",
  styleUrls: ["./full.component.scss"],
})
export class FullComponent implements OnInit {
  color = "blue";
  showSettings = false;
  showMinisidebar = true;
  showDarktheme = false;
  showRtl = false;
  items: MenuItemWithSubDto[];
  currentUser: UserLoginResponse;

  public innerWidth: any;

  public config: PerfectScrollbarConfigInterface = {};

  constructor(
    private authService: AuthService,
    public router: Router
  ) { }

  ngOnInit() {
    if (this.router.url === "/") {
      this.router.navigate([RouteEnum.HOME], { skipLocationChange: false });
    }
    this.handleLayout();
    this.currentUser = this.authService.currentUserValue;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.handleLayout();
  }

  toggleSidebar() {
    this.showMinisidebar = !this.showMinisidebar;
  }

  handleLayout() {
    this.innerWidth = window.innerWidth;
    // 1170 yerine 1400 yapıldı
    //ÖZHAN
    // if (this.innerWidth < 1400) {
    //   this.showMinisidebar = true;
    // } else {
    //   this.showMinisidebar = false;
    // }
  }
}
