import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { throwError as observableThrowError, Observable, Subject } from "rxjs";
import { BaseRestService } from "../base-rest.service";
import { ReminderEntity } from "app/models/entity/core/reminder.entity";
import { CommonRequest } from "app/models/rest/common/commonRequest";
import { ReminderViewModel } from "app/models/dto/core/reminder-view.model";
import { ReminderDelayRequest } from "app/models/rest/reminder/reminder-delay-request.model";
import { SessionStorageService } from "../session-storage.service";





@Injectable({
  providedIn: 'root'
})
export class ReminderService extends BaseRestService {
  public remindersChanged = new Subject<void>();

  constructor(protected httpClient: HttpClient, protected sessionStorageService: SessionStorageService) {
    super(httpClient, sessionStorageService);
  }
  // Hatırlatıcı listesinde değişiklik olduğunda çağrılacak
  notifyRemindersChanged() {
    this.remindersChanged.next();
  }

  // Değişiklikleri dinlemek için
  onRemindersChanged(): Observable<void> {
    return this.remindersChanged.asObservable();
  }

  getReminders(): Observable<ReminderEntity[]> {
    const url = `/reminders`;
    return this.httpGet(url).pipe(catchError(this.errorHandler));
  }

  getReminderById(id): Observable<ReminderEntity> {
    const url = `/reminders/${id}`;
    return this.httpGet(url).pipe(catchError(this.errorHandler));
  }

  addReminder(data): Observable<ReminderEntity> {
    const url = `/reminders`;
    return this.httpPost<ReminderEntity>(url, data).pipe(catchError(this.errorHandler),
      tap(() => this.remindersChanged.next()));
  }



  updateReminder(id, data): Observable<ReminderEntity> {
    const url = `/reminders/${id}`;
    return this.httpPut(url, data)
      .pipe(catchError(this.errorHandler), tap(() => this.remindersChanged.next()));
  }

  deleteReminder(id) {
    const url = `/reminders/${id}`;
    return this.httpDelete(url).pipe(catchError(this.errorHandler), tap(() => this.remindersChanged.next()));
  }

  getRemindersBySearch(request: CommonRequest): Observable<ReminderViewModel[]> {
    const url = `/reminders/search`;
    return this.httpPost(url, request).pipe(catchError(this.errorHandler));
  }

  terminateById(id): Observable<ReminderEntity> {
    const url = `/reminders/${id}/terminate`;
    return this.httpGet(url).pipe(catchError(this.errorHandler), tap(() => this.remindersChanged.next()));
  }

  delay(request: ReminderDelayRequest): Observable<ReminderEntity> {
    const url = `/reminders/delay`;
    return this.httpPost(url, request).pipe(catchError(this.errorHandler), tap(() => this.remindersChanged.next()));
  }

  errorHandler(error: HttpErrorResponse) {
    if (error.message)
      return observableThrowError(error);
    else return observableThrowError(error || " Server Error");
  }
}
