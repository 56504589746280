// import defaultLanguage from "./../assets/i18n/tr.json";
// import enLanguage from "./../assets/i18n/en.json";
import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "environments/environment";
import { PrimeNGConfig } from "primeng/api";
import { filter } from "rxjs/operators";
import { AuthService } from "./services/auth.service";
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { UserLoginResponse } from "./models/rest/user/userLoginResponse";
import { ModalSizeEnum } from "./enum/modal-size.enum";
import { LockScreenComponent } from "./pages/core/lock-screen/lock-screen.component";
import { StorageConstants } from "./constant/storage.constant";
import { CallerIdLogCreateResponseModel } from "./models/rest/voip/caller-id-log-create-response.model";
import { DeviceVoipInternalResponse } from "./models/rest/voip/device-voip-internal-response.model";
import { VoipQueueStatusRequest } from "./models/rest/voip/voip_queue_status_request.model";
import { VoipOperationalService } from "./services/voip/voip-operational.service";
import { DeviceLocalStorageData } from "./models/rest/user/device-local-storage-data.model";
import { SessionStorageService } from "./services/session-storage.service";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {

  @ViewChild('childModal', { static: false }) childModal: ElementRef;


  currentUser: UserLoginResponse;

  // some fields to store our state so we can display it in the UI
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  public modalRef: NgbModalRef;
  isScreenLocked: boolean = false;
  hasIddleTime: boolean = false;


  callerIdLogResponse: CallerIdLogCreateResponseModel;
  deviceVoipInternalResponse: DeviceVoipInternalResponse;
  voipQueueStatusRequest: VoipQueueStatusRequest = new VoipQueueStatusRequest();
  deviceLocalStorageData: DeviceLocalStorageData;

  constructor(
    private analytics: AngularFireAnalytics,
    private sessionStorageService: SessionStorageService,
    private voipOperationalService: VoipOperationalService,//santral sistemi için tanımlanması yeterli
    private idle: Idle,
    private keepalive: Keepalive,
    private modalService: NgbModal,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private config: PrimeNGConfig) {
    this.authService.currentUser.subscribe((res) => {
      this.currentUser = res;
      this.hasIddleTime = this.currentUser?.idleTime > 0;

    });

    this.setIddleParameter(idle);
    this.setLanguage(translate);
    this.authService.startRefreshTokenTimer();
    this.trackPageView();

  }
  trackPageView() {
    this.analytics.logEvent('page_view', { page_title: 'Home' });
  }
  ngOnInit(): void {
    document.body.classList.add('notranslate');
    this.changeLanguage();
    this.setPrimengTranslate();
    this.authService.getRoleCodes();
    this.setTitleSubscriber();
  }

  private setLanguage(translate: TranslateService) {
    translate.addLangs(['tr', 'en', 'ru', 'az', 'ar', 'de', 'fr', 'uz', 'bg']);
    // translate.setTranslation('tr', defaultLanguage);
    // translate.setTranslation('en', enLanguage);
    translate.setDefaultLang('tr');
  }


  private changeLanguage() {
    const localLang = this.authService.getLanguageCode();

    if (this.translate.currentLang != localLang) {
      this.translate.use(localLang);
    }
  }

  private setPrimengTranslate() {
    this.translate.get('primeng').subscribe(res => {
      this.config.setTranslation(res);
    });
  }

  private setTitleSubscriber() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe(() => {

        var rt = this.getChild(this.activatedRoute);

        rt.data.subscribe(data => {
          if (data.title)
            this.titleService.setTitle(this.translate.instant(data.title));

          else
            this.titleService.setTitle(environment.appName);
        });

        this.setMetaTags();
      });
  }

  private setIddleParameter(idle: Idle) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(5);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(15);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.reset();
      if (this.modalRef != null)
        this.modalRef.close();
    });

    idle.onTimeout.subscribe(() => {
      this.modalRef?.close();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      // this.router.navigate(['/']);
      if (this.hasIddleTime && this.currentUser && !this.isScreenLocked) {
        this.openPin();
      }
    });

    idle.onIdleStart.subscribe(() => {
      if (!this.hasIddleTime || !this.currentUser || this.isScreenLocked) {
        return;
      }

      this.idleState = 'You\'ve gone idle!';

      this.modalRef = this.modalService.open(this.childModal, {
        backdrop: "static", centered: true, scrollable: true, size: ModalSizeEnum.MEDIUM
      });
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = this.translate.instant('message.warning.you-will-time-out-in-seconds').replace('{SECOND}', countdown);

    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.authService.currentUser.subscribe(res => {
      //
      if (res && res.idleTime > 0) {
        this.hasIddleTime = true;
        idle.setIdle(res.idleTime);
        idle.watch();
        this.timedOut = false;

        let isIddle = this.sessionStorageService.getString(StorageConstants.IS_IDDLE);
        if (isIddle == "1")
          this.openPin();

      } else {
        this.hasIddleTime = false;
        idle.stop();
      }
    });

    this.authService.isScreenLocked.subscribe((res) => {
      this.isScreenLocked = res ?? false;

      this.reset();
    });

  }

  reset() {
    if (this.idle != null)
      this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
    this.sessionStorageService.setString(StorageConstants.IS_IDDLE, "0");
  }

  hideChildModal(): void {
    this.modalRef.close();
  }

  stay() {
    this.modalRef.close();
    this.reset();
  }

  logout() {
    this.modalRef.close();
    this.authService.doLogout();
  }

  openPin() {
    if (!this.currentUser || this.isScreenLocked) {
      return;
    }
    this.authService.lockScreen();
    const modalRef = this.modalService.open(LockScreenComponent, {
      backdrop: "static", centered: true, scrollable: true, size: ModalSizeEnum.MEDIUM, keyboard: false
    });

    //modal cevap döndüğünde
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {

      // modal kapat
      modalRef.close();

      // Cevap boş değilse
      if (receivedEntry) {
        this.authService.unlockScreen();
      }
      else {
        this.authService.doLogout();
      }

    });

  }

  setMetaTags() {
    // todo burası çalışmıyor. index hmtldeki değerler geliyor.
    this.metaService.addTags([
      { name: 'keywords', content: 'Kuru Temizleme ,Çamaşırhane, Halı Yıkama , Terzi , Lostra Müşteri Takip Yazılımı' },
      { name: 'description', content: ',Kuru Temizleme ,Çamaşırhane, Halı Yıkama , Terzi , Lostra Müşteri Takip Yazılımı' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Özhan Küçük' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }

}



