import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";


const maxAge = 43200000; // 12 Saat (60*60*12*1000)

@Injectable({
  providedIn: "root",
})
export class RequestCacheService {
  cache = new Map();

  get(url: string) {
    const cached = this.cache.get(url);

    if (!cached) {
      return undefined;
    }

    const isExpired = cached.lastRead < Date.now() - maxAge;
    if (isExpired) {
      this.cache.delete(url);
      return undefined;
    }
    return cached.response;
  }

  put(url: string, response: HttpResponse<any>): void {
    const entry = { url, response, lastRead: Date.now() };
    this.cache.set(url, entry);

    // Süresi geçenleri temizle
    const expired = Date.now() - maxAge;
    this.cache.forEach((expiredEntry, key) => {
      if (expiredEntry.lastRead < expired) {
        this.cache.delete(key);
      }
    });
  }

  clear(): void {
    this.cache.clear();
  }

  /**
   * Belirli bir anahtar içinde geçen bir desene göre önbelleği temizler
   * @param pattern Temizlenecek anahtar desenini içerir
   */
  clearByKeyPattern(pattern: string): void {
    const regex = new RegExp(pattern, "i"); // 'i' harf duyarsız arama
    this.cache.forEach((_, key) => {
      if (regex.test(key)) {
        this.cache.delete(key);
      }
    });
  }
}

