import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError as observableThrowError, Observable } from "rxjs";
import { BaseRestService } from "../base-rest.service";
import { SmsTemplateEntity } from "app/models/entity/sms/sms-template-entity";
import { SessionStorageService } from "../session-storage.service";

@Injectable({
  providedIn: 'root'
})
export class SmsTemplateService extends BaseRestService {
  constructor(protected httpClient: HttpClient, protected sessionStorageService: SessionStorageService) {
    super(httpClient, sessionStorageService);
  }

  //Şablonlar
  getSmsTemplates(): Observable<SmsTemplateEntity[]> {
    const url = `/sms-templates`;
    return this.httpGet<SmsTemplateEntity[]>(url).pipe(catchError(this.errorHandler));
  }

  //Şablon ekle
  addSmsTemplate(data) {
    const url = `/sms-templates`;
    return this.httpPost<SmsTemplateEntity>(url, data)
      .pipe(catchError(this.errorHandler));
  }

  //Şablon güncelle
  updateSmsTemplate(id, data): Observable<SmsTemplateEntity> {
    const url = `/sms-templates/${id}`;
    return this.httpPut<SmsTemplateEntity>(url, data)
      .pipe(catchError(this.errorHandler));
  }

  //Şablon sil
  deleteSmsTemplate(id) {
    const url = `/sms-templates/${id}`;
    return this.httpDelete(url).pipe(catchError(this.errorHandler));
  }

  getSelectableTemplate(): Observable<SmsTemplateEntity[]> {
    const url = `/sms-templates/selectable-template`;
    return this.httpGet<SmsTemplateEntity[]>(url).pipe(catchError(this.errorHandler));
  }
  errorHandler(error: HttpErrorResponse) {
    //
    // console.log(error);
    return observableThrowError(error);
  }
}
