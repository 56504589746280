<span [ngSwitch]="id">
  <span class="badge order-preparation" *ngSwitchCase="1">{{'orderStatusValue.'+id | translate}}</span>
  <span class="badge order-pickup " *ngSwitchCase="2">{{'orderStatusValue.'+id | translate}}</span>
  <span class="badge order-pickedup" *ngSwitchCase="3">{{'orderStatusValue.'+id | translate}}</span>
  <span class="badge order-product-preparation " *ngSwitchCase="4">{{'orderStatusValue.'+id | translate}}</span>
  <span class="badge order-prepared" *ngSwitchCase="5">{{'orderStatusValue.'+id | translate}}</span>
  <span class="badge order-in-service" *ngSwitchCase="6">{{'orderStatusValue.'+id | translate}}</span>
  <span class="badge order-incomplete-deliver " *ngSwitchCase="7">{{'orderStatusValue.'+id | translate}}</span>
  <span class="badge order-delivered " *ngSwitchCase="8">{{'orderStatusValue.'+id | translate}}</span>
  <span class="badge order-done" *ngSwitchCase="9">{{'orderStatusValue.'+id | translate}}</span>
  <span class="badge order-canceled " *ngSwitchCase="10">{{'orderStatusValue.'+id | translate}}</span>
  <span class="badge bg-info" *ngSwitchDefault></span>
</span>
