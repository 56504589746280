<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'menuitem.sms-template' | translate}}</h4>

  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>


<div class="modal-body">


  <!-- Listeleme -->
  <div class="card">
    <div class="card-body">
      <p-table #dt [columns]="cols" [value]="dataList" [responsive]="true" [paginator]="false" [rows]="10" [resizableColumns]="false" [reorderableColumns]="true" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[5,10,15,25,50,100]" currentPageReportTemplate="{first} - {last} of {totalRecords}">
        <!-- caption   -->
        <ng-template pTemplate="caption">
          <div class="row">
            <!-- --şube -->
            <div class="col-sm-12 col-md-6">
              <div class="col">
                <label class="custom-form-label" for="storeSelect">{{'store.storeName' | translate}}</label>
                <select name="storeSelect" [(ngModel)]="selectedStoreId" class="form-control" (ngModelChange)="getData()">
                  <option [ngValue]="0" *ngIf="hasMultipleStore">
                    {{'store.allStores' | translate}}
                  </option>
                  <option *ngFor="let option of stores" [ngValue]="option.id">
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
              <input class="form-control" type="search" placeholder="{{'hint.search-in-result' | translate}}" (keyup)="dt.filterGlobal($event.target.value, 'contains')" appUppercase />
            </div>
          </div>
        </ng-template>

        <!-- header -->
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn pReorderableColumn>
              <app-table-headers-generic [col]="col">
              </app-table-headers-generic>
            </th>
            <th style="width: 12rem;">{{'table-header.process' | translate}}</th>
          </tr>
        </ng-template>

        <!-- body -->
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
          <tr [pSelectableRow]="rowData">
            <hr class="d-sm-none solid">
            <td *ngFor="let col of columns">
              <app-table-columns-generic [data]="rowData[col.field]" [format]="col"> </app-table-columns-generic>
            </td>
            <td>
              <button type="button" class="btn btn-primary btn-table" (click)="selectRecord(rowData)">
                {{'button.select' | translate}}
              </button>

              <button *ngxPermissionsOnly="[roleCodeEnum.SMS_TEMPLATE_UPDATE]" class="btn btn-warning btn-table" (click)="openAddUpdateModal(rowData)">
                <i class="fa fa-edit" aria-hidden="true"></i>
              </button>
              <button *ngxPermissionsOnly="[roleCodeEnum.SMS_TEMPLATE_DELETE]" type="button" class="btn btn-outline-danger btn-table" (click)="delete(rowData)">
                <i class="fa fa-remove" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<div class="modal-footer">

  <button type="button" class="btn btn-secondary" (click)="close()">
    {{'button.close' | translate}}
  </button>

  <button *ngxPermissionsOnly="[roleCodeEnum.SMS_TEMPLATE_CREATE]" class="btn btn-primary m-1 pull-right" (click)="openAddUpdateModal()">
    <i class="fa fa-plus"></i>
    {{'button.add-new' | translate}}
  </button>
</div>