import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { YesNoModalComponent } from 'app/core/yes-no-modal/yes-no-modal.component';
import { ModalSizeEnum } from 'app/enum/modal-size.enum';
import { YesNoModalTypeEnum } from 'app/enum/type/yes_no_modal_type.enum';
import { InputSelectComponent } from 'app/pages/core/input-select/input-select.component';
import { PrinterSettingModalComponent } from 'app/pages/device/printer-setting-modal/printer-setting-modal.component';
import { OrderDeliveryDateSelectModalComponent } from 'app/pages/order/order-delivery-date-select-modal/order-delivery-date-select-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalUtilsService {

  constructor(private modalService: NgbModal,
    private translate: TranslateService) { }

  async openModal(title: string, message: string): Promise<boolean> {
    return new Promise((resolve) => {
      const modalRef = this.modalService.open(YesNoModalComponent, {
        backdrop: 'static', centered: true, scrollable: true, size: ModalSizeEnum.MEDIUM, keyboard: true
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.message = message;

      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        modalRef.close();
        resolve(receivedEntry && receivedEntry.result);
      });
    });
  }

  async openModalForDelete(): Promise<boolean> {
    return new Promise((resolve) => {
      const modalRef = this.modalService.open(YesNoModalComponent, {
        backdrop: 'static', centered: true, scrollable: true, size: ModalSizeEnum.MEDIUM, keyboard: true
      });
      modalRef.componentInstance.modalType = YesNoModalTypeEnum.DANGER;
      modalRef.componentInstance.title = this.translate.instant('title.confirmation');
      modalRef.componentInstance.message = this.translate.instant('confirmation.the-selected-record-will-be-deleted-do-you-confirm');

      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        modalRef.close();
        resolve(receivedEntry && receivedEntry.result);
      });
    });
  }

  async openModalForConfirmation(title: string, message: string): Promise<boolean> {
    return new Promise((resolve) => {
      const modalRef = this.modalService.open(YesNoModalComponent, {
        backdrop: 'static', centered: true, scrollable: true, size: ModalSizeEnum.MEDIUM, keyboard: true
      });
      modalRef.componentInstance.modalType = YesNoModalTypeEnum.PRIMARY;
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.message = message;

      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        modalRef.close();
        resolve(receivedEntry && receivedEntry.result);
      });
    });
  }

  async openModalForWarning(title: string, message: string): Promise<boolean> {
    return new Promise((resolve) => {
      const modalRef = this.modalService.open(YesNoModalComponent, {
        backdrop: 'static', centered: true, scrollable: true, size: ModalSizeEnum.MEDIUM, keyboard: true
      });
      modalRef.componentInstance.modalType = YesNoModalTypeEnum.WARNING;
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.message = message;

      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        modalRef.close();
        resolve(receivedEntry && receivedEntry.result);
      });
    });
  }



  async openSelectModal(dataList: any[], options?: SelectModalOptions): Promise<any> {


    return new Promise((resolve) => {
      const modalRef = this.modalService.open(InputSelectComponent, {
        backdrop: 'static', centered: true, scrollable: true,
        size: options.size ?? ModalSizeEnum.MEDIUM,
        keyboard: true
      });
      options.isDataObject ??= true;

      modalRef.componentInstance.title = options.title;
      modalRef.componentInstance.dataList = dataList;

      if (options.isDataObject) {
        if (options.displayField != undefined)
          modalRef.componentInstance.displayFields = [options.displayField];
        else if (options.displayFields != undefined)
          modalRef.componentInstance.displayFields = options.displayFields;
        else
          modalRef.componentInstance.displayFields = ["name"];

      }

      modalRef.componentInstance.initialItem = options.initialItem;
      modalRef.componentInstance.isDataObject = options.isDataObject;


      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        modalRef.close();
        resolve(receivedEntry);
      });
    });
  }

  async openOrderDeliveryDateModal(isPickup: boolean, isDelivery: boolean, selectedDate: Date): Promise<any> {
    return new Promise((resolve) => {
      const modalRef = this.modalService.open(OrderDeliveryDateSelectModalComponent, {
        backdrop: 'static', centered: true, scrollable: true, size: ModalSizeEnum.LARGE, keyboard: true
      });
      modalRef.componentInstance.selectedDate = selectedDate;
      modalRef.componentInstance.isPickup = isPickup;
      modalRef.componentInstance.isDelviery = isDelivery;

      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        modalRef.close();
        resolve(receivedEntry);
      });
    });
  }

  async openPrinterSettingModal(): Promise<boolean> {
    return new Promise((resolve) => {
      const modalRef = this.modalService.open(PrinterSettingModalComponent, {
        backdrop: 'static', centered: true, scrollable: false, size: ModalSizeEnum.MEDIUM, keyboard: true
      });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        modalRef.close();
        resolve(receivedEntry);
      });
    });
  }



}


interface SelectModalOptions {
  title?: string;
  displayFields?: string[];
  displayField?: string;
  initialItem?: any;
  size?: string;
  isDataObject?: boolean;
}
