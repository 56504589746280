
export enum OnlineCollectionStatusEnum {
  PREPARED_REQUEST = "PREPARED_REQUEST",
  PREPARED_REQUEST_ERROR = "PREPARED_REQUEST_ERROR",
  PENDING = "PENDING",
  CALLBACK_RECEIVED = "CALLBACK_RECEIVED",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
  PAYMENT_FAILURE = "PAYMENT_FAILURE",
  PAYMENT_PROCESSED = "PAYMENT_PROCESSED",
  PAYMENT_PROCESSED_MANUALLY = "PAYMENT_PROCESSED_MANUALLY",
  FAILURE = "FAILURE",

}
export const OnlineCollectionStatusEnumValues = [
  OnlineCollectionStatusEnum.PREPARED_REQUEST,
  OnlineCollectionStatusEnum.PREPARED_REQUEST_ERROR,
  OnlineCollectionStatusEnum.PENDING,
  OnlineCollectionStatusEnum.PAYMENT_SUCCESS,
  OnlineCollectionStatusEnum.PAYMENT_FAILURE,
  OnlineCollectionStatusEnum.PAYMENT_PROCESSED,
  OnlineCollectionStatusEnum.PAYMENT_PROCESSED_MANUALLY,
  OnlineCollectionStatusEnum.FAILURE
]
