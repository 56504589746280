export { };

declare global {
  interface Number {
    toBool: () => boolean;
    isNotGreaterThenZero: () => boolean;
    random: (min: number, max: number) => number;
    round: (decimals: number) => number;
    thousandsSeperator(): String;
  }

}

Number.prototype.toBool = function (): boolean {
  if (this === 1) {
    return false;
  }
  return true;
};

Number.prototype.isNotGreaterThenZero = function (): boolean {
  if (this > 0) {
    return false;
  }
  return true;
};


Number.prototype.round = function (decimals) {
  return Number(this.toFixed(decimals));
};


(<any>Number).random = function (min: number, max: number): number {
  if (isNaN(min) || isNaN(max)) {
    throw "Error: Only numbers are accepted as arguments.";
  }
  //There are issues with base 8 versus base 10 in some instances, so force it to use base 10.
  return Math.floor(
    Math.random() *
    (parseInt(max.toString(), 10) - parseInt(min.toString(), 10) + 1) +
    parseInt(min.toString(), 10)
  );
};

Number.prototype.thousandsSeperator = function (): string {
  return Number(this).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
