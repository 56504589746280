<div *ngIf="customer">
  <div class="card">
    <div class="card-header">

      <h4 data-private class="card-title text-center" *ngIf="customer.customerName">
        {{ customer.isIndividual?customer.customerName:(customer.customerName+' - '+customer.firstName+' '+customer.lastName)}}
      </h4>

    </div>
    <div class="card-body">

      <div class="row" *ngIf="customer.isBlackList">
        <span style="color:red;font-weight:bold">
          {{'message.warning.customer-is-blacklisted' | translate}}
        </span>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" *ngIf="totalDebt">
          <small class="info-label">{{'customer.totalDebt' | translate}}</small>
          <app-customer-total-debt-balance-badge [balance]="totalDebt" [showLabel]="false"></app-customer-total-debt-balance-badge>
        </div>


        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" *ngIf="bonusBalance">
          <small class="info-label">{{'customer.bonusBalance' | translate}}</small>
          <app-bonus-balance-badge [balance]="bonusBalance" [showLabel]="false"></app-bonus-balance-badge>
        </div>


        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" *ngIf="currentAccountBalance">
          <small class="info-label">{{'customer.currentAccountBalance' | translate}}</small>
          <app-current-account-balance-badge [balance]="currentAccountBalance" [showLabel]="false"></app-current-account-balance-badge>
        </div>


        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" *ngIf="customerTypeName">
          <small class="info-label">{{'customer.customerType' | translate}}</small>
          <div class="info-content"> {{ customerTypeName }}</div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" *ngIf="communityName">
          <small class="info-label">{{'customer.community' | translate}}</small>
          <div class="info-content"> {{ communityName }}</div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" *ngIf="customer.cardNumber">
          <small class="info-label">{{'customer.cardNumber' | translate}}</small>
          <div class="info-content"> {{ customer.cardNumber | cardNumberFormat }}</div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
          <small class="info-label">{{'common.discount' | translate}}</small>
          <div class="info-content">% {{ customer.discount }}</div>
        </div>

        <div data-private class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" *ngIf="customer.mobilePhone">
          <small class="info-label">{{ 'communication.mobilePhone' | translate }}</small>
          <div class="d-flex align-items-center">
            <div class="info-content"> {{ customer.mobilePhone | phoneDisplayFormat}}</div>
            <app-phone-call-option-widget [phoneNumber]="customer.mobilePhone" [customerId]="customer.id"></app-phone-call-option-widget>
          </div>
        </div>

        <div data-private class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" *ngIf="customer.pstnPhone1">
          <small class="info-label">{{'communication.pstnPhone1' | translate}}</small>
          <div class="d-flex align-items-center">
            <div class="info-content"> {{ customer.pstnPhone1 | phoneDisplayFormat}}</div>
            <app-phone-call-option-widget [phoneNumber]="customer.pstnPhone1" [customerId]="customer.id"></app-phone-call-option-widget>
          </div>
        </div>
        <div data-private class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" *ngIf="customer.pstnPhone2">
          <small class="info-label">{{'communication.pstnPhone2' | translate}}</small>
          <div class="d-flex align-items-center">
            <div class="info-content"> {{ customer.pstnPhone2 | phoneDisplayFormat}}</div>
            <app-phone-call-option-widget [phoneNumber]="customer.pstnPhone2" [customerId]="customer.id"></app-phone-call-option-widget>
          </div>
        </div>
        <div data-private class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" *ngIf="customer.nationalPhone">
          <small class="info-label">{{'communication.nationalPhone' | translate}}</small>
          <div class="d-flex align-items-center">
            <div class="info-content"> {{ customer.nationalPhone | phoneDisplayFormat}}</div>
          </div>
        </div>
        <div data-private class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" *ngIf="address?.siteName">
          <small class="info-label">{{'address.siteName' | translate}}</small>
          <div class="info-content"> {{ address.siteName }}</div>
        </div>

        <div data-private class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" *ngIf="address?.buildingName">
          <small class="info-label">{{'address.buildingName' | translate}}</small>
          <div class="info-content"> {{ address.buildingName }}</div>
        </div>

        <div data-private class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" *ngIf="address?.doorNum">
          <small class="info-label">{{'address.doorNum' | translate}}</small>
          <div class="info-content"> {{ address.doorNum }}</div>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" *ngIf="address?.districtName">
          <small class="info-label">{{'address.district' | translate}}</small>
          <div class="info-content"> {{ address.districtName }}</div>
        </div>

        <div data-private class="col-sm-12 col-md-12" *ngIf="address?.description">
          <div>
            <small class="info-label">{{'address.description' | translate}}</small>
            <app-address-maps-widget [address]="address" class="pull-right"></app-address-maps-widget>
          </div>
          <div class="info-content"> {{ address.description }}</div>
        </div>


      </div>

      <div class="row">
        <div class="col-sm-12" *ngIf="customer.notes">
          <small class="info-label">{{'common.notes' | translate}}</small>
          <div class="info-label-danger"> {{ customer.notes }}</div>
        </div>
        <div class="col-sm-12" *ngIf="customer.orderInstruction">
          <small class="info-label">{{'customer.orderInstruction' | translate}}</small>
          <div class="info-label-danger"> {{ customer.orderInstruction }} </div>

        </div>
      </div>
      <button class="btn btn-primary" type="button" (click)="goParentCustomer()" *ngIf="showParentCustomer && customer.parentCustomerId>0">
        {{'button.go-parent-customer' | translate}}
      </button>
    </div>
  </div>

  <div class="card" *ngIf="showBillingDetail&&customer.isBilling">
    <app-customer-billing-info-widget [customer]="customer"></app-customer-billing-info-widget>
  </div>
</div>