import { Pipe, PipeTransform } from '@angular/core';
import { DynamicTableColumnModel } from 'app/models/dto/core/dynamicTableColumn.model';

@Pipe({
  name: 'filterColSelected'
})
export class FilterColSelectedPipe implements PipeTransform {

  transform(list: DynamicTableColumnModel[]): any {
    return list ? list.filter(item => item.isSelected || item.isSelected == null) : [];
  }
}
