import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { YesNoModalComponent } from 'app/core/yes-no-modal/yes-no-modal.component';
import { ModalSizeEnum } from 'app/enum/modal-size.enum';
import { RoleCodeEnum } from 'app/enum/role-code.enum';
import { SmsMessageTypeEnumForCustomerValues, SmsMessageTypeEnumValues, SmsMessageTypeEnum } from 'app/enum/sms/sms-message-type.enum';
import { AllowedStoreTypeEnum } from 'app/enum/type/allowed-store-type.enum';
import { YesNoModalTypeEnum } from 'app/enum/type/yes_no_modal_type.enum';
import { DynamicTableColumnModel } from 'app/models/dto/core/dynamicTableColumn.model';
import { SmsTemplateEntity } from 'app/models/entity/sms/sms-template-entity';
import { AuthService } from 'app/services/auth.service';
import { AlertifyService } from 'app/services/core/alertify.service';
import { SmsTemplateService } from 'app/services/sms/sms-template.service';
import { StoreService } from 'app/services/store/store.service';
import { clone } from 'lodash';
import { SmsTemplateAddUpdateComponent } from '../sms-template-add-update/sms-template-add-update.component';
import { StoreCacheService } from 'app/services/cache/store-cache.service';

@Component({
  selector: 'app-sms-template-select-modal',
  templateUrl: './sms-template-select-modal.component.html',
  styleUrls: ['./sms-template-select-modal.component.css']
})
export class SmsTemplateSelectModalComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  dataList: SmsTemplateEntity[];
  cols: DynamicTableColumnModel[];
  data: SmsTemplateEntity = new SmsTemplateEntity();

  isNewData: boolean;
  loading: boolean;
  roleCodeEnum = RoleCodeEnum;

  //arayazüden seçilen mağaza id si
  selectedStoreId: number = 0;
  hasMultipleStore?: boolean = false;
  stores: any[];

  constructor(
    private storeCacheService: StoreCacheService,
    private authService: AuthService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private smsTemplateService: SmsTemplateService,
    private alertifyService: AlertifyService
  ) { }
  async ngOnInit() {
    this.getCols();
    this.selectedStoreId = this.authService.getStoreId();
    await this.getStores();
    this.getData();
  }


  async getStores() {
    try {

      const res = await this.storeCacheService.getStoreSelectList(this.authService.getStoreId(), AllowedStoreTypeEnum.ALLOWED_MANAGE_STORE).toPromise();
      this.stores = res;
      this.hasMultipleStore = this.stores.length > 1;
    }
    catch (err) {

      this.alertifyService.error(err);
    }
  }

  // listeler
  getData() {
    this.loading = true;
    this.storeCacheService.getSmsTemplates(this.selectedStoreId).subscribe(
      (res) => {
        this.dataList = res;

        this.dataList = this.dataList.filter(x => SmsMessageTypeEnumForCustomerValues.firstOrDefault(y => y == x.usageFor) != null)
        this.dataList = this.dataList.sortByValues("usageFor", SmsMessageTypeEnumValues);
        this.loading = false;
      },
      (err) => {
        this.alertifyService.error(err);
        this.loading = false;
      }
    );
  }
  // Modal ekleme güncelleme
  openAddUpdateModal(rowData) {

    const modalRef = this.modalService.open(SmsTemplateAddUpdateComponent, {
      backdrop: "static", centered: true, scrollable: true, size: ModalSizeEnum.LARGE
    });

    // eğer güncelleme ise bu değer dolu geliyor
    if (rowData) {
      this.isNewData = false;
      this.data = rowData;
    }
    else {
      this.isNewData = true;
      this.data = new SmsTemplateEntity();
      this.data.storeId = this.selectedStoreId;
      this.data.usageFor = SmsMessageTypeEnum.CUSTOM;
    }

    //modal  veri geçişi
    modalRef.componentInstance.data = clone(this.data);

    //modal cevap döndüğünde
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      // Cevap boş değilse
      if (receivedEntry) {

        if (this.isNewData)
          this.dataList = this.dataList.concat(receivedEntry);
        else {
          this.dataList.replace(this.data, receivedEntry);
          this.dataList = this.dataList.slice();
        }
        this.data = receivedEntry;
      }

      // modal kapat
      modalRef.close();
    });

  }
  //sil
  delete(rowData) {

    const modalRef = this.modalService.open(YesNoModalComponent, {
      backdrop: "static", centered: true, scrollable: true, size: ModalSizeEnum.MEDIUM, keyboard: true
    });
    modalRef.componentInstance.modalType = YesNoModalTypeEnum.DANGER;
    modalRef.componentInstance.title = this.translate.instant('title.confirmation');
    modalRef.componentInstance.message = this.translate.instant('confirmation.the-selected-record-will-be-deleted-do-you-confirm');
    //modal cevap döndüğünde
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {

      // modal kapat
      modalRef.close();

      // Cevap boş değilse
      if (receivedEntry) {
        this.smsTemplateService
          .deleteSmsTemplate(rowData.id)
          .subscribe(
            (res) => {
              this.dataList.remove(rowData);
              this.dataList = this.dataList.slice();
              this.alertifyService.success(this.translate.instant('message.info.deleted'));
            },
            (err) => {
              this.alertifyService.error(err);
            }
          );
      }
    });

  }
  close() {
    this.passEntry.emit();
  }

  selectRecord(rowData) {
    this.passEntry.emit(rowData);
  }
  //tablo alanları
  private getCols() {
    this.cols = [
      {
        field: "name",
        header: this.translate.instant('sms.smsTemplateName'),
        isSelected: true
      },
      {
        field: "message",
        header: this.translate.instant('sms.message'),
        dataType: "truncate",
        maxDisplayLength: 60,
        isSelected: true
      }
    ];
  }

}
