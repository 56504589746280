import { Component, Input, OnInit } from '@angular/core';
import { OnlineCollectionStatusEnum } from 'app/enum/online-collection/online-collection-status.enum';

@Component({
  selector: 'app-online-collection-status-badge',
  templateUrl: './online-collection-status-badge.component.html',
  styleUrls: ['./online-collection-status-badge.component.css']
})
export class OnlineCollectionStatusBadgeComponent implements OnInit {
  @Input() value: string;

  onlineCollectionStatusEnum = OnlineCollectionStatusEnum;

  constructor() { }

  ngOnInit(): void {
  }

}
