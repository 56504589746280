import { HostListener, Pipe } from '@angular/core';

@Pipe({
  name: 'numberOnly'
})
export class NumberOnlyPipe {

  @HostListener('keydown', ['$event'])
  keyDownEvent(event: KeyboardEvent) {
    if (event.key.length === 1 && (event.which < 48 || event.which > 57)) {
      event.preventDefault();
    }
  }

}
