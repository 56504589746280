import { Injectable } from '@angular/core';
import { PrinterService } from './printer.service';
import { ExecuetAppRequest } from 'app/models/rest/app/execute-app-request';
import { AlertifyService } from './alertify.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private printerService: PrinterService,
    private authService: AuthService,
    private alertifyService: AlertifyService) { }

  isWindows() {
    const platform = navigator.platform.toLowerCase();

    return platform.includes('win');
  }

  isMac() {
    const platform = navigator.platform.toLowerCase();

    return platform.includes('mac');
  }


  downloadAnyDesk() {

    let downloadUrl: string;

    if (this.isWindows()) {
      downloadUrl = 'https://anydesk.com/tr/downloads/thank-you?dv=win_exe'; // Windows exe linki
    } else if (this.isMac()) {
      downloadUrl = 'https://anydesk.com/tr/downloads/thank-you?dv=mac_dmg'; // Mac dmg linki
    } else {
      alert('Desteklenmeyen işletim sistemi!');
      return;
    }
    window.open(downloadUrl, '_blank');
  }

  async downloadSupportApp() {

    if (this.isWindows()) {
      let hasVersion: boolean = false;

      try {
        //versiyon bilgisi servisi çalışıyor ise yeni yapıdır
        const res = await this.printerService.getAppVersion().toPromise();
        hasVersion = true;
      }
      catch (err) {
        console.log(err);
      }

      if (hasVersion) {
        let request = new ExecuetAppRequest();
        request.exeName = "AlpemixCMX.exe";
        request.downloadUrl = "https://www.alpemix.com/site/AlpemixCMX.exe";
        request.parameters = ["ozhankucuk", "aktif", "aktif123"];
        if (this.authService.isLoggedIn) {
          let screenName = this.authService.getCurrentUserScreenName().toString();
          //18 karaktere izin veriyor.
          if (screenName.length > 18) {
            screenName = screenName.substring(0, 18);
          }
          request.parameters.push(screenName);
        }
        request.forceDownload = true;
        request.execute = true;
        try {
          const res = await this.printerService.executeApp(request).toPromise();

          if (res.isSuccess) {
            this.alertifyService.success("Destek programı başlatıldı");
          }
          else {
            this.alertifyService.error(res.message);
          }
          return;
        }
        catch (err) {
          this.alertifyService.error(err);
        }
      }

      window.open("https://www.alpemix.com/site/AlpemixCMX.exe", '_blank');
    }
    else {

      window.open("https://alpemix.com/tr/macosx-dosyalari", '_blank');
    }




  }

  downloadJava() {
    window.open("https://aktifmusteri.com/download/setup/JavaSetup8.exe", '_blank');
  }

  downloadAktifMusteri() {
    window.open("https://aktifmusteri.com/download/setup/aktifmusteri.exe", '_blank');
  }
}
