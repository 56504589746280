import { Component, OnInit } from '@angular/core';
import { DateFormatEnum } from 'app/enum/date-format.enum';

@Component({
  selector: 'app-clock-widget',
  templateUrl: './clock-widget.component.html',
  styleUrls: ['./clock-widget.component.css']
})
export class ClockWidgetComponent implements OnInit {
  currentDateTime: Date;
  dateFormatEnum = DateFormatEnum;

  ngOnInit() {
    this.updateTime();
    setInterval(() => {
      this.updateTime();
    }, 10000); // Her saniye güncellenir
  }

  updateTime() {
    this.currentDateTime = new Date();
  }

}
