import { Injectable } from '@angular/core';
import { isNullOrEmpty } from 'app/helper/utils';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor(
    public authService: AuthService
  ) {


  }


  getPhoneNumberWithCountryCode(phone: string) {
    if (isNullOrEmpty(phone)) return null;

    if (phone!.startsWith("+") && phone!.length > 11) return phone;

    let countryPhoneCode = this.authService.getCountryPhoneCode();
    let returnValue = phone;

    if (!isNullOrEmpty(countryPhoneCode)) {
      if (countryPhoneCode == "994" && phone.length >= 10) {
        returnValue = "${countryPhoneCode!}0${phone.substring(phone.length - 9)}";
      } else {
        returnValue = countryPhoneCode! + phone;
      }
    }

    return returnValue;
  }

  openWhatsApp(phoneNumber: string, message: string, addCountryCode: boolean = true) {

    if (addCountryCode)
      phoneNumber = this.getPhoneNumberWithCountryCode(phoneNumber);
    // Mesajı URL uyumlu hale getir
    const encodedMessage = encodeURIComponent(message);
    // WhatsApp URL şablonu, mesaj ile birlikte
    const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(url, '_blank');
  }

}
