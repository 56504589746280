import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { throwError as observableThrowError, Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { DistrictSearchDto } from "../../models/dto/districtSearchDto";
import { City } from "../../models/entity/city";
import { SubProvince } from "../../models/entity/sub-province";
import { District } from "../../models/entity/district";
import { Unit } from "../../models/entity/unit";
import { RequestCacheService } from "./request-cache.service";
import { RoleCode } from "../../models/entity/roleCode";
import { RoleGroup } from "../../models/entity/roleGroup";
import { SmsVendor } from "../../models/entity/smsVendor";
import { Country } from "../../models/entity/country";
import { Owner } from "../../models/entity/owner";
import { BaseRestService } from "../base-rest.service";
import { SessionStorageService } from "../session-storage.service";

@Injectable({
  providedIn: "root",
})
export class CommonService extends BaseRestService {
  constructor(protected httpClient: HttpClient, protected sessionStorageService: SessionStorageService, private cache: RequestCacheService) {
    super(httpClient, sessionStorageService);
  }


  //Şehrin semtleri
  // getCities(id): Observable<City[]> {
  //   const url = `/commons/countries/${id}/cities`;
  //   return this.http.get<City[]>(url).pipe(catchError(this.errorHandler));
  // }

  public getCountries(): Observable<Country[]> {

    const url = `/commons/countries`;
    return this.httpGet<Country[]>(url, true).pipe(catchError(this.errorHandler));
  }


  public getSampleOwners(): Observable<Owner[]> {
    const url = `/commons/sample-owners`;
    return this.httpGet<Owner[]>(url, true).pipe(catchError(this.errorHandler));
  }

  public getCities(id): Observable<City[]> {
    const url = `/commons/countries/${id}/cities`;
    return this.httpGet<City[]>(url, true).pipe(catchError(this.errorHandler));
  }

  public getSubProvinces(id): Observable<SubProvince[]> {
    const url = `/commons/cities/${id}/sub-provinces`;
    return this.httpGet<SubProvince[]>(url, true).pipe(catchError(this.errorHandler));
  }

  public getDistricts(id): Observable<District[]> {
    const url = `/commons/sub-provinces/${id}/districts`;

    return this.httpGet<District[]>(url, true).pipe(catchError(this.errorHandler));
  }

  //Şehrin semtleri
  // getDistrictsByCity(id): Observable<DistrictSearchDto[]> {
  //   const url = `/commons/cities/${id}/districts`;
  //   return this.http
  //     .get<DistrictSearchDto[]>(url)
  //     .pipe(catchError(this.errorHandler));
  // }

  public getDistrictsByCity(id): Observable<DistrictSearchDto[]> {
    const url = `/commons/cities/${id}/districts`;

    return this.httpGet<DistrictSearchDto[]>(url, true).pipe(catchError(this.errorHandler));
  }

  public getDistrictsByPostCode(id, postCode): Observable<DistrictSearchDto> {
    const url = `/commons/countries/${id}/post-code=${postCode}`;
    return this.httpGet<DistrictSearchDto>(url);
  }


  //Birim
  getUnits(): Observable<Unit[]> {
    const url = `/commons/units`;
    return this.httpGet<Unit[]>(url).pipe(catchError(this.errorHandler));
  }


  // Diller
  // getLanguages(): Observable<Language[]> {
  //   const url = `/commons/languages`;
  //   return this.httpGet<Language[]>(url);
  // }


  getRoleCodes(): Observable<RoleCode[]> {
    const url = `/commons/role-codes`;
    return this.httpGet<RoleCode[]>(url).pipe(catchError(this.errorHandler));
  }

  getRoleGroups(): Observable<RoleGroup[]> {
    const url = `/commons/role-codes`;
    return this.httpGet<RoleGroup[]>(url).pipe(catchError(this.errorHandler));
  }

  // Sms Firmaları
  public getSmsVendors(): Observable<SmsVendor[]> {
    const url = `/commons/sms-vendors`;
    return this.httpGet<SmsVendor[]>(url).pipe(catchError(this.errorHandler));
  }



  importDistrict(data) {
    const url = `/commons/import-district`;
    return this.httpPost(url, data).pipe(catchError(this.errorHandler));
  }

  // public getAppCultureInfos(): Observable<AppCultureInfoModel[]> {
  //   const url = `/commons/app-culture-infos`;
  //   return this.httpGet<AppCultureInfoModel[]>(url).pipe(catchError(this.errorHandler));
  // }

  errorHandler(error: HttpErrorResponse) {
    if (error.error.message) return observableThrowError(error);
    else return observableThrowError(error.error);
  }
}
