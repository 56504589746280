import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalSizeEnum } from 'app/enum/modal-size.enum';
import { isNullOrEmpty } from 'app/helper/utils';
import { VoipInternalModel } from 'app/models/entity/voip-internal.model';
import { DeviceLocalStorageData } from 'app/models/rest/user/device-local-storage-data.model';
import { DeviceVoipInternalResponse } from 'app/models/rest/voip/device-voip-internal-response.model';
import { VoipCallStartRequest } from 'app/models/rest/voip/voip-call-start-request';
import { AlertifyService } from 'app/services/core/alertify.service';
import { ModalUtilsService } from 'app/services/core/modal-utils.service';

import { SessionStorageService } from 'app/services/session-storage.service';
import { DeviceService } from 'app/services/store/device.service';
import { VoipInternalService } from 'app/services/voip/voip-internal.service';

@Component({
  selector: 'app-voip-call-start-widget',
  templateUrl: './voip-call-start-widget.component.html',
  styleUrls: ['./voip-call-start-widget.component.css']
})
export class VoipCallStartWidgetComponent implements OnInit {

  @Input() customerId: number;
  @Input() phoneNumber: string;
  deviceLocalStorageData: DeviceLocalStorageData;
  voipInternal: VoipInternalModel;

  constructor(
    private translate: TranslateService,
    private alertifyService: AlertifyService,
    private voipInternalService: VoipInternalService,
    private sessionStorageService: SessionStorageService,
    private modalUtilsService: ModalUtilsService) { }

  ngOnInit(): void {
    this.deviceLocalStorageData = this.sessionStorageService.getDeviceStorageData();
  }
  validateSave() {
    if (isNullOrEmpty(this.phoneNumber)) {
      this.alertifyService.warning(this.translate.instant("message.error.phone-number-cannot-be-blank"))
      return false;
    }

    if (this.voipInternal == null) {
      this.alertifyService.error(this.translate.instant('message.warning.voip-internal-or-internal-no-can-not-be-empty'));

      return false;
    }

    if (isNullOrEmpty(this.voipInternal.internalNoForStartingCall)) {
      this.alertifyService.error(this.translate.instant('message.warning.voip-internal-or-internal-no-can-not-be-empty'));

      return false;
    }
    return true;
  }

  async startVoipCall() {
    try {

      if (this.voipInternal == null)
        await this.getCurrentDeviceVoipInternal();

      if (!this.validateSave()) return;

      let internalNo = this.voipInternal.internalNoForStartingCall;
      if (internalNo.includes(",")) {
        let items = internalNo.split(",");
        internalNo = await this.modalUtilsService.openSelectModal(
          items,
          {
            title: this.translate.instant('dropdown.select-record'),
            size: ModalSizeEnum.MEDIUM,
            isDataObject: false
          });

      }


      if (isNullOrEmpty(internalNo))
        return;

      const result = await this.modalUtilsService.openModal(this.translate.instant('title.confirmation'), this.translate.instant("confirmation.starting-call-on-voip"));
      if (!result)
        return;


      let request = new VoipCallStartRequest();
      request.customerId = this.customerId;
      request.voipInternalId = this.deviceLocalStorageData.voipInternalId;
      request.internalNo = internalNo;
      request.phoneNumber = this.phoneNumber;

      const res = await this.voipInternalService.startCall(request).toPromise();
      this.alertifyService.success(this.translate.instant("message.info.process-is-done"));
    } catch (err) {
      this.alertifyService.error(err);
    }

  }

  async getCurrentDeviceVoipInternal() {
    try {
      const res = await this.voipInternalService.getVoipInternalById(this.deviceLocalStorageData.voipInternalId).toPromise();
      this.voipInternal = res;
    } catch (err) {
      this.alertifyService.error(err);
    }

  }
}
