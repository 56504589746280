import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { FullComponent } from "./layouts/full/full.component";
import { BlankComponent } from "./layouts/blank/blank.component";
import { AppPrivacyPolicyComponent } from "./app-privacy-policy/app-privacy-policy.component";

export const routes: Routes = [
  {
    path: "",
    component: FullComponent,
    children: [
      { path: "", redirectTo: "/authentication/login", pathMatch: "full", },
      // {
      //   path: "starter",
      //   canActivate: [AuthGuard],
      //   loadChildren: () =>
      //     import("./starter/starter.module").then((m) => m.StarterModule),
      // },
      // {
      //   path: "component",
      //   loadChildren: () =>
      //     import("./component/component.module").then(
      //       (m) => m.ComponentsModule
      //     ),
      //   canActivate: [AuthGuard],
      // },
      // {
      //   path: "",
      //   canActivate: [AuthGuard],
      //   loadChildren: () =>
      //     import("./pages/pages.module").then((m) => m.PagesModule),
      // },
    ],
  },

  // {
  //   path: "apps",
  //   loadChildren: () => import("./apps/apps.module").then((m) => m.AppsModule),
  // },

  {
    path: "",
    component: FullComponent,
    children: [
      {
        path: "pages",
        loadChildren: () =>
          import("./pages/pages.module").then((m) => m.PagesModule),
      },
    ],
  },
  {
    path: "",
    component: BlankComponent,
    children: [
      {
        path: "authentication",
        loadChildren: () =>
          import("./authentication/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: "",
    component: BlankComponent,
    children: [
      {
        path: "customer",
        loadChildren: () =>
          import("./customer/customer.module").then(
            (m) => m.CustomerModule
          ),
      },
    ],
  },

  {
    path: "app-privacy-policy",
    component: AppPrivacyPolicyComponent,
  },
  {
    path: "**",
    redirectTo: "404",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'top'
}),
    NgbModule],
  exports: [RouterModule],
})
export class AppRoutingModule { }

