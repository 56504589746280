import { CallerIdLogCreateResponseModel } from '../../models/rest/voip/caller-id-log-create-response.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable } from "rxjs";
import { catchError } from 'rxjs/operators';
import { BaseRestService } from '../base-rest.service';
import { CallerIdLog } from 'app/models/entity/caller-id-log';
import { CallerIdLogCreateRequestModel } from 'app/models/rest/voip/caller-id-log-create-request.model';
import { SessionStorageService } from "../session-storage.service";

@Injectable({
  providedIn: 'root'
})
export class CallerIdLogService extends BaseRestService {
  constructor(protected httpClient: HttpClient, protected sessionStorageService: SessionStorageService) {
    super(httpClient, sessionStorageService);
  }
  // All
  getCallerIdLogs(): Observable<CallerIdLog[]> {
    const url = `/caller-id-logs`;
    return this.httpGet<CallerIdLog[]>(url)
      .pipe(catchError(this.errorHandler));
  }

  //Add
  addCallerIdLog(data) {
    const url = `/caller-id-logs`;
    return this.httpPost<CallerIdLog>(url, data)
      .pipe(catchError(this.errorHandler));
  }

  //Add
  createCallerIdLog(data: CallerIdLogCreateRequestModel) {
    const url = `/caller-id-logs/create`;
    return this.httpPost<CallerIdLogCreateResponseModel>(url, data)
      .pipe(catchError(this.errorHandler));
  }

  //Update
  updateCallerIdLog(id, data): Observable<CallerIdLog> {
    const url = `/caller-id-logs/${id}`;
    return this.httpPut<CallerIdLog>(url, data)
      .pipe(catchError(this.errorHandler));
  }


  //Delete
  deleteCallerIdLog(id) {
    const url = `/caller-id-logs/${id}`;
    return this.httpDelete(url).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    // console.log("service:"+error);
    return observableThrowError(error);
  }
}
