import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { throwError as observableThrowError, Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { User } from "../../models/entity/user";
import { RoleCode } from "../../models/entity/roleCode";
import { UserRoleCodeMatrisResponse } from "../../models/rest/user/userRoleCodeMatrisResponse";
import { MenuItemWithSubDto } from "../../models/dto/menuItemWithSubDto";
import { RequestCacheService } from '../core/request-cache.service';
import { BaseRestService } from '../base-rest.service';
import { UserLoginRequest } from 'app/models/rest/user/userLoginRequest';
import { SelectItemIdDto } from 'app/models/dto/selectItemIdDto';
import { UserActiveNotificationResponse } from 'app/models/rest/user/user-active-notification-response';
import { UserRelationUpdateRequest } from 'app/models/rest/user/user-relation-update-request.model';
import { CommonRequest } from 'app/models/rest/common/commonRequest';
import { UsersForRoleCodeRequest } from 'app/models/rest/user/user-for-role-code-request';
import { UserChangePasswordRequest } from 'app/models/rest/user/user-change-password-request';
import { SessionStorageService } from "../session-storage.service";
import { UserLoginResponse } from 'app/models/rest/user/userLoginResponse';

@Injectable({
  providedIn: "root"
})
export class UserService extends BaseRestService {
  constructor(protected httpClient: HttpClient, protected sessionStorageService: SessionStorageService, private cache: RequestCacheService) {
    super(httpClient, sessionStorageService);
  }

  getUsers(): Observable<User[]> {
    const url = `/users`;
    return this.httpGet<User[]>(url).pipe(catchError(this.errorHandler));
  }

  getUsersBySearch(request: CommonRequest): Observable<User[]> {
    const url = `/users/search`;
    return this.httpPost<User[]>(url, request).pipe(catchError(this.errorHandler));
  }

  getUserById(id): Observable<User> {
    const url = `/users/${id}`;
    return this.httpGet<User>(url).pipe(catchError(this.errorHandler));
  }

  addUser(user): Observable<User> {
    const url = `/users`;
    return this.httpPost<User>(url, user).pipe(
      tap(() => this.cache.clearByKeyPattern("users")),
      catchError(this.errorHandler)
    );
  }

  updateUser(id, user): Observable<User> {
    const url = `/users/${id}`;
    return this.httpPut<User>(url, user).pipe(
      tap(() => this.cache.clearByKeyPattern("users")),
      catchError(this.errorHandler)
    );
  }

  deleteUser(id): Observable<void> {
    const url = `/users/${id}`;
    return this.httpDelete(url).pipe(
      tap(() => this.cache.clearByKeyPattern("users")),
      catchError(this.errorHandler)
    );
  }

  getUserRoleCodeValues(id): Observable<string[]> {
    const url = `/users/${id}/role-code-values`;
    return this.httpGet<string[]>(url).pipe(catchError(this.errorHandler));
  }

  getUserRoleCodes(id): Observable<RoleCode[]> {
    const url = `/users/${id}/role-codes`;
    return this.httpGet<RoleCode[]>(url).pipe(catchError(this.errorHandler));
  }

  getUserRoleCodeMatris(id): Observable<UserRoleCodeMatrisResponse> {
    const url = `/users/${id}/role-code-matris`;
    return this.httpGet<UserRoleCodeMatrisResponse>(url).pipe(catchError(this.errorHandler));
  }

  updateUserRoleCodeMatris(id, userRoleCodeMatris): Observable<UserRoleCodeMatrisResponse> {
    const url = `/users/${id}/role-code-matris`;
    return this.httpPut<UserRoleCodeMatrisResponse>(url, userRoleCodeMatris).pipe(
      tap(() => this.cache.clearByKeyPattern("users")),
      catchError(this.errorHandler)
    );
  }

  sendConfirmLink(id): Observable<void> {
    const url = `/users/${id}/send-confirm-link`;
    return this.httpGet(url).pipe(catchError(this.errorHandler));
  }

  getUsersByRoleCode(request: UsersForRoleCodeRequest): Observable<User[]> {
    const url = `/users/filter-by-role-code`;
    return this.httpPost<User[]>(url, request).pipe(catchError(this.errorHandler));
  }

  checkPassword(username: string, password: string): Observable<void> {
    const url = `/users/check-password`;
    return this.httpPost(url, { username, password }).pipe(catchError(this.errorHandler));
  }

  login(request: UserLoginRequest): Observable<UserLoginResponse> {
    const url = `/auth/login`;
    return this.httpPost<UserLoginResponse>(url, request, true).pipe(catchError(this.errorHandler));
  }

  changePassword(request: UserChangePasswordRequest): Observable<void> {
    const url = `/users/change-password`;
    return this.httpPost(url, request).pipe(catchError(this.errorHandler));
  }

  getUserSessionInfo(): Observable<UserLoginResponse> {
    const url = `/users/session-info`;
    return this.httpGet<UserLoginResponse>(url).pipe(catchError(this.errorHandler));
  }

  getStoreSelectList(userId: number, type: string): Observable<SelectItemIdDto[]> {
    const url = `/users/${userId}/user-relation?type=${type}`;
    return this.httpGet<SelectItemIdDto[]>(url).pipe(catchError(this.errorHandler));
  }

  userRelationUpdate(request: UserRelationUpdateRequest[]): Observable<void> {
    const url = `/users/user-relation-update`;
    return this.httpPost(url, request).pipe(
      tap(() => this.cache.clearByKeyPattern("users")),
      catchError(this.errorHandler)
    );
  }

  setUserDefaultRoles(id): Observable<void> {
    const url = `/users/${id}/reset-user-default-roles`;
    return this.httpGet(url).pipe(
      tap(() => this.cache.clearByKeyPattern("users")),
      catchError(this.errorHandler)
    );
  }

  getActiveNotications(): Observable<UserActiveNotificationResponse[]> {
    const url = `/users/active-notifications`;
    return this.httpGet<UserActiveNotificationResponse[]>(url).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return observableThrowError(error);
  }
}
