import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateFormatEnum } from 'app/enum/date-format.enum';
import { AlertifyService } from 'app/services/core/alertify.service';

@Component({
  selector: 'app-input-date-modal',
  templateUrl: './input-date-modal.component.html',
  styleUrls: ['./input-date-modal.component.css']
})
export class InputDateModalComponent implements OnInit {
  @Input() data: Date;
  @Input() isRequired: boolean = true;
  title: string;
  dateFormatEnum = DateFormatEnum;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  constructor(private alertifyService: AlertifyService,
    private translate: TranslateService) { }

  ngOnInit() { }

  close() {
    this.passEntry.emit();
  }

  save() {
    if (this.isRequired && this.data == null) {
      this.alertifyService.error(this.translate.instant("message.error.cannot-be-blank"));
      return;
    }
    this.passEntry.emit(this.data);
  }
}
