import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-input-select",
  templateUrl: "./input-select.component.html",
  styleUrls: ["./input-select.component.css"]
})
export class InputSelectComponent implements OnInit {
  @Input() dataList: any[];
  @Input() isDataObject: boolean = true;
  @Input() title: string;
  @Input() displayFields: string[]; // Alan adları için dizi
  @Input() initialItem: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
  ) {
  }

  ngOnInit() { }

  getDisplayText(item: any): string {

    if (!this.isDataObject) return item;

    return this.displayFields.map(field => item[field]).join(' ');

  }
  selectItem(item) {
    this.passEntry.emit(item);
  }

  close() {
    this.passEntry.emit();
  }
}
