import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-total-debt-balance-badge',
  templateUrl: './customer-total-debt-balance-badge.component.html',
  styleUrls: ['./customer-total-debt-balance-badge.component.css']
})
export class CustomerTotalDebtBalanceBadgeComponent implements OnInit {
  @Input() balance: number;
  @Input() showLabel: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

}
