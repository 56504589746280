import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchGlobal'
})
export class SearchGlobalPipe implements PipeTransform {

  transform(records: Array<any>, searchText?: string): any {
    if (searchText == null) return records;

    searchText = searchText.replace('İ', 'i').toLowerCase();

    return records.filter(rec => {
      return Object.keys(rec).some(x => String(rec[x]).replace('İ', 'i').toLowerCase().includes(searchText));
    })
  }
}
