import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSizeEnum } from 'app/enum/modal-size.enum';
import { ReminderDelayEnum, ReminderDelayEnumValues } from 'app/enum/common/reminder-delay.enum';
import { UserNotificationTypeEnum } from 'app/enum/notification/user-notification-type.enum';
import { AlertifyService } from 'app/services/core/alertify.service';
import { ReminderService } from 'app/services/reminder/reminder.service';
import { InputDateModalComponent } from '../input-date-modal/input-date-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ReminderDelayRequest } from 'app/models/rest/reminder/reminder-delay-request.model';
import { UserActiveNotificationResponse } from 'app/models/rest/user/user-active-notification-response';
import { Router } from '@angular/router';
import { RouteEnum } from 'app/enum/route.enum';

@Component({
  selector: 'app-reminder-view-modal',
  templateUrl: './reminder-view-modal.component.html',
  styleUrls: ['./reminder-view-modal.component.css']
})
export class ReminderViewModalComponent implements OnInit {

  @Input() data: UserActiveNotificationResponse;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  reminderDelayEnumValues = ReminderDelayEnumValues;
  userNotificationTypeEnum = UserNotificationTypeEnum;

  selectedDelay: ReminderDelayEnum = ReminderDelayEnum.FIFTEEN_MINUTES;
  constructor(private modalService: NgbModal,
    private translate: TranslateService,
    private reminderService: ReminderService,
    private alertifyService: AlertifyService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.passEntry.emit();
  }


  async terminate() {
    try {
      const res = await this.reminderService.terminateById(this.data.key).toPromise();
      this.passEntry.emit(res);
    }
    catch (err) {
      this.alertifyService.error(err);
    }
  }

  delay() {


    if (this.selectedDelay != ReminderDelayEnum.CUSTOM) {
      let newDate = new Date().addMinutes(this.selectedDelay);
      this.delaySave(newDate);
      return;
    }

    const modalRef = this.modalService.open(InputDateModalComponent, {
      backdrop: true, centered: true, scrollable: false, size: ModalSizeEnum.MEDIUM
    });

    //modal  veri geçişi
    modalRef.componentInstance.data = this.data.notificationDate;
    modalRef.componentInstance.title = this.translate.instant("reminder.remindDate")
    //modal cevap döndüğünde
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (receivedEntry) {
        this.delaySave(receivedEntry);

      }

      // modal kapat
      modalRef.close();

    });

  }

  async delaySave(remindDate: Date) {

    try {
      let request = new ReminderDelayRequest();
      request.id = Number(this.data.key);
      request.remindDate = remindDate;

      const res = await this.reminderService.delay(request).toPromise();
      this.passEntry.emit(res);
    }
    catch (err) {
      this.alertifyService.error(err);
    }
  }

  goScreen() {
    if (this.data.type === UserNotificationTypeEnum.ORDER) {
      this.modalService.dismissAll(); // Modalı kapat

      this.router.navigate([RouteEnum.ORDER_DETAIL], {
        queryParams: { customerId: this.data.customerId, orderId: this.data.orderId }, skipLocationChange: false
      });
    }
    else if (this.data.type === UserNotificationTypeEnum.OPERATIONAL_DEMAND) {
      this.modalService.dismissAll(); // Modalı kapat

      this.router.navigate([RouteEnum.OPERATIONAL_DEMANDS], {
        skipLocationChange: false
      });

    }
  }

  goUrl() {
    this.modalService.dismissAll(); // Modalı kapat

    this.navigateToUrl(this.data.redirectUrl);


  }

  navigateToUrl(dynamicUrl: string) {
    // URL'i parse et
    const url = new URL(dynamicUrl, window.location.origin);

    // Path ve query parametrelerini ayrıştır
    const path = url.pathname;
    const queryParams = {};
    url.searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    // Navigasyonu gerçekleştir
    // this.router.navigate([path], { queryParams: queryParams, skipLocationChange: false });


    // Navigasyonu gerçekleştir ve mevcut rota parametrelerini güncelle
    this.router.navigate([path], { queryParams: queryParams }).then(() => {
      // Parametreler güncellendiğinde yapılacak işlemler
      window.location.reload(); // Mevcut bileşeni yeniden yüklemek için
    });

  }
  getGoScreenButtonTitle() {
    if (this.data.type == UserNotificationTypeEnum.OPERATIONAL_DEMAND)
      return this.translate.instant('menuitem.operational-demands');
    else if (this.data.type == UserNotificationTypeEnum.ORDER)
      return this.translate.instant('button.go-order');
    else "GO";
  }

}
