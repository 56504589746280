<router-outlet>
  <app-spinner></app-spinner>
</router-outlet>

<ng-template #childModal let-modal>
  <div class="modal-header">
    <h4 id="dialog-child-name" class="modal-title pull-left">{{'title.you-have-been-idle' | translate}}</h4>
    <button type="button" class="btn-close pull-right" aria-label="Close" (click)="hideChildModal()">

    </button>
  </div>
  <div class="modal-body">
    {{idleState}}
  </div>
  <div class="modal-footer">
    <button type="button" (click)="logout()" class="btn btn-danger">{{'authentication.logout' | translate}}</button>
    <button type="button" (click)="stay()" class="btn btn-success">{{'button.continue' | translate}}</button>
  </div>
</ng-template>


<!-- *ngIf="currentUser" -->