import { BaseOwnerEntity } from "../../entity/baseOwnerEntity";

export class OperationalDemandEntity extends BaseOwnerEntity {
  id?: number;
  storeId?: number;
  subject?: string;
  message?: string;
  repliedMessage?: string;
  demandTypeId?: number;
  value?: string;
  demandedUser?: number;
  isDone?: boolean;
  completedDate?: Date;
  status?: number;
}
