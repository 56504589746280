import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ReminderService } from './reminder.service';
import { ReminderEntity } from 'app/models/entity/core/reminder.entity';
import { AuthService } from '../auth.service';
import { AlertifyService } from '../core/alertify.service';
import { SoundService } from '../core/sound.service';
import { UserService } from '../user/user.service';
import { UserActiveNotificationResponse } from 'app/models/rest/user/user-active-notification-response';
import { OperationalDemandService } from '../core/operational-demand.service';

@Injectable({
  providedIn: 'root'
})
export class ReminderOperationalService {

  intervalReminder;
  isReminderModalOpen: boolean = false;

  remindersChangedSubscription: Subscription;
  reminderTimerDuration: number = 30000;

  reminders: UserActiveNotificationResponse[] = []
  lastRefreshDate: Date;

  public reminderNotifyModal = new Subject<UserActiveNotificationResponse>();

  constructor(private authService: AuthService,
    private alertifyService: AlertifyService,
    private userService: UserService,
    private reminderService: ReminderService,
    private soundService: SoundService,
    private operationalDemandService: OperationalDemandService
  ) {


  }

  subscribeWhenReminderChanged() {
    this.remindersChangedSubscription = this.reminderService.onRemindersChanged()
      .subscribe(() => {
        this.getReminders();
      });
    this.remindersChangedSubscription = this.operationalDemandService.onRemindersChanged()
      .subscribe(() => {
        this.getReminders();
      });
  }

  async startReminderTimer() {
    if (this.intervalReminder)
      return;
    this.stopReminderTimer();
    await this.getReminders();
    this.subscribeWhenReminderChanged();
    this.intervalReminder = setInterval(async () => {

      if (this.isReminderModalOpen)
        return;

      //son çalışma süresi 30 sn ye geçti ise tekrar başlat
      if (this.lastRefreshDate != null && this.lastRefreshDate.addMinutes(10) < new Date()) {
        await this.getReminders();
      }

      const currentTime = new Date();
      for (var item of this.reminders) {
        const notificationTime = item.notificationDate; // 'notification.time' bildirimin zamanını temsil etmeli
        if (currentTime >= notificationTime) {
          // Bildirimi göster
          // this.openProductInformationModal(item);

          this.reminderNotifyModal.next(item);
          break;
        }
      }
    }, this.reminderTimerDuration);


  }

  stopReminderTimer() {
    clearInterval(this.intervalReminder);
    if (this.remindersChangedSubscription)
      this.remindersChangedSubscription.unsubscribe();
  }

  // Değişiklikleri dinlemek için
  onReminderNotifyModalChanged(): Observable<ReminderEntity> {
    return this.reminderNotifyModal.asObservable();
  }

  async getReminders() {
    try {


      const res = await this.userService.getActiveNotications().toPromise();
      this.reminders = res ?? [];

      this.lastRefreshDate = new Date();

    }
    catch (err) {
      this.alertifyService.error(err);
      this.stopReminderTimer();
    }
  }

  updateModalStatus(isOpen: boolean) {

    this.isReminderModalOpen = isOpen;

    if (isOpen) {
      this.soundService.startRepeatingSound();
    }
    else {
      this.soundService.stopRepeatingSound();
    }
  }
}
