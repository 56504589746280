<div class="card">
    <div class="card-body">
        <h4 data-private class="card-title text-center">{{'title.billing-info' | translate}}</h4>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" *ngIf="customer.isBilling">
                <small class="info-label">{{'customer.isBilling' | translate}}</small>
                <div>
                    <app-boolean-badge [value]="customer.isBilling"> </app-boolean-badge>
                </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6" *ngIf="customer.isBilling&& customer.billingProfileId">
                <small class="info-label">{{ 'invoice.documentType'| translate}}</small>
                <h6>{{ 'invoiceDocumentTypeValue.'+ customer.billingProfileId | translate }}</h6>
            </div>


            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" *ngIf="customer.taxOffice">
                <small class="info-label">{{'common.taxOffice' | translate}}</small>
                <div class="info-content"> {{ customer.taxOffice }}</div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" *ngIf="customer.taxId">
                <small class="info-label">{{'common.taxId' | translate}}</small>
                <div class="info-content"> {{ customer.taxId }}</div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12" *ngIf="customer.billingName">
                <small class="info-label">{{'customer.billingName' | translate}}</small>
                <div class="info-content"> {{ customer.billingName }}</div>
            </div>
        </div>

    </div>
</div>