export { };
import {
  DateFormatEnum
} from "app/enum/date-format.enum";
var moment = require('moment'); // require
declare global {
  interface Date {
    addSeconds(seconds: number): Date;
    addMinutes(minutes: number): Date;
    addHours(hours: number): Date;
    addDays(days: number): Date;
    addWeeks(weeks: number): Date;
    addMonths(months: number): Date;
    addYears(years: number): Date;
    firstWeekDay(): Date;
    lastWeekDay(): Date;
    firstWeekDayOfMonth(): Date;
    firstMonthDay(): Date;
    lastMonthDay(): Date;
    firstYearDay(): Date;
    lastYearDay(): Date;
    //clearZone(): Date;
    // clearZone2(): Date;
    clearTime(): Date;
    clearSecond(): Date;
    isToday(): boolean;
    clone(): Date;
    isAnotherMonth(date: Date): boolean;
    isWeekend(): boolean;
    isSameDate(date: Date): boolean;
    isAfter(date: Date): boolean;
    isBefore(date: Date): boolean;
    getStringDate(): String;

    diffDays(date: Date): number;
    toFormat(format: string): string;
  }
}

// Date.prototype.addDays = function(days: number): Date {
//   if (!days) return this;
//   let date = this;
//   date.setDate(date.getDate() + days);

//   return date;
// };

// Date.prototype.addMinutes = function(minutes: number): Date {
//   if (!minutes) return this;
//   let date = this;
//   date.setDate(date.getDate() + minutes);

//   return date;
// };

// Date.prototype.toISOString = function () {
//   if (this == null)
//     return "";

//   // console.log(this.getFullYear() + "-" + this.getMonth().toString().padStart(2, "0")
//   // + "-" + this.getDate().toString().padStart(2, "0") + "T"
//   // + this.getHours().toString().padStart(2, "0") + ":"
//   // + this.getMinutes().toString().padStart(2, 0) + ":"
//   // + this.getSeconds().toString().padStart(2, 0));


//   return this.format('YYYY-MM-DDTHH:mm:ss');
//     // return this.getFullYear() + "-" + this.getMonth().toString().padStart(2, "0")
//     //   + "-" + this.getDate().toString().padStart(2, "0") + "T"
//     //   + this.getHours().toString().padStart(2, "0") + ":"
//     //   + this.getMinutes().toString().padStart(2, 0) + ":"
//     //   + this.getSeconds().toString().padStart(2, 0);
// };

Date.prototype.toJSON = function () {
  // console.log( moment(this, moment.ISO_8601 ).format("YYYY-MM-DDTHH:mm:ss"));
  return moment(this, moment.ISO_8601).format(DateFormatEnum.DATE_TIME_SERILIAZE_FORMAT);
  // return moment(this, moment.ISO_8601).format("YYYY-MM-DDTHH:mm:ss");
};

Date.prototype.toFormat = function (format: String) {
  // console.log( moment(this, moment.ISO_8601 ).format("YYYY-MM-DDTHH:mm:ss"));
  return moment(this, moment.ISO_8601).format(format);
  // return moment(this, moment.ISO_8601).format("YYYY-MM-DDTHH:mm:ss");
};
Date.prototype.addSeconds = function (seconds) {
  return new Date(this.getTime() + seconds * 1000);
};

Date.prototype.addMinutes = function (minutes) {
  return new Date(this.getTime() + minutes * 60000);
};

Date.prototype.addHours = function (hours) {
  return new Date(this.getTime() + hours * 3600000);
};

Date.prototype.addDays = function (days) {
  return new Date(this.getTime() + days * 86400000);
};

Date.prototype.addWeeks = function (weeks) {
  return this.addDays(weeks * 7);
};

Date.prototype.addMonths = function (months) {
  var newDate = new Date(this);
  newDate.setMonth(this.getMonth() + months);

  // Ayın son gününde olduğunda ve yeni ay daha kısa olduğunda ayarlamalar yapılır
  if (newDate.getDate() < this.getDate()) {
    newDate.setDate(0);
  }

  return newDate;
};

Date.prototype.addYears = function (years) {
  var newDate = new Date(this);
  newDate.setFullYear(this.getFullYear() + years);

  // Yılın son gününde olduğunda ve şubat ayı gibi özel durumlar için ayarlamalar yapılır
  if (newDate.getMonth() < this.getMonth()) {
    newDate.setDate(0);
  }

  return newDate;
};


Date.prototype.firstWeekDay = function () {

  var day = this.getDay(),
    diff = this.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(this.setDate(diff));
};

Date.prototype.lastWeekDay = function () {
  var day = this.getDay(),
    diff = this.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(this.setDate(diff)).addDays(6);
};
Date.prototype.firstWeekDayOfMonth = function () {
  var year = this.getFullYear(), // Yıl
    month = this.getMonth(), // Ay
    firstDay = new Date(year, month, 1), // Ayın ilk günü
    dayOfWeek = firstDay.getDay(); // Ayın ilk gününün haftanın kaçıncı günü olduğu

  // Ayın ilk günü Pazartesi değilse, bir sonraki Pazartesi'ye ayarla
  var diff = dayOfWeek === 0 ? 1 : (dayOfWeek === 1 ? 0 : (8 - dayOfWeek));
  return new Date(year, month, firstDay.getDate() + diff);
};

Date.prototype.firstMonthDay = function () {
  var dt = this.getDate();
  dt = new Date(this.getFullYear(), this.getMonth(), 1);

  return dt;
};

Date.prototype.lastMonthDay = function () {
  var dt = this.getDate();
  dt = new Date(this.getFullYear(), this.getMonth() + 1, 0);

  return dt;
};

Date.prototype.firstYearDay = function () {
  var dt = this.getDate();
  dt = new Date(this.getFullYear(), 0, 1);

  return dt;
};

Date.prototype.lastYearDay = function () {
  var dt = this.getDate();
  dt = new Date(this.getFullYear(), 12, 0);

  return dt;
};

// Date.prototype.clearZone = function(): Date {
//   let date = this;
//   date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
//   return date;
// };

// Date.prototype.clearZone2 = function(): Date {
//   let date = new Date(
//     this.getFullYear(),
//     this.getMonth(),
//     this.getDay(),
//     this.getHours(),
//     this.getMinutes(),
//     this.getSeconds(),
//     0
//   );
//   return date;
// };
Date.prototype.clearTime = function (): Date {
  this.setHours(0);
  this.setMinutes(0);
  this.setSeconds(0);
  return this;
};
Date.prototype.clearSecond = function (): Date {
  this.setSeconds(0);
  return this;
};


Date.prototype.isToday = function (): boolean {
  let today = new Date();
  return this.isSameDate(today);
};


Date.prototype.clone = function (): Date {
  return new Date(+this);
};

Date.prototype.isAnotherMonth = function (date: Date): boolean {
  return date && this.getMonth() !== date.getMonth();
};

Date.prototype.diffDays = function (date: any): number {
  return Math.ceil(Math.abs(this - date) / (1000 * 60 * 60 * 24));
};


Date.prototype.isWeekend = function (): boolean {
  return this.getDay() === 0 || this.getDay() === 6;
};
Date.prototype.isSameDate = function (date: Date): boolean {
  return (
    date &&
    this.getFullYear() === date.getFullYear() &&
    this.getMonth() === date.getMonth() &&
    this.getDate() === date.getDate()
  );
};
// isAfter metodu: Mevcut tarih verilen tarihten sonra mı diye kontrol eder
Date.prototype.isAfter = function (date) {
  return this.getTime() > date.getTime();
};

// isBefore metodu: Mevcut tarih verilen tarihten önce mi diye kontrol eder
Date.prototype.isBefore = function (date) {
  return this.getTime() < date.getTime();
};



Date.prototype.getStringDate = function (): String {
  //Month names in Brazilian Portuguese
  let monthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ];
  //Month names in English
  //let monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  let today = new Date();
  if (this.getMonth() == today.getMonth() && this.getDay() == today.getDay()) {
    return "Hoje";
    //return "Today";
  } else if (
    this.getMonth() == today.getMonth() &&
    this.getDay() == today.getDay() + 1
  ) {
    return "Amanhã";
    //return "Tomorrow";
  } else if (
    this.getMonth() == today.getMonth() &&
    this.getDay() == today.getDay() - 1
  ) {
    return "Ontem";
    //return "Yesterday";
  } else {
    return (
      this.getDay() +
      " de " +
      this.monthNames[this.getMonth()] +
      " de " +
      this.getFullYear()
    );
    //return this.monthNames[this.getMonth()] + ' ' + this.getDay() + ', ' +  this.getFullYear();
  }
};
