import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-current-account-balance-badge',
  templateUrl: './current-account-balance-badge.component.html',
  styleUrls: ['./current-account-balance-badge.component.css']
})
export class CurrentAccountBalanceBadgeComponent implements OnInit {

  @Input() balance: number;
  @Input() showLabel: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
