import { Injectable } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import { LogTypeEnum } from "..//../enum/log-type.enum";
import { SessionStorageService } from "../session-storage.service";
@Injectable({
  providedIn: "root"
})
export class AlertifyService {
  constructor(private toastr: ToastrService,
    private sessionStorageService: SessionStorageService) { }

  info(message: string) {
    if (typeof message == 'string') {
      message = message.replace(/\n/g, '<br>');
    }
    this.toastr.info(message, "", {
      enableHtml: true,
      timeOut: 1000,
      positionClass: 'toast-top-center',
      closeButton: true,
      progressBar: true,
    });
    // alertify.success(message);
  }

  infoTopCenter(message: string, title: string = "") {
    if (typeof message == 'string') {
      message = message.replace(/\n/g, '<br>');
    }
    this.toastr.info(message, title, {
      enableHtml: true,
      timeOut: 2000,
      positionClass: 'toast-top-center',
      closeButton: true,
      progressBar: true,

    })
  }
  success(message: string, title: string = "") {
    if (typeof message == 'string') {
      message = message.replace(/\n/g, '<br>');
    }
    this.toastr.success(message, title, {
      enableHtml: true,
      timeOut: 3000,
      positionClass: 'toast-top-center',
      closeButton: true,
      progressBar: true,
    });
    // alertify.success(message);
  }

  warning(message: string, title: string = "") {
    if (typeof message == 'string') {
      message = message.replace(/\n/g, '<br>');
    }
    this.toastr.warning(message, title, {
      enableHtml: true,
      timeOut: 3000,
      positionClass: 'toast-top-center',
      closeButton: true,
      progressBar: true,
    });
    this.sessionStorageService.addLocalLog(LogTypeEnum.WARNING, message);
  }


  error(message: string, title: string = "") {
    if (typeof message == 'string') {
      message = message.replace(/\n/g, '<br>');
    }

    // alertify.error(message);
    console.log(message);

    this.toastr.error(message, title, {
      enableHtml: true,  // HTML desteğini etkinleştir
      timeOut: 6000,
      positionClass: 'toast-top-center',
      progressBar: true,
      closeButton: true,
    });
    this.sessionStorageService.addLocalLog(LogTypeEnum.ERROR, message);
  }


  callerIdNotifier(message: string, isDanger: boolean) {
    if (typeof message == 'string') {
      message = message.replace(/\n/g, '<br>');
    }
    if (isDanger) {
      this.toastr.error(message, "GELEN ARAMA", {
        enableHtml: true,
        timeOut: 60000,
        positionClass: 'toast-bottom-right',
        closeButton: true,
        progressBar: true,

      })
    }
    else {
      this.toastr.success(message, "GELEN ARAMA", {
        enableHtml: true,
        timeOut: 60000,
        positionClass: 'toast-bottom-right',
        closeButton: true,
        progressBar: true,

      })
    }

    this.sessionStorageService.addLocalLog(LogTypeEnum.INFO, message);
  }

}
