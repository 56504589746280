import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateFormatEnum } from 'app/enum/date-format.enum';
import { SmsTemplateEntity } from 'app/models/entity/sms/sms-template-entity';
import { AlertifyService } from 'app/services/core/alertify.service';
import { SmsTemplateService } from 'app/services/sms/sms-template.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { SmsSpecialFieldEnum, SmsSpecialFieldEnumForCustomValues, SmsSpecialFieldEnumForOrderValues } from 'app/enum/sms/sms-special-field.enum';
import { SmsService } from 'app/services/sms/sms.service';
import { SendSmsPreviewRequest } from 'app/models/rest/sms/send-sms-preview-request';
import { SmsMessageTypeEnum, SmsMessageTypeEnumForOrderValues } from 'app/enum/sms/sms-message-type.enum';

@Component({
  selector: 'app-sms-template-add-update',
  templateUrl: './sms-template-add-update.component.html',
  styleUrls: ['./sms-template-add-update.component.css']
})
export class SmsTemplateAddUpdateComponent implements OnInit {

  @Input() data: SmsTemplateEntity;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  dateFormatEnum = DateFormatEnum;
  specialField: string = SmsSpecialFieldEnum.CUSTOMER_NAME;
  smsSpecialFieldEnum = SmsSpecialFieldEnum;
  messagePreview: string;

  specialFields = [];


  constructor(private clipboard: Clipboard,
    private smsService: SmsService,
    private smsTemplateService: SmsTemplateService,
    private translate: TranslateService,
    private alertifyService: AlertifyService) { }

  ngOnInit(): void {
    if (this.data) {

    }
    else {
      this.data = new SmsTemplateEntity();
      this.data.usageFor = SmsMessageTypeEnum.CUSTOM;
    }

    this.specialFields.pushRange(SmsSpecialFieldEnumForCustomValues);


    if (SmsMessageTypeEnumForOrderValues.where(x => x == this.data.usageFor).length > 0) {
      this.specialFields.pushRange(SmsSpecialFieldEnumForOrderValues);
    }

  }


  //güncelle veya yeni kayıt ekle
  save() {
    if (!this.validateSave())
      return;


    //Yeni kayıt ise
    if (!this.data.id) {
      this.add();
    }
    //Var olan kaydı güncelle
    else {
      this.update();
    }
  }

  //kontroller
  validateSave() {

    return true;
  }

  // yeni kayıt ekle
  add() {
    this.smsTemplateService.addSmsTemplate(this.data).subscribe(
      (res) => {
        this.data = res;
        this.alertifyService.success(this.translate.instant('message.info.saved'));
        this.passEntry.emit(this.data);

      },
      (err) => {
        this.alertifyService.error(err);
      }
    );
  }

  // güncelle
  update() {
    this.smsTemplateService
      .updateSmsTemplate(this.data.id, this.data)
      .subscribe(
        (res) => {
          this.data = res;
          this.alertifyService.success(this.translate.instant('message.info.updated'));
          this.passEntry.emit(this.data);
        },
        (err) => {
          this.alertifyService.error(err);
        }
      );
  }

  close() {
    this.passEntry.emit();
  }


  copySpecialField() {

    this.clipboard.copy("{" + this.specialField + "}");
    this.alertifyService.info(this.translate.instant("message.info.copied-clipboard"));
    // this.sendSmsBulkRequest.message = this.sendSmsBulkRequest.message + "{" + this.specialField + "}";
  }

  previewSms() {

    let request = new SendSmsPreviewRequest();
    request.message = this.data.message;
    request.smsMessageType = this.data.usageFor;
    this.smsService.getSmsPreview(request).subscribe(
      (res) => {
        this.messagePreview = res.message;
      }
      ,
      (err) => {

        this.alertifyService.error(err);
      }
    )
  }
}
