import { Component, Input, OnInit } from '@angular/core';
import { isNullOrEmpty } from 'app/helper/utils';
import { Address } from 'app/models/entity/address';
import { AuthService } from 'app/services/auth.service';
import { StoreCacheService } from 'app/services/cache/store-cache.service';

@Component({
  selector: 'app-address-maps-widget',
  templateUrl: './address-maps-widget.component.html',
  styleUrls: ['./address-maps-widget.component.css']
})
export class AddressMapsWidgetComponent implements OnInit {
  @Input() address: Address;
  storeAddress: Address;
  constructor(private storeCacheService: StoreCacheService,
    private authService: AuthService) { }

  ngOnInit(): void {
  }

  getAddressForMaps() {
    let query = "https://www.google.com/maps/dir";

    let sourceKey = this.getSearchKey(this.storeAddress);
    let destinationKey = this.getSearchKey(this.address);

    if (!isNullOrEmpty(sourceKey))
      query = query + "/" + sourceKey;

    if (!isNullOrEmpty(destinationKey))
      query = query + "/" + destinationKey;

    return query;

  }

  getSearchKey(data: Address) {

    if (data.isLocationValid) {
      return data.latitude + "," + data.longitude;
    }

    let searchKey = "";

    if (!isNullOrEmpty(data.description))
      searchKey = searchKey + data.description + ",";

    if (!isNullOrEmpty(data.subProvinceName))
      searchKey = searchKey + data.subProvinceName + ",";

    if (!isNullOrEmpty(data.countryName))
      searchKey = searchKey + data.cityName + "," + data.countryName;

    return searchKey.split("/").join(" ");
  }


  openMaps() {
    if (this.storeAddress == null)
      this.getStoreAddress();
    else
      this.openUrlInBrowser();
  }

  private openUrlInBrowser() {
    window.open(this.getAddressForMaps(), '_blank');
  }

  private getStoreAddress() {
    this.storeCacheService.getAddressByStoreId(this.authService.getStoreId()).subscribe(data => {
      this.storeAddress = data;
      this.openUrlInBrowser();
    });
  }


}
