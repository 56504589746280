import { UserLoginResponse } from "./../../models/rest/user/userLoginResponse";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
declare var $: any;
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  showMenu = "";
  showSubMenu = "";
  currentUser: UserLoginResponse;
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = "0";
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = "0";
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(private authService: AuthService) {
    this.authService.currentUser.subscribe((res) => {
      this.currentUser = res;
      this.authService.getRoleCodes();
    });
  }
  // End open close
  ngOnInit() {
  }
  logout() {
    this.authService.doLogout();
  }
}
