import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-privacy-policy',
  templateUrl: './app-privacy-policy.component.html',
  styleUrls: ['./app-privacy-policy.component.css']
})
export class AppPrivacyPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
