<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'title.select-date-range' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>
<div class="modal-body">
  <!-- Güncelleme  -->
  <div>
    <form #orderChangeDateForm="ngForm" class="needs-validation" novalidate>
      <div class="card">
        <div class="card-body">
          <div class="col-md-12">
            <div class="row" *ngIf="isCustomSelected">
              <div class="row col-md-6 form-group">
                <div class="col-sm-4">
                  <label class="custom-form-label">{{'common.startDate' | translate}}</label>
                </div>
                <div class="col-sm-8">

                  <p-calendar name="startDateInput" ngDefaultControl [(ngModel)]="startDate" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
                    [dateFormat]="dateFormatEnum.DATE_PICKER_INPUT_FORMAT" [firstDayOfWeek]="'firstDayOfWeek'| translate">
                  </p-calendar>
                </div>
              </div>

              <div class="row col-md-6 form-group">
                <div class="col-sm-4">
                  <label class="custom-form-label">{{'common.endDate' | translate}}</label>
                </div>
                <div class="col-sm-8">

                  <p-calendar name="endDateInput" ngDefaultControl [(ngModel)]="endDate" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
                    [dateFormat]="dateFormatEnum.DATE_PICKER_INPUT_FORMAT" [firstDayOfWeek]="'firstDayOfWeek'| translate">
                  </p-calendar>
                </div>
              </div>

              <div class="col-xs-12 col-md-12 d-flex justify-content-center">
                <button type="button" class="btn btn-outline-danger" (click)="clearCustom()" *ngIf="isCustomSelected">
                  {{'button.show-date-options' | translate}}
                </button>
              </div>
            </div>

            <div class="col-xs-12 col-md-12 form-group container" *ngIf="!isCustomSelected">
              <div class="btn-group-vertical me-1">
                <button class="btn btn-sm btn-outline-primary  m-2" (click)="selectRange(dateRangeEnum.TODAY)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.TODAY | translate}}
                </button>
                <button class="btn btn-sm btn-outline-primary  m-2" (click)="selectRange(dateRangeEnum.THIS_WEEK)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.THIS_WEEK | translate}}
                </button>
                <button class="btn btn-sm btn-outline-primary  m-2" (click)="selectRange(dateRangeEnum.THIS_MONTH)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.THIS_MONTH | translate}}
                </button>
                <button class="btn btn-sm btn-outline-primary  m-2" (click)="selectRange(dateRangeEnum.THIS_YEAR)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.THIS_YEAR | translate}}
                </button>
              </div>
              <div class="btn-group-vertical me-1" *ngIf="!hidePast">
                <button class="btn btn-sm btn-outline-primary  m-2" (click)="selectRange(dateRangeEnum.YESTERDAY)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.YESTERDAY | translate}}
                </button>
                <button class="btn btn-sm btn-outline-primary  m-2" (click)="selectRange(dateRangeEnum.LAST_WEEK)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.LAST_WEEK | translate}}
                </button>
                <button class="btn btn-sm btn-outline-primary  m-2" (click)="selectRange(dateRangeEnum.LAST_MONTH)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.LAST_MONTH | translate}}
                </button>
                <button class="btn btn-sm btn-outline-primary  m-2" (click)="selectRange(dateRangeEnum.LAST_YEAR)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.LAST_YEAR | translate}}
                </button>
              </div>

              <div class="btn-group-vertical me-1" *ngIf="!hideTomorrow">
                <button class="btn btn-sm btn-outline-primary  m-2" (click)="selectRange(dateRangeEnum.TOMORROW)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.TOMORROW | translate}}
                </button>
                <button class="btn btn-sm btn-outline-primary  m-2" (click)="selectRange(dateRangeEnum.NEXT_WEEK)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.NEXT_WEEK | translate}}
                </button>
                <button class="btn btn-sm btn-outline-primary  m-2" (click)="selectRange(dateRangeEnum.NEXT_MONTH)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.NEXT_MONTH | translate}}
                </button>
                <button class="btn btn-sm btn-outline-primary  m-2" (click)="selectRange(dateRangeEnum.NEXT_YEAR)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.NEXT_YEAR | translate}}
                </button>
              </div>

              <div class="btn-group-vertical">
                <button class="btn btn-sm btn-outline-primary m-2" (click)="selectRange(dateRangeEnum.FIRST_WEEK_OF_MONTH)">
                  {{ 'dateRangeEnumValue.FIRST_WEEK_OF_MONTH' | translate }}
                </button>
                <button class="btn btn-sm btn-outline-primary m-2" (click)="selectRange(dateRangeEnum.SECOND_WEEK_OF_MONTH)">
                  {{ 'dateRangeEnumValue.SECOND_WEEK_OF_MONTH' | translate }}
                </button>
                <button class="btn btn-sm btn-outline-primary m-2" (click)="selectRange(dateRangeEnum.THIRD_WEEK_OF_MONTH)">
                  {{ 'dateRangeEnumValue.THIRD_WEEK_OF_MONTH' | translate }}
                </button>
                <button class="btn btn-sm btn-outline-primary m-2" (click)="selectRange(dateRangeEnum.FOURTH_WEEK_OF_MONTH)">
                  {{ 'dateRangeEnumValue.FOURTH_WEEK_OF_MONTH' | translate }}
                </button>
              </div>


              <div class="btn-group-vertical ms-2">
                <button class="btn btn-sm btn-outline-primary  m-2" (click)="selectRange(dateRangeEnum.LAST7DAY)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.LAST7DAY | translate}}
                </button>
                <button class="btn btn-sm btn-outline-primary  m-2" (click)="selectRange(dateRangeEnum.LAST30DAY)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.LAST30DAY | translate}}
                </button>
                <button class="btn btn-sm btn-outline-primary  m-2" (click)="selectRange(dateRangeEnum.LAST90DAY)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.LAST90DAY | translate}}
                </button>
                <button class="btn btn-sm btn-outline-primary  m-2" (click)="selectRange(dateRangeEnum.ALL)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.ALL | translate}}
                </button>
                <button class="btn btn-secondary  m-2" (click)="selectRange(dateRangeEnum.CUSTOM)">
                  {{'dateRangeEnumValue.'+dateRangeEnum.CUSTOM | translate}}
                </button>

              </div>

              <div class="btn-group-horizontal">

                <button *ngFor="let month of months" class="btn btn-sm btn-outline-secondary  m-2" (click)="selectMonth(month)">
                  {{getMonthName(month) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="close()">
    {{'button.close' | translate}}
  </button>
  <button type="button" [disabled]="orderChangeDateForm.form.invalid" class="btn btn-primary" *ngIf="dateRange ===dateRangeEnum.CUSTOM" (click)="apply()">
    {{'button.apply' | translate}}
  </button>
</div>