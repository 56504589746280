<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ (data?.id?'title.update-record':'title.new-record') | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>

<div class="modal-body">
  <!-- Güncelleme  -->
  <div>
    <form #createForm="ngForm" class="needs-validation" novalidate>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <!-- Ad-->
              <div class="col">
                <label class="custom-form-label" for="name">{{'sms.smsTemplateName' | translate}}</label>
                <input #name="ngModel" type="text" class="form-control" name="name" [(ngModel)]="data.name" required [readonly]="data.usageFor!='CUSTOM'"
                  [class.is-invalid]="name.invalid && name.touched" />

                <app-field-error-display [displayError]="name.invalid && name.touched" errorMsg="{{'message.error.cannot-be-blank' | translate}}">
                </app-field-error-display>
              </div>

              <div class="col">
                <label class="custom-form-label" for="message">{{'sms.message' | translate}}</label>
                <textarea type="text" #message="ngModel" name="message" style="height: 400px;" [(ngModel)]="data.message" class="form-control">
              </textarea>
                <app-field-error-display [displayError]="message.invalid && message.touched" errorMsg="{{'message.error.cannot-be-blank' | translate}}">
                </app-field-error-display>
              </div>

              <div class="pull-right" *ngIf="data.message">
                {{'sms.messageLength' | translate }}: <span class="length label label-sm label-danger">{{data.message?.length}}</span>
              </div>

            </div>
            <div class="col-xs-12 col-md-6">
              <div class="form-group">
                <div class="input-group mb-3">
                  <div class="col">
                    <label class="custom-form-label" for="specialField">{{'sms.specialFields' | translate}}</label>
                    <select name="specialField" class="form-control" [(ngModel)]="specialField" (ngModelChange)="copySpecialField()">
                      <option *ngFor="let item of specialFields" [ngValue]="item">{{ 'templateKeywordValue.'+item | translate}}
                      </option>
                    </select>
                  </div>
                  <div class="input-group-append">
                    <button class="btn btn-primary btn-input-group-append" type="button" (click)="copySpecialField()" triggers="mouseenter:mouseleave" placement="top" container="body"
                      [ngbPopover]="'button.copy'| translate">
                      <i class="fa fa-copy"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="show-new-line" style="height: 400px;">{{messagePreview}}</div>
              <div class="pull-right" *ngIf="messagePreview">
                {{'sms.messageLength' | translate }}: <span class="length label label-sm label-danger">{{messagePreview?.length}}</span>
              </div>
            </div>
          </div>


        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">
    {{'button.close' | translate}}
  </button>
  <button type="button" class="btn btn-outline-primary" (click)="previewSms()">
    {{'title.preview' | translate}}
  </button>
  <button type="button" [disabled]="createForm.form.invalid" class="btn btn-primary" (click)="save()">
    {{'button.save' | translate}}
  </button>
</div>