<div class="d-flex justify-content-between align-items-center">
  <span *ngIf="showLabel" class="info-label">{{'customer.currentAccountBalance' | translate}}</span>

  <h4> <span class="badge rounded-pill" [ngClass]="{'bg-success':balance>0,'bg-danger':balance<0,'bg-info':balance==0}">
      <span *ngIf="balance>0" class="me-2">{{'payment.current-account-positive-status' | translate}}</span>
      <span *ngIf="balance<0" class="me-2">{{'payment.current-account-negative-status' | translate}}</span>
      {{ balance | thousandSeparatorWithDecimals }}
    </span>
  </h4>


</div>