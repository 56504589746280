import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { throwError as observableThrowError, Observable, Subject } from "rxjs";
import { OperationalDemandEntity } from "../../models/entity/core/operational-demand-entity";
import { BaseRestService } from "../base-rest.service";
import { CommonRequest } from "app/models/rest/common/commonRequest";
import { OperationalDemandProcessRequest } from "app/models/rest/core/operational-demand-process-request";
import { SessionStorageService } from "../session-storage.service";

@Injectable({
  providedIn: 'root'
})
export class OperationalDemandService extends BaseRestService {
  public remindersChanged = new Subject<void>();

  constructor(protected httpClient: HttpClient, protected sessionStorageService: SessionStorageService) {
    super(httpClient, sessionStorageService);
  }
  // Hatırlatıcı listesinde değişiklik olduğunda çağrılacak
  notifyRemindersChanged() {
    this.remindersChanged.next();
  }

  // Değişiklikleri dinlemek için
  onRemindersChanged(): Observable<void> {
    return this.remindersChanged.asObservable();
  }
  getOperationalDemands(): Observable<OperationalDemandEntity[]> {
    const url = `/operational-demands`;
    return this.httpGet<OperationalDemandEntity[]>(url).pipe(catchError(this.errorHandler));
  }

  getOperationalDemandEntityById(id) {
    const url = `/operational-demands/${id}`;
    return this.httpGet(url).pipe(catchError(this.errorHandler));
  }

  addOperationalDemand(data) {
    const url = `/operational-demands`;
    return this.httpPost<OperationalDemandEntity>(url, data).pipe(catchError(this.errorHandler),
      tap(() => this.remindersChanged.next()));
  }

  updateOperationalDemand(id, data): Observable<OperationalDemandEntity> {
    const url = `/operational-demands/${id}`;
    return this.httpPut<OperationalDemandEntity>(url, data)
      .pipe(catchError(this.errorHandler),
        tap(() => this.remindersChanged.next()));
  }

  deleteOperationalDemand(id) {
    const url = `/operational-demands/${id}`;
    return this.httpDelete(url).pipe(catchError(this.errorHandler),
      tap(() => this.remindersChanged.next()));
  }

  getOperationalDemandsBySearch(data: CommonRequest): Observable<OperationalDemandEntity[]> {
    const url = `/operational-demands/search`;
    return this.httpPost<OperationalDemandEntity[]>(url, data).pipe(catchError(this.errorHandler));
  }

  process(data: OperationalDemandProcessRequest) {
    const url = `/operational-demands/process`;
    return this.httpPost(url, data).pipe(catchError(this.errorHandler),
      tap(() => this.remindersChanged.next()));
  }


  errorHandler(error: HttpErrorResponse) {
    if (error.message)
      return observableThrowError(error);
    else return observableThrowError(error || " Server Error");
  }
}
