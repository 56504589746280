export enum SmsMessageTypeEnum {
  CUSTOMER_FORGOT_PASSWORD = "CUSTOMER_FORGOT_PASSWORD",
  BONUS_ADDED = "BONUS_ADDED",
  BONUS_GAINED = "BONUS_GAINED",
  BONUS_REMIND = "BONUS_REMIND",
  CURRENT_ACCOUNT_ADDED_CREDIT = "CURRENT_ACCOUNT_ADDED_CREDIT",
  CURRENT_ACCOUNT_ADDED_DEBIT = "CURRENT_ACCOUNT_ADDED_DEBIT",
  CURRENT_ACCOUNT_BALANCE_REMIND = "CURRENT_ACCOUNT_BALANCE_REMIND",
  LOYALTY_CARD_DEBTED_REMIND = "LOYALTY_CARD_DEBTED_REMIND",
  LOYALTY_CARD_BALANCE_REMIND = "LOYALTY_CARD_BALANCE_REMIND",
  CUSTOM = "CUSTOM",
  CUSTOMER_CREATED = "CUSTOMER_CREATED",
  ORDER_CREATED = "ORDER_CREATED",
  ORDER_INCOMPLETE_DELIVER = "ORDER_INCOMPLETE_DELIVER",
  ORDER_DELIVERED = "ORDER_DELIVERED",
  ORDER_IN_SERVICE = "ORDER_IN_SERVICE",
  ORDER_PICKUP = "ORDER_PICKUP",
  ORDER_READY = "ORDER_READY",
  ORDER_READY_FOR_DELIVERY = "ORDER_READY_FOR_DELIVERY",
  ORDER_READY_REMINDER = "ORDER_READY_REMINDER",
  SEND_ORDER_INFO_TO_SERVICE_CAR = "SEND_ORDER_INFO_TO_SERVICE_CAR",
  OTP = "OTP",
  ONLINE_COLLECTION_LINK = "ONLINE_COLLECTION_LINK",
  ORDER_ONLINE_COLLECTION_LINK = "ORDER_ONLINE_COLLECTION_LINK",
  DEBTOR_CUSTOMER = "DEBTOR_CUSTOMER",
  CUSTOMER_HAS_UNDELIVERED_ORDER = "CUSTOMER_HAS_UNDELIVERED_ORDER",
  CUSTOMER_NOT_REACHED = "CUSTOMER_NOT_REACHED",
  CUSTOMER_BALANCE = "CUSTOMER_BALANCE",
  ORDER_RECEIPT_LINK = "ORDER_RECEIPT_LINK",
  BILLING_LINK = "BILLING_LINK"


}

export const SmsMessageTypeEnumValues: string[] = [
  SmsMessageTypeEnum.ORDER_CREATED,
  SmsMessageTypeEnum.ORDER_PICKUP,
  SmsMessageTypeEnum.SEND_ORDER_INFO_TO_SERVICE_CAR,
  SmsMessageTypeEnum.ORDER_READY,
  SmsMessageTypeEnum.ORDER_READY_FOR_DELIVERY,
  SmsMessageTypeEnum.ORDER_READY_REMINDER,
  SmsMessageTypeEnum.ORDER_IN_SERVICE,
  SmsMessageTypeEnum.ORDER_INCOMPLETE_DELIVER,
  SmsMessageTypeEnum.ORDER_DELIVERED,
  SmsMessageTypeEnum.CUSTOMER_NOT_REACHED,
  SmsMessageTypeEnum.ORDER_RECEIPT_LINK,

  SmsMessageTypeEnum.CUSTOMER_FORGOT_PASSWORD,
  SmsMessageTypeEnum.BONUS_ADDED,
  SmsMessageTypeEnum.BONUS_GAINED,
  SmsMessageTypeEnum.BONUS_REMIND,
  SmsMessageTypeEnum.CURRENT_ACCOUNT_ADDED_CREDIT,
  SmsMessageTypeEnum.CURRENT_ACCOUNT_ADDED_DEBIT,
  SmsMessageTypeEnum.CURRENT_ACCOUNT_BALANCE_REMIND,
  SmsMessageTypeEnum.LOYALTY_CARD_BALANCE_REMIND,
  SmsMessageTypeEnum.LOYALTY_CARD_DEBTED_REMIND,
  SmsMessageTypeEnum.CUSTOM,
  SmsMessageTypeEnum.CUSTOMER_CREATED,
  SmsMessageTypeEnum.OTP,
  SmsMessageTypeEnum.ONLINE_COLLECTION_LINK,
  SmsMessageTypeEnum.DEBTOR_CUSTOMER,
  SmsMessageTypeEnum.CUSTOMER_HAS_UNDELIVERED_ORDER,
  SmsMessageTypeEnum.CUSTOMER_BALANCE,
  SmsMessageTypeEnum.BILLING_LINK
]

export const SmsMessageTypeEnumForOrderValues = [
  SmsMessageTypeEnum.ORDER_CREATED,
  SmsMessageTypeEnum.ORDER_PICKUP,
  SmsMessageTypeEnum.SEND_ORDER_INFO_TO_SERVICE_CAR,
  SmsMessageTypeEnum.ORDER_READY,
  SmsMessageTypeEnum.ORDER_READY_FOR_DELIVERY,
  SmsMessageTypeEnum.ORDER_READY_REMINDER,
  SmsMessageTypeEnum.ORDER_IN_SERVICE,
  SmsMessageTypeEnum.ORDER_INCOMPLETE_DELIVER,
  SmsMessageTypeEnum.ORDER_DELIVERED,
  SmsMessageTypeEnum.CUSTOMER_NOT_REACHED,
  SmsMessageTypeEnum.ORDER_RECEIPT_LINK,
  SmsMessageTypeEnum.ORDER_ONLINE_COLLECTION_LINK
]


export const SmsMessageTypeEnumForCustomerValues = [
  SmsMessageTypeEnum.CUSTOM,
  SmsMessageTypeEnum.CUSTOMER_CREATED,
  SmsMessageTypeEnum.DEBTOR_CUSTOMER,
  SmsMessageTypeEnum.CUSTOMER_HAS_UNDELIVERED_ORDER,
  SmsMessageTypeEnum.CUSTOMER_BALANCE,
  SmsMessageTypeEnum.CURRENT_ACCOUNT_BALANCE_REMIND,
  SmsMessageTypeEnum.LOYALTY_CARD_BALANCE_REMIND,
  SmsMessageTypeEnum.LOYALTY_CARD_DEBTED_REMIND
]
