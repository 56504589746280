import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateFormatEnum } from 'app/enum/date-format.enum';
import { WebImageUrlCategoryTypeEnumValues } from 'app/enum/type/web-image-url-category-type.enum';
import { HelpVideoUrlModel } from 'app/models/entity/help-video-url.model';
import { AlertifyService } from 'app/services/core/alertify.service';
import { HelpVideoUrlService } from 'app/services/core/help-video-url.service';

@Component({
  selector: 'app-help-video-add-update',
  templateUrl: './help-video-add-update.component.html',
  styleUrls: ['./help-video-add-update.component.css']
})
export class HelpVideoAddUpdateComponent implements OnInit {
  @Input() data: HelpVideoUrlModel;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  dateFormatEnum = DateFormatEnum;
  webImageUrlCategoryTypeEnumValues = WebImageUrlCategoryTypeEnumValues;

  constructor(
    private helpVideoUrlService: HelpVideoUrlService,
    private translate: TranslateService,
    private alertifyService: AlertifyService) { }

  ngOnInit(): void {
    if (this.data) {

    }
    else {
      this.data = new HelpVideoUrlModel();
    }
  }


  //güncelle veya yeni kayıt ekle
  save() {
    if (!this.validateSave())
      return;


    //Yeni kayıt ise
    if (!this.data.id) {
      this.add();
    }
    //Var olan kaydı güncelle
    else {
      this.update();
    }
  }

  //kontroller
  validateSave() {

    this.data.url = this.data.url.replace('https://youtu.be', 'https://www.youtube.com/embed');

    return true;
  }

  // yeni kayıt ekle
  add() {
    this.helpVideoUrlService.addVideoUrl(this.data).subscribe(
      (res) => {
        this.data = res;
        this.alertifyService.success(this.translate.instant('message.info.saved'));
        this.passEntry.emit(this.data);

      },
      (err) => {
        this.alertifyService.error(err);
      }
    );
  }

  // güncelle
  update() {
    this.helpVideoUrlService
      .updateVideoUrl(this.data.id, this.data)
      .subscribe(
        (res) => {
          this.data = res;
          this.alertifyService.success(this.translate.instant('message.info.updated'));
          this.passEntry.emit(this.data);
        },
        (err) => {
          this.alertifyService.error(err);
        }
      );
  }

  close() {
    this.passEntry.emit();
  }
}
