import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageConstants } from 'app/constant/storage.constant';
import { DevicePreferenceTypeEnum } from 'app/enum/type/device-preference-type.enum';
import { DevicePreference } from 'app/models/entity/devicePreference';
import { PrinterListResponse } from 'app/models/rest/setting/printerListResponse';
import { AuthService } from 'app/services/auth.service';
import { AlertifyService } from 'app/services/core/alertify.service';
import { PrinterService } from 'app/services/core/printer.service';
import { SessionStorageService } from 'app/services/session-storage.service';

import { DevicePreferenceService } from 'app/services/store/device-preference.service';
import { DeviceService } from 'app/services/store/device.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-printer-setting-modal',
  templateUrl: './printer-setting-modal.component.html',
  styleUrls: ['./printer-setting-modal.component.css']
})
export class PrinterSettingModalComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  deviceId;
  printers: PrinterListResponse[];
  receipt: PrinterListResponse;
  label: PrinterListResponse;
  additionalReceipt: PrinterListResponse;
  packageLabel: PrinterListResponse;
  billing: PrinterListResponse;
  devicePreferences: DevicePreference[];
  constructor(
    private sessionStorageService: SessionStorageService,
    private translate: TranslateService,
    private printerService: PrinterService,
    private alertifyService: AlertifyService,
    private authService: AuthService,
    private deviceService: DeviceService,
    private devicePreferenceService: DevicePreferenceService
  ) { }

  ngOnInit() {
    this.deviceId = this.authService.getCurrenDeviceId();
    this.getPrinters();
  }


  async getPrinters() {

    try {
      const res = await firstValueFrom(this.printerService.getPrinters());
      this.printers = res;
      this.setSelectedvalue();
    }
    catch (err) {

      this.alertifyService.error(err)
    }
  }



  getDevicePreferences() {
    this.devicePreferenceService.getDevicePreferences().subscribe(
      res => {
        this.devicePreferences = res;
        this.setSelectedvalue();
      },
      err => this.alertifyService.error(err)
    );
  }

  setSelectedvalue() {
    this.receipt = this.printers.filter(
      x => x.name == this.sessionStorageService.getString(StorageConstants.PRINTER_RECEIPT)
    )[0];

    this.label = this.printers.filter(
      x => x.name == this.sessionStorageService.getString(StorageConstants.PRINTER_LABEL)
    )[0];

    this.additionalReceipt = this.printers.filter(
      x => x.name == this.sessionStorageService.getString(StorageConstants.PRINTER_ADDITIONAL_RECEIPT)
    )[0];

    this.packageLabel = this.printers.filter(
      x => x.name == this.sessionStorageService.getString(StorageConstants.PRINTER_PACKAGE_LABEL)
    )[0];
    this.billing = this.printers.filter(
      x => x.name == this.sessionStorageService.getString(StorageConstants.PRINTER_BILLING)
    )[0];

  }

  async save() {
    if (this.receipt) {
      this.sessionStorageService.setString(StorageConstants.PRINTER_RECEIPT, this.receipt.name);
      await this.updateDevicePreference(DevicePreferenceTypeEnum.PRINTER_RECEIPT, this.receipt.name);
    }

    if (this.label) {
      this.sessionStorageService.setString(StorageConstants.PRINTER_LABEL, this.label.name);
      await this.updateDevicePreference(DevicePreferenceTypeEnum.PRINTER_LABEL, this.label.name);
    }

    if (this.additionalReceipt) {
      this.sessionStorageService.setString(StorageConstants.PRINTER_ADDITIONAL_RECEIPT, this.additionalReceipt.name);
      await this.updateDevicePreference(DevicePreferenceTypeEnum.PRINTER_ADDITIONAL_RECEIPT, this.additionalReceipt.name);
    }

    if (this.packageLabel) {
      this.sessionStorageService.setString(StorageConstants.PRINTER_PACKAGE_LABEL, this.packageLabel.name);
      await this.updateDevicePreference(DevicePreferenceTypeEnum.PRINTER_PACKAGE_LABEL, this.packageLabel.name);
    }
    if (this.billing) {
      this.sessionStorageService.setString(StorageConstants.PRINTER_BILLING, this.billing.name);
      await this.updateDevicePreference(DevicePreferenceTypeEnum.PRINTER_BILLING, this.billing.name);
    }
    this.alertifyService.success(this.translate.instant('message.info.updated'));

    this.passEntry.emit(true);
  }

  async updateDevicePreference(shortCode: string, value: string) {

    try {
      let devicePreference = new DevicePreference();
      devicePreference.deviceId = this.deviceId;
      devicePreference.shortCode = shortCode;
      devicePreference.value = value;
      const res = await this.deviceService.updateDevicePreference(this.deviceId, devicePreference).toPromise();
    }
    catch (err) {
      this.alertifyService.error(err);

    }

  }
  close() {
    this.passEntry.emit();
  }
}
