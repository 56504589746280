import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-order-delivery-date-select-modal',
  templateUrl: './order-delivery-date-select-modal.component.html',
  styleUrls: ['./order-delivery-date-select-modal.component.css']
})
export class OrderDeliveryDateSelectModalComponent implements OnInit {

  @Input() selectedDate: Date;
  @Input() isPickup: boolean = false;
  @Input() isDelivery: boolean = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  days: Date[] = [];
  hours: number[] = Array.from({ length: 15 }, (_, i) => 8 + i); // 8'den 22'ye saatler
  selectedHour: number;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    if (this.selectedDate == null)
      this.selectedDate = new Date();
    else
      this.selectedDate = new Date(this.selectedDate);

    this.selectedHour = this.selectedDate.getHours();

    for (let i = 0; i < 15; i++) {
      let date = new Date();
      date.setDate(date.getDate() + i);
      this.days.push(date);
    }
    console.log(this.days);
  }

  selectDate(date: Date): void {
    this.selectedDate = date;
  }

  isSelectedDate(date: Date): boolean {
    return date.toDateString() === this.selectedDate.toDateString();
  }

  selectHour(hour: number): void {
    this.selectedHour = hour;
    this.selectedDate.setHours(hour);
    this.selectedDate.setMinutes(0);
    this.selectedDate.setSeconds(0);
  }

  isSelectedHour(hour: number): boolean {
    return hour === this.selectedHour;
  }

  getDayName(value: Date) {
    let day = value.getDate();
    let dayName = this.translate.instant('primeng.dayNames.' + value.getDay() + '');
    let month = this.translate.instant('primeng.monthNames.' + value.getMonth() + '');
    return day + " " + month + " " + dayName;
  }


  close() {
    this.passEntry.emit();
  }

  save() {

    this.passEntry.emit({
      selectedDate: this.selectedDate,
      isPickup: this.isPickup,
      isDelivery: this.isDelivery
    });
  }

}
