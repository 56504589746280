import { camelCase } from "jquery";
import { DateRangeEnum } from "app/enum/date-range.enum";

export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function clone(c: any): any {
  if (c == null)
    return null;

  let data = {};
  for (let prop in c) {
    data[prop] = c[prop];
  }
  return data;
}

export function copy(aObject) {
  return JSON.parse(JSON.stringify(aObject));

  // if (!aObject) {
  //   return aObject;
  // }

  // let v;
  // let bObject = Array.isArray(aObject) ? [] : {};
  // for (const k in aObject) {
  //   v = aObject[k];
  //   bObject[k] = typeof v === "object" ? this.copy(v) : v;
  // }

  // return bObject;
}
export function stringIsNumber(str: string): boolean {
  if (typeof str !== 'string') {
    return false;
  }

  if (str.trim() === '') {
    return false;
  }

  return !Number.isNaN(Number(str));
}



export function isNullOrEmpty(val) {
  if (!val || val == undefined || val == null ||
    val.length == 0 || val == "") {
    return true;
  }
  return false;
}


export function isNotNull(val) {

  return !isNullOrEmpty(val);
}

export function isNullOrEmptyAllFields(val) {
  for (const key of Object.keys(val)) {
    const value = val[key];
    if (isNotNull(value)) {
      return false;
    }
  }
  return true;
}

export function getAppDateNow() {
  return new Date();
}

export function getStartEndDate(range: string) {
  // let city_time_diff = 2; //change according to your city

  // let time_now = Date.now();
  // time_now = time_now + (3600000 * city_time_diff); //Add our city time (in msec);
  // let new_date = new Date(time_now);
  // console.log("My city time is: ", new_date);

  let returnValue = {
    startDate: new Date(),
    endDate: new Date(),
    title: "",
    isDateActive: true
  };
  // console.log(returnValue);
  returnValue.title = range;
  switch (range) {
    case DateRangeEnum.TODAY:
      returnValue.startDate = getAppDateNow();
      returnValue.endDate = new Date();
      break;

    case DateRangeEnum.THIS_WEEK:
      returnValue.startDate = getAppDateNow().firstWeekDay();
      returnValue.endDate = getAppDateNow().lastWeekDay();
      break;

    case DateRangeEnum.THIS_MONTH:
      returnValue.startDate = getAppDateNow().firstMonthDay();
      returnValue.endDate = getAppDateNow().lastMonthDay();
      break;

    case DateRangeEnum.THIS_YEAR:
      returnValue.startDate = getAppDateNow().firstYearDay();
      returnValue.endDate = getAppDateNow().lastYearDay();
      break;

    case DateRangeEnum.YESTERDAY:
      returnValue.startDate = getAppDateNow().addDays(-1);
      returnValue.endDate = getAppDateNow().addDays(-1);
      break;

    case DateRangeEnum.LAST_WEEK:
      returnValue.startDate = getAppDateNow().addWeeks(-1).firstWeekDay();
      returnValue.endDate = getAppDateNow().addWeeks(-1).lastWeekDay();
      break;

    case DateRangeEnum.LAST_MONTH:
      returnValue.startDate = getAppDateNow().addMonths(-1).firstMonthDay();
      returnValue.endDate = getAppDateNow().addMonths(-1).lastMonthDay();
      break;

    case DateRangeEnum.LAST_YEAR:
      returnValue.startDate = getAppDateNow().addYears(-1).firstYearDay();
      returnValue.endDate = getAppDateNow().addYears(-1).lastYearDay();
      break;

    case DateRangeEnum.TOMORROW:
      returnValue.startDate = getAppDateNow().addDays(1);
      returnValue.endDate = getAppDateNow().addDays(1);
      break;

    case DateRangeEnum.NEXT_WEEK:
      returnValue.startDate = getAppDateNow().addWeeks(1).firstWeekDay();
      returnValue.endDate = getAppDateNow().addWeeks(1).lastWeekDay();
      break;

    case DateRangeEnum.NEXT_MONTH:
      returnValue.startDate = getAppDateNow().addMonths(1).firstMonthDay();
      returnValue.endDate = getAppDateNow().addMonths(1).lastMonthDay();
      break;

    case DateRangeEnum.NEXT_YEAR:
      returnValue.startDate = getAppDateNow().addYears(1).firstYearDay();
      returnValue.endDate = getAppDateNow().addYears(1).lastYearDay();
      break;


    case DateRangeEnum.FIRST_WEEK_OF_MONTH:
      returnValue.startDate = getAppDateNow().firstWeekDayOfMonth();
      returnValue.endDate = returnValue.startDate.lastWeekDay();
      break;
    case DateRangeEnum.SECOND_WEEK_OF_MONTH:
      returnValue.startDate = getAppDateNow().firstWeekDayOfMonth().addWeeks(1);
      returnValue.endDate = returnValue.startDate.lastWeekDay();
      break;
    case DateRangeEnum.THIRD_WEEK_OF_MONTH:
      returnValue.startDate = getAppDateNow().firstWeekDayOfMonth().addWeeks(2);
      returnValue.endDate = returnValue.startDate.lastWeekDay();
      break;
    case DateRangeEnum.FOURTH_WEEK_OF_MONTH:
      returnValue.startDate = getAppDateNow().firstWeekDayOfMonth().addWeeks(3);
      returnValue.endDate = returnValue.startDate.lastWeekDay();
      break;
    case DateRangeEnum.LAST7DAY:
      returnValue.startDate = getAppDateNow().addDays(-6);
      returnValue.endDate = getAppDateNow();
      break;

    case DateRangeEnum.LAST30DAY:
      returnValue.startDate = getAppDateNow().addDays(-29);
      returnValue.endDate = getAppDateNow();
      break;

    case DateRangeEnum.LAST90DAY:
      returnValue.startDate = getAppDateNow().addDays(-89);
      returnValue.endDate = getAppDateNow();
      break;
    case DateRangeEnum.ALL:
      returnValue.startDate = new Date(2000, 1, 1);
      returnValue.endDate = getAppDateNow().addYears(5).lastYearDay();
      returnValue.isDateActive = false;
      break;
    case DateRangeEnum.CUSTOM:
      returnValue.title = "";
      break;
  }
  returnValue.startDate.clearTime();
  returnValue.endDate.clearTime();

  return returnValue;
}

export function camelCaseObject(o) {
  let newO, origKey, value
  if (o instanceof Array) {
    newO = []
    for (origKey in o) {
      value = o[origKey]
      if (typeof value === 'object') {
        value = camelCaseObject(value)
      }
      newO.push(value)
    }
  } else {
    newO = {}
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newO[camelCase(origKey)] = o[origKey]
      }
    }
  }
  return newO
}

export function bin2string(array) {
  var result = "";
  for (var i = 0; i < array.length; ++i) {
    result += (String.fromCharCode(array[i]));
  }
  return result;
}

export function areSameDay(date1: Date, date2: Date): boolean {
  date1 = new Date(date1);
  date2 = new Date(date2);


  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();
}




