<div class="modal-header bg-secondary">
  <h4 class="modal-title text-white" id="modal-basic-title">{{ 'order.deliveryDate' | translate }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>

<div class="modal-body">
  <div class="card">
    <div class="card-body">

      <div class="mb-4"> <!-- Gün seçimi için başlık -->
        <h5>{{'title.select-day' | translate}}</h5>
        <div class="payment-type-buttons">
          <button type="button" *ngFor="let day of days" class="btn btn-sm payment-type-button" [ngClass]="{'btn-primary': isSelectedDate(day), 'btn-outline-secondary': !isSelectedDate(day)}"
            (click)="selectDate(day)">
            {{ getDayName(day)}}
          </button>
        </div>
      </div>

      <div> <!-- Saat seçimi için başlık -->
        <h5>{{'title.select-hour' | translate}}</h5>
        <div class="payment-type-buttons">
          <button type="button" *ngFor="let hour of hours" class="btn btn-sm payment-type-button" [ngClass]="{'btn-primary': isSelectedHour(hour), 'btn-outline-secondary': !isSelectedHour(hour)}"
            (click)="selectHour(hour)">
            {{ hour }}:00
          </button>
        </div>
      </div>
      <div class="row mt-2">

        <div class="col form-check form-switch">
          <input type="checkbox" class="form-check-input" id="isPickupInput2" name="isPickupInput2" [(ngModel)]="isPickup" checked="" />
          <label class="form-check-label" for="isPickupInput2">{{'order.isPickup' | translate}}</label>
        </div>


        <div class="col form-check form-switch">
          <input type="checkbox" class="form-check-input" id="isDeliveryInput2" name="isDeliveryInput2" [(ngModel)]="isDelivery" checked="" />
          <label class="form-check-label" for="isDeliveryInput2">{{'order.isDelivery' | translate}}</label>
        </div>

      </div>
    </div>

  </div>


</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">
    {{ 'button.close' | translate }}
  </button>
  <button type="button" class="btn btn-primary" ngbAutofocus (click)="save()">
    {{ 'button.save' | translate }}
  </button>
</div>