import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { StorageConstants } from 'app/constant/storage.constant';
import { environment } from 'environments/environment';
import { throwError as observableThrowError, Observable } from "rxjs";
import { SessionStorageService } from './session-storage.service';
export class BaseRestService {

  constructor(protected httpClient: HttpClient, protected sessionStorageService: SessionStorageService) {
  }

  protected httpGet<T>(url: string, excludeAuth?: boolean, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.get<any>(environment.apiUrl + url, { headers: excludeAuth ? this.headersWithoutAuthorization : this.headersWithAuthorization, params });
  }

  protected httpDelete<T>(url: string, excludeAuth?: boolean, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.delete<any>(environment.apiUrl + url, { headers: excludeAuth ? this.headersWithoutAuthorization : this.headersWithAuthorization, params });
  }

  protected httpPost<T>(url: string, data: any, excludeAuth?: boolean, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.post<any>(environment.apiUrl + url, data, { headers: excludeAuth ? this.headersWithoutAuthorization : this.headersWithAuthorization, params });
  }

  protected httpPut<T>(url: string, data: any, excludeAuth?: boolean, params: HttpParams = new HttpParams()): Observable<any> {

    return this.httpClient.put<any>(environment.apiUrl + url, data, { headers: excludeAuth ? this.headersWithoutAuthorization : this.headersWithAuthorization, params });
  }
  // PDF dosyaları için özel POST metodu
  protected httpPostForPdf(url: string, data: any, excludeAuth?: boolean, params: HttpParams = new HttpParams()): Observable<Blob> {
    // PDF dosyaları için özel ayarlar
    const options = {
      headers: excludeAuth ? this.headersWithoutAuthorization : this.headersWithAuthorization,
      params: params,
      responseType: 'blob' as 'json' // Angular'ın responseType'u blob olarak algılaması için bu şekilde ayarlanmalı
    };

    return this.httpClient.post<Blob>(environment.apiUrl + url, data, options);
  }

  errorHandler(error: HttpErrorResponse) {
    //
    // console.log(error);
    return observableThrowError(error);
  }


  get headersWithAuthorization(): HttpHeaders {
    let headers = new HttpHeaders();

    let languageCode = localStorage.getItem(StorageConstants.LANGUAGE_CODE);
    headers = headers.set('Authorization', `Bearer ${this.sessionStorageService.getString(StorageConstants.TOKEN_KEY)}`);
    headers = headers.set('x-owner-id', `Bearer ${this.sessionStorageService.getString(StorageConstants.OWNER_ID)}`);

    // headers = headers.set('Accept', '*/*');
    // headers = headers.set('Access-Control-Allow-Origin', '*');
    // headers = headers.set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, HEAD');
    headers = headers.set('Content-Type', 'application/json');
    // headers = headers.set('Access-Control-Allow-Headers', '*');
    headers = headers.set('x-app-version', environment.VERSION);
    headers = headers.set('x-app-platform', 'WEB');

    if (languageCode != null)
      headers = headers.set('Accept-Language', languageCode);
    // headers = headers.set('timeout', '100000');

    return headers;
  }
  get headersWithoutAuthorization(): HttpHeaders {
    let headers = new HttpHeaders();
    let languageCode = localStorage.getItem(StorageConstants.LANGUAGE_CODE);

    // headers = headers.set('Accept', '*/*');
    // headers = headers.set('Access-Control-Allow-Origin', '*');
    // headers = headers.set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, HEAD');
    headers = headers.set('Content-Type', 'application/json');
    // headers = headers.set('Access-Control-Allow-Headers', '*');
    headers = headers.set('x-app-version', environment.VERSION);

    headers = headers.set('x-app-platform', 'WEB');
    if (languageCode != null)
      headers = headers.set('Accept-Language', languageCode);
    // headers = headers.set('timeout', '100000');

    return headers;
  }
}


