import { Injectable } from "@angular/core";
import { throwError as observableThrowError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { PrinterListResponse } from "../../models/rest/setting/printerListResponse";
import { PrinterSendRequest } from "../../models/rest/setting/printerSendRequest";
import { VoipQueueStatusRequest } from "../../models/rest/voip/voip_queue_status_request.model";
import { VoipQueueStatusResponse } from "../../models/rest/voip/voip_queue_status_response.model";
import { VoipSocketStartRequest } from "../../models/rest/voip/voip_socket_request.model";
import { PrinterSendResponse } from "../../models/rest/setting/printerSendResponse";

import { environment } from "environments/environment";
import { DeviceInfoResponseModel } from "app/models/rest/device-info-response.model";
import { ReceiptLabelResponse } from "app/models/rest/setting/receiptLabelResponse";
import { CallerIdLogCreateRequestModel } from "app/models/rest/voip/caller-id-log-create-request.model";
import { ExecuetAppRequest } from "app/models/rest/app/execute-app-request";
import { ExecuetAppResponse } from "app/models/rest/app/execute-app-response";
import { AppVersionResponse } from "app/models/rest/app/app-version-response";


@Injectable({
  providedIn: "root"
})
export class PrinterService {
  constructor(private http: HttpClient) {
    // this.printService.initQZ();
  }

  printHtmlWithPopup(data) {
    var height = 700;
    var width = 700;
    var leftPosition, topPosition;
    //Allow for borders.
    leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
    //Allow for title and status bars.
    topPosition = (window.screen.height / 2) - ((height / 2) + 50);
    let popupWindow = window.open('', '_blank', "status=no,height=" + height + ",width=" + width + ",resizable=yes,left="
      + leftPosition + ",top=" + topPosition + ",screenX=" + leftPosition + ",screenY="
      + topPosition + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
    popupWindow.document.open();
    popupWindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' +
      data + '</html>');
    popupWindow.document.close();


    const closePopupWindow = () => {
      popupWindow.close();
    };

    // Popup penceresi kapatıldığında veya yazdırma tamamlandığında çağrılacak işlevleri belirleyin
    popupWindow.onafterprint = closePopupWindow;
    popupWindow.onbeforeunload = closePopupWindow;
  }

  getPrinters(): Observable<any[]> {
    // return this.printService.getPrinters();

    const url = `${environment.printerUrl}/printerList`;

    return this.http
      .get<PrinterListResponse[]>(url, { headers: this.headersWithoutAuthorization })
      .pipe(catchError(this.errorHandler));
  }


  getPrinterServiceIsAlive(): Observable<any> {
    const url = `${environment.printerUrl}/v1/printer/is-alive`;
    return this.http
      .get(url, { headers: this.headersWithoutAuthorization })
      .pipe(catchError(this.errorHandler));
  }

  printPdfBlob(data: Blob, printerName: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const uintArray = new Uint8Array(arrayBuffer);
        const numberArray = Array.from(uintArray);
        // console.log(numberArray); // numberArray, BLOB verisini içeren sayı dizisi olacak

        let request = new PrinterSendRequest();
        request.byteData = numberArray;
        request.printType = "PDF";
        // request.height = res.height;
        request.count = 1;
        request.printerName = printerName;
        request.layout = "PORTRAIT";
        this.sendPrinter(request).subscribe(
          (data) => {
            if (data.statusCode == "SUCCESS")
              resolve(true);
            else
              reject(data.statusExplanation);
          },
          (err) => {
            reject(err);
          }
        );
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(blob);
    });
  }

  printPdfBlob2(data: Blob, printerName: string) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const reader = new FileReader();
    reader.onload = () => {
      const arrayBuffer = reader.result as ArrayBuffer;
      const uintArray = new Uint8Array(arrayBuffer);
      const numberArray = Array.from(uintArray);
      // console.log(numberArray); // numberArray, BLOB verisini içeren sayı dizisi olacak


      let request = new PrinterSendRequest();
      request.byteData = numberArray;
      request.printType = "PDF";
      // request.height = res.height;
      request.count = 1;
      request.printerName = printerName;
      request.layout = "PORTRAIT";
      this.sendPrinter(request).subscribe(
        (data) => {
          if (data.statusCode == "SUCCESS")
            return true
          else
            throw data.statusExplanation;
        },
        (err) => {
          throw (err);
        }
      );

    };
    reader.readAsArrayBuffer(blob);
  }

  sendPrinter(request: PrinterSendRequest): Observable<PrinterSendResponse> {
    const url = `${environment.printerUrl}/send`;
    // console.log(request.data);
    return this.http.post<PrinterSendResponse>(url, request, { headers: this.headersWithoutAuthorization }).pipe(catchError(this.errorHandler));
  }

  convertPrinterSendRequest(input: ReceiptLabelResponse): PrinterSendRequest {
    let request = new PrinterSendRequest();
    request.data = input.data;
    request.byteData = input.byteData;
    request.printType = input.type;
    request.width = input.width;
    request.height = input.height;
    request.count = 1;
    request.layout = "PORTRAIT";
    return request;

  }

  openCashRegister(printerName: string) {
    let command = String.fromCharCode(27) + String.fromCharCode(112) + String.fromCharCode(0) + String.fromCharCode(25) + String.fromCharCode(250);
    var byteData = [];
    for (var i = 0; i < command.length; i++) {
      byteData.push(command.charCodeAt(i));
    }
    let request = new PrinterSendRequest();
    request.printerName = printerName;
    request.byteData = byteData;
    request.printType = "ESCPOS";
    this.sendPrinter(request);
  }
  getIncomingCall(): Observable<CallerIdLogCreateRequestModel> {
    const url = `${environment.printerUrl}/v1/voip/last-call`;
    return this.http
      .get<CallerIdLogCreateRequestModel>(url, { headers: this.headersWithoutAuthorization })
      .pipe(catchError(this.errorHandler));

  }

  getVoipQueueStatus(request: VoipQueueStatusRequest): Observable<VoipQueueStatusResponse> {
    const url = `${environment.printerUrl}/v1/voip/queue-status`;
    return this.http
      .post<VoipQueueStatusResponse>(url, request, { headers: this.headersWithoutAuthorization })
      .pipe(catchError(this.errorHandler));

  }


  startNetsgmSocket(request: VoipSocketStartRequest) {
    const url = `${environment.printerUrl}/v1/netgsm/start-socket`;
    return this.http
      .post(url, request, { headers: this.headersWithoutAuthorization })
      .pipe(catchError(this.errorHandler));

  }

  getComputerIdentifier(): Observable<DeviceInfoResponseModel> {
    const url = `${environment.printerUrl}/v1/app/device-code`;
    return this.http
      .get<DeviceInfoResponseModel>(url, { headers: this.headersWithoutAuthorization })
      .pipe(catchError(this.errorHandler));

  }
  getAppVersion(): Observable<AppVersionResponse> {
    const url = `${environment.printerUrl}/v1/app/version`;
    return this.http
      .get<AppVersionResponse>(url, { headers: this.headersWithoutAuthorization })
      .pipe(catchError(this.errorHandler));

  }

  executeApp(request: ExecuetAppRequest): Observable<ExecuetAppResponse> {
    const url = `${environment.printerUrl}/v1/download/execute`;
    return this.http
      .post(url, request, { headers: this.headersWithoutAuthorization })
      .pipe(catchError(this.errorHandler));

  }

  errorHandler(error) {

    console.log(error);
    if (error == "Http failure response for http://localhost:8083/printerList: 0 Unknown Error")
      return observableThrowError("Lokal yazıcı servislerine erişim yok");
    else
      return observableThrowError(error);

  }

  get headersWithoutAuthorization(): HttpHeaders {
    let headers = new HttpHeaders();

    // headers = headers.set('Accept', '*/*');
    headers = headers.set('Access-Control-Allow-Origin', '*');
    // headers = headers.set('Access-Control-Request-Private-Network', 'false');
    headers = headers.set('Content-Type', 'application/json');
    // headers = headers.set('Referer', 'http://localhost:8083');
    headers = headers.set('Accept-Language', 'tr');

    // headers = headers.set('timeout', '100000');

    return headers;
  }
}
