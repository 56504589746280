import { Injectable } from '@angular/core';
import { isNullOrEmpty } from 'app/helper/utils';
import { NgxPermissionsService } from 'ngx-permissions';
import { AlertifyService } from './core/alertify.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSizeEnum } from 'app/enum/modal-size.enum';
import { RoleCodeEnum } from 'app/enum/role-code.enum';
import { UserCheckPermissionModalComponent } from 'app/pages/user/user-check-permission-modal/user-check-permission-modal.component';
import { StorageConstantsForDatabaseValues } from 'app/constant/storage.constant';
import { UserSessionStorageMergeRequest } from 'app/models/rest/user/user-session-storage-merge-request';
import { UserSessionStorageService } from './user/user-session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserOperationalService {

  constructor(

    private userSessionStorageService: UserSessionStorageService,
    private translate: TranslateService,
    private alertifyService: AlertifyService,
    private permissionsService: NgxPermissionsService,
    private modalService: NgbModal) { }

  checkUserPermissionByRoleCode(roleCode: string) {
    if (isNullOrEmpty(roleCode))
      return true;

    let status = this.permissionsService.getPermission(roleCode);
    if (!status) {

      this.alertifyService.warning(this.translate.instant('message.error.you-are-not-authorized-if-authorized-exit-the-program-and-log-in-again').replace('{PERMISSION_NAME}',
        this.translate.instant('roleCodeValue.' + roleCode)));
      return false;
    }
    else
      return true;

  }

  hasPermissionByRoleCode(roleCode: string) {
    if (isNullOrEmpty(roleCode))
      return true;

    let status = this.permissionsService.getPermission(roleCode);
    if (!status) {
      return false;
    }
    else
      return true;
  }

  hasPermissionAnyByRoleCodes(roleCodes: string[]) {
    if (roleCodes == undefined || roleCodes.length == 0)
      return true;

    for (let roleCode of roleCodes) {
      let status = this.permissionsService.getPermission(roleCode);
      if (status)
        return true;
    }

    return false;
  }

  isAdminUser() {
    return this.hasPermissionByRoleCode(RoleCodeEnum.ADMINISTRATOR_USER);
  }

  isDeveloperUser() {
    return this.hasPermissionByRoleCode(RoleCodeEnum.DEVELOPER);
  }


  checkUserPermissionByRoleCodes(roleCodes: string[]) {
    if (roleCodes.length == 0)
      return true;

    roleCodes.forEach((x) => {
      let status = this.checkUserPermissionByRoleCode(x);
      if (!status)
        return false;
    });

    return true;
  }

  async openAuthenticatedUserModal(roleCode: string, relatedStoreId: number, allowedType: string): Promise<boolean> {
    return new Promise((resolve) => {
      const modalRef = this.modalService.open(UserCheckPermissionModalComponent, {
        backdrop: 'static', centered: true, scrollable: true, size: ModalSizeEnum.MEDIUM, keyboard: true
      });
      modalRef.componentInstance.roleCode = roleCode;
      modalRef.componentInstance.relatedStoreId = relatedStoreId;
      modalRef.componentInstance.allowedType = allowedType;
      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        modalRef.close();
        resolve(receivedEntry);
      });
    });
  }

  async checkAndOpenModalIfNecessary(roleCode: string, relatedStoreId: number, allowedType: string): Promise<boolean> {
    // Öncelikle kullanıcının ilgili rol kodu için yetkisi olup olmadığını kontrol edin.
    const hasPermission = this.hasPermissionByRoleCode(roleCode);

    // Eğer kullanıcı yetkiliyse, doğrudan true dönün.
    if (hasPermission) {
      return true;
    }

    this.alertifyService.error(this.translate.instant('message.error.you-are-not-authorized-if-authorized-exit-the-program-and-log-in-again').replace('{PERMISSION_NAME}',
      this.translate.instant('roleCodeValue.' + roleCode)));
    // Yetkisi yoksa, yetki alma modalını açın ve kullanıcıdan gelen yanıtı bekleyin.
    return await this.openAuthenticatedUserModal(roleCode, relatedStoreId, allowedType);
  }

  //Kullanıcının yetki talep etmesi için açılan modal
  // async openRequestPermissionModal(roleCode: string): Promise<boolean> {
  //   return new Promise((resolve) => {
  //     const modalRef = this.modalService.open(UserRequestPermissionModalComponent, {
  //       backdrop: 'static', centered: true, scrollable: true, size: ModalSizeEnum.MEDIUM, keyboard: true
  //     });
  //     modalRef.componentInstance.roleCode = roleCode;
  //     modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
  //       modalRef.close();
  //       resolve(receivedEntry);
  //     });
  //   });
  // }


  async setLocalStorageToDatabase() {
    let dataList: UserSessionStorageMergeRequest[] = [];
    // LocalStorage'daki tüm anahtarları al
    for (let i = 0; i < sessionStorage.length; i++) {
      // Anahtarı al
      const key = sessionStorage.key(i);
      if (key !== null) {

        for (const prefix of StorageConstantsForDatabaseValues) {
          if (key.startsWith(prefix)) {
            const value = sessionStorage.getItem(key);
            let req = new UserSessionStorageMergeRequest();
            req.key = key;
            req.value = value;

            dataList.push(req);
            break; // Belirtilen anahtarın eşleştiği anda döngüden çık
          }
        }
      }
    }
    if (dataList.length > 0) {
      await this.userSessionStorageService.mergeUserSessionStorageBulk(dataList).toPromise();
    }
  }
}
