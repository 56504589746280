import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceTypeEnum } from 'app/enum/type/device-type.enum';
import { StorageConstants } from 'app/constant/storage.constant';
import { User } from 'app/models/entity/user';
import { UserLoginRequest } from 'app/models/rest/user/userLoginRequest';
import { AuthService } from 'app/services/auth.service';
import { AlertifyService } from 'app/services/core/alertify.service';
import { UserService } from 'app/services/user/user.service';
import { SessionStorageService } from 'app/services/session-storage.service';


@Component({
  selector: 'app-lock-screen',
  templateUrl: './lock-screen.component.html',
  styleUrls: ['./lock-screen.component.css']
})
export class LockScreenComponent implements OnInit {
  public capslockOn: boolean = false;

  @HostListener('window:click', ['$event']) onClick(event) {
    if (event.getModifierState && event.getModifierState('CapsLock')) {
      this.capslockOn = true;
    } else {
      this.capslockOn = false;
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {
    if (event.getModifierState && event.getModifierState('CapsLock')) {
      this.capslockOn = true;
    } else {
      this.capslockOn = false;
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event) {
    if (event.getModifierState && event.getModifierState('CapsLock')) {
      this.capslockOn = true;
    } else {
      this.capslockOn = false;
    }
  }
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  users: User[];
  selectedUser: User;
  password: string;
  loading: boolean = false;
  errorCount: number = 0;
  constructor(
    private sessionStorageService: SessionStorageService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private alertifyService: AlertifyService) { }

  ngOnInit(): void {
    this.sessionStorageService.setString(StorageConstants.IS_IDDLE, "1");
    this.getData();
  }

  getData() {
    this.userService.getUsers().subscribe(
      (res) => {
        this.users = res;
        this.users = this.users.filter(x => x.storeId == this.authService.getStoreId());
        this.users = this.users.sortBy("screenName");
        this.selectedUser = this.users.firstOrDefault(x => x.id == this.authService.getCurrentUserId());
      },
      (err) => {
        this.alertifyService.error(err);

      }
    );
  }

  close() {
    this.passEntry.emit();
  }

  checkPin() {
    this.passEntry.emit(true);
  }

  loginUser() {
    if (this.errorCount > 2) {
      this.passEntry.emit();
    }

    if (this.selectedUser.id == this.authService.getCurrentUserId()) {

      this.checkUserPassword();
    }
    else {
      this.loginNewUser();
    }
  }

  checkUserPassword() {
    this.loading = true;
    this.userService.checkPassword(this.selectedUser.userName, this.password).subscribe(
      (data) => {
        this.loading = false;
        this.passEntry.emit(true);
      },
      (err) => {
        this.loading = false;
        this.errorCount++;
        this.alertifyService.error(err);
      }
    );
  }

  loginNewUser() {
    this.loading = true;
    let userLoginRequest = new UserLoginRequest();
    userLoginRequest.userName = this.selectedUser.userName;
    userLoginRequest.password = this.password;
    userLoginRequest.deviceCode = this.authService.getCurrenDeviceCode();
    userLoginRequest.deviceType = DeviceTypeEnum.WEB;

    this.userService.login(userLoginRequest).subscribe(
      (data) => {
        this.loading = false;
        this.authService.saveToken(data.token);
        this.authService.saveRefreshToken(data.refreshToken);
        this.authService.startRefreshTokenTimer();
        this.getSessionInfo();

      },
      (err) => {

        this.loading = false;

        if (err != "OK") this.alertifyService.error(err);
        else
          this.alertifyService.error(
            "İnternet erişimi yok veya sistemsel bir problem mevcut"
          );

        this.passEntry.emit();
      }
    );
  }

  getSessionInfo() {
    this.userService.getUserSessionInfo().subscribe(
      async (data) => {
        this.authService.saveAfterLogin(data);
        window.location.reload();
      },
      (err) => {

        this.alertifyService.error(err);
      }
    );
  }

}
