export enum DateFormatEnum {

  DATE_FORMAT = 'DD.MM.YYYY',
  DATE_FORMAT_DISPLAY = 'dd.MM.yyyy',
  DATE_PICKER_INPUT_FORMAT = 'dd-mm-yy',
  DATE_PICKER_LOCALE = 'tr-TR',
  DATE_FORMAT_EXCEL = 'DD.MM.YYYY',

  DATE_TIME_PICKER_INPUT_FORMAT = 'dd-mm-yy',
  DATE_TIME_FORMAT_EXCEL = 'DD.MM.YYYY HH:mm:ss',
  DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss',
  DATE_TIME_FORMAT_DISPLAY = 'dd.MM.yyyy HH:mm:ss',
  DATE_TIME_FORMAT_DISPLAY_WITHOUT_SECOND = 'dd.MM.yyyy HH:mm',

  // DATE_TIME_FORMAT_DISPLAY_WITH_NAME = 'ddd.MMM.yyyy HH:mm:ss',

  DATE_TIME_SERILIAZE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ',
  DATE_SERILIAZE_FORMAT = 'YYYY-MM-DD',


  MONTH_WITH_YEAR = 'MM-yyyy',

}
