import { Injectable } from '@angular/core';
import { CallerIdLogCreateRequestModel } from 'app/models/rest/voip/caller-id-log-create-request.model';
import { VoipQueueStatusRequest } from 'app/models/rest/voip/voip_queue_status_request.model';
import { BehaviorSubject, interval } from 'rxjs';
import { AuthService } from '../auth.service';
import { AlertifyService } from '../core/alertify.service';
import { PrinterService } from '../core/printer.service';
import { DeviceVoipInternalResponse } from "app/models/rest/voip/device-voip-internal-response.model";
import { VoipVendorOperationalService } from './entegrator/voip-vendor-operational.service';
import { DeviceService } from '../store/device.service';
import { CallerIdLogService } from './callerid-log.service';
import { RouteEnum } from 'app/enum/route.enum';
import { VoipVendorEnum } from 'app/enum/voip/voip-vendor.enum';
import { VoipSocketStartRequest } from 'app/models/rest/voip/voip_socket_request.model';
import { LogTypeEnum } from 'app/enum/log-type.enum';
import { DeviceLocalStorageData } from 'app/models/rest/user/device-local-storage-data.model';
import { CallerIdLogCreateResponseModel } from 'app/models/rest/voip/caller-id-log-create-response.model';
import { VoipQueueStatusResponse } from 'app/models/rest/voip/voip_queue_status_response.model';
import { delay, isNotNull, isNullOrEmpty } from 'app/helper/utils';
import { SoundService } from '../core/sound.service';
import { IncomingCallDisplayModalComponent } from 'app/pages/voip/incoming-call-display-modal/incoming-call-display-modal.component';
import { ModalSizeEnum } from 'app/enum/modal-size.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionStorageService } from '../session-storage.service';



@Injectable({
  providedIn: 'root'
})
export class VoipOperationalService {

  public callerIdLogCreateRequestModel: BehaviorSubject<CallerIdLogCreateRequestModel> = new BehaviorSubject(null);

  lastStartStopDate: Date;
  lastIncomingCall: string;
  lastIncomingCallDate: Date;
  intervalCallerId;
  intervalVoip;
  voipQueueStatusRequest: VoipQueueStatusRequest = new VoipQueueStatusRequest();
  deviceVoipInternalResponse: DeviceVoipInternalResponse;
  deviceLocalStorageData: DeviceLocalStorageData;

  timerMinutely = interval(300000); //Refreshes every 5 minutes

  private currentModalRef: any = null;


  constructor(private authService: AuthService,
    private sessionStorageService: SessionStorageService,
    private alertifyService: AlertifyService,
    private printerService: PrinterService,
    private voipVendorOperationalService: VoipVendorOperationalService,
    private deviceService: DeviceService,
    private callerIdLogService: CallerIdLogService,
    private modalService: NgbModal,
  ) {
    this.authService.currentUser.subscribe(async (res) => {

      if (res) {
        await delay(5000); // 2 saniye bekler
        this.checkTimers();
      }
      else {
        this.startStopCallerIdTimers();
        this.lastStartStopDate = null;
      }
    });
    this.getLastCallSubscribe();
    this.timerMinutely.subscribe(() => {
      this.sessionStorageService.addLocalLog(LogTypeEnum.INFO, "timerMinutely");
      this.checkTimers();
    });

  }

  checkTimers() {
    //son çalışma süresi 30 sn ye geçti ise tekrar başlat
    if (this.lastStartStopDate != null && this.lastStartStopDate.addSeconds(30) > new Date()) {
      return;
    }

    this.sessionStorageService.addLocalLog(LogTypeEnum.INFO, "checkTimers");
    this.deviceLocalStorageData = this.sessionStorageService.getDeviceStorageData();

    this.lastStartStopDate = new Date();
    this.startStopCallerIdTimers();
    this.startStopVoipTimers();
  }

  //caller id veya netgsm için 2 sn de bir çalışır
  startStopCallerIdTimers() {
    this.deviceLocalStorageData = this.sessionStorageService.getDeviceStorageData();

    if (!this.authService.isLoggedIn) {
      if (this.intervalCallerId) {
        clearInterval(this.intervalCallerId);
        this.intervalCallerId = null; // Değeri sıfırlayın
      }
    } else {
      if (this.deviceLocalStorageData.isCallerIdActive || (this.deviceLocalStorageData.isVoipActive && this.deviceVoipInternalResponse?.voipVendorId == VoipVendorEnum.NETGSM)) {
        if (this.intervalCallerId == null) {
          this.startTimerCallerId();
          this.alertifyService.success('Callerid sistemi aktif');
        } else {
          clearInterval(this.intervalCallerId);
          this.intervalCallerId = null; // Değeri sıfırlayın
          this.startTimerCallerId();
        }
      }
    }
  }

  //santral için 2 sn de bir deneyen job
  startStopVoipTimers() {
    this.deviceLocalStorageData = this.sessionStorageService.getDeviceStorageData();
    if (!this.authService.isLoggedIn) {
      if (this.intervalVoip) {
        clearInterval(this.intervalVoip);
        this.intervalVoip = null; // Değeri sıfırlayın
      }
    } else {
      if (this.deviceLocalStorageData.isVoipActive) {
        if (this.deviceVoipInternalResponse == null) {
          this.getCurrentDeviceVoipInternal();
        } else {
          if (this.intervalVoip == null) {
            this.startVoipByVendor();
          } else {
            clearInterval(this.intervalVoip);
            this.intervalVoip = null; // Değeri sıfırlayın
            this.startVoipByVendor();
          }
        }
      }
    }
  }


  startTimerCallerId() {
    this.sessionStorageService.addLocalLog(LogTypeEnum.INFO, "startTimerCallerId");
    this.intervalCallerId = setInterval(() => {
      if (!this.authService.isLoggedIn)
        return;
      this.getLastCallerIdCall();
    }, 2000)
  }

  getLastCallerIdCall() {
    this.printerService.getIncomingCall().subscribe(
      (res) => {
        if (res) {
          console.log(res);
          this.callerIdLogCreateRequestModel.next(res);
        }

      },
      (err) => {
        this.alertifyService.error(err);
        clearInterval(this.intervalCallerId);
      }
    )
  }

  startTimerVoip() {
    console.log("startTimerVoip");
    this.alertifyService.success("Santral sistemi aktif");
    this.sessionStorageService.addLocalLog(LogTypeEnum.INFO, "startTimerVoip");
    this.intervalVoip = setInterval(() => {
      if (!this.authService.isLoggedIn) {
        return;
      }
      this.getQueueStatus();
    }, 2000)
  }

  getQueueStatus() {

    if (this.deviceVoipInternalResponse == null) {
      clearInterval(this.intervalVoip);
    }
    this.voipQueueStatusRequest.url = this.deviceVoipInternalResponse.queueStatusUrl;
    this.voipQueueStatusRequest.voipVendorId = this.deviceVoipInternalResponse.voipVendorId;

    let internalNoList = this.deviceVoipInternalResponse.internalNo.split(",");

    this.printerService.getVoipQueueStatus(this.voipQueueStatusRequest).subscribe(
      (res) => {
        try {
          res.internalNoList = internalNoList;
          let incomingCallDetail: VoipQueueStatusResponse = this.voipVendorOperationalService.parseData(res);
          if (incomingCallDetail == null) {
            this.lastIncomingCall = null;
            this.lastIncomingCallDate = null;
            return;
          }
          if (isNullOrEmpty(incomingCallDetail.phoneNumber))
            return;

          if (this.lastIncomingCall == incomingCallDetail.phoneNumber &&
            incomingCallDetail.creationDate != null &&
            incomingCallDetail.creationDate.addSeconds(120) > new Date()
          ) {
            return;
          }

          this.lastIncomingCall = incomingCallDetail.phoneNumber;
          this.lastIncomingCallDate = new Date();

          let request: CallerIdLogCreateRequestModel = new CallerIdLogCreateRequestModel();

          request.channelType = "VOIP";
          request.isIncomingCall = true;
          request.internalNo = incomingCallDetail.internalNo;
          request.phoneNumber = incomingCallDetail.phoneNumber;

          this.callerIdLogCreateRequestModel.next(request);

        }
        catch (e) {
          this.alertifyService.error("Santral Hatası" + e);
        }
      },
      (err) => {
        this.alertifyService.error(err);
        clearInterval(this.intervalVoip);
      }
    )
  }

  getCurrentDeviceVoipInternal() {
    this.deviceService.getCurrentDeviceVoipInternal().subscribe(
      (res) => {
        this.deviceVoipInternalResponse = res;
        if (this.deviceVoipInternalResponse != null && this.deviceLocalStorageData.voipInternalId > 0) {
          this.startVoipByVendor();
        }

      },
      (err) => {
        this.alertifyService.error(err);
        clearInterval(this.intervalVoip);
      }
    )
  }

  startVoipByVendor() {
    if (isNotNull(this.deviceVoipInternalResponse.queueStatusUrl)) {
      if (this.deviceVoipInternalResponse.voipVendorId == VoipVendorEnum.NETGSM)
        this.startNetgsmSocket();
      else
        this.startTimerVoip();
    }

  }

  public getIncomingCall = () => {
    return this.callerIdLogCreateRequestModel.asObservable();
  }

  public removeLastIncomingCall() {
    this.callerIdLogCreateRequestModel.next(null);
  }


  getLastCallSubscribe() {
    this.getIncomingCall().subscribe(
      (res) => {
        if (res) {
          this.findCustomerByPhone(res);
          this.removeLastIncomingCall();
        }

      },
      (err) => {
        this.alertifyService.error(err);
      }
    )
  }

  findCustomerByPhone(request: CallerIdLogCreateRequestModel) {

    this.callerIdLogService
      .createCallerIdLog(request)
      .subscribe(
        (res) => {
          let callerIdLogResponse: CallerIdLogCreateResponseModel = res;

          this.openIncomingCallModal(callerIdLogResponse);
          // let url = window.location.origin;
          // if (callerIdLogResponse.customerId > 0) {
          //   this.alertifyService.callerIdNotifier(
          //     `<a href='${url}/#/${RouteEnum.CUSTOMER_DETAIL}/${callerIdLogResponse.customerId}/'>${callerIdLogResponse.html}</a>`, callerIdLogResponse.isBlackList);
          // }
          // else {
          //   this.alertifyService.callerIdNotifier(
          //     `<a href='${url}/#/${RouteEnum.CALLERID_HISTORY_REPORT}/'>${callerIdLogResponse.html}</a>`, callerIdLogResponse.isBlackList);
          // }
        },
        (err) => {
          this.alertifyService.callerIdNotifier(err, true);
        }
      );
  }

  startNetgsmSocket() {
    let request: VoipSocketStartRequest = new VoipSocketStartRequest();

    request.url = "crmsntrl.netgsm.com.tr";
    request.port = 9110;
    request.userName = this.deviceVoipInternalResponse.userName;
    request.password = this.deviceVoipInternalResponse.password;
    request.voipVendorId = this.deviceVoipInternalResponse.voipVendorId;
    if (isNotNull(this.deviceVoipInternalResponse.internalNo))
      request.internalNoList = this.deviceVoipInternalResponse.internalNo.split(",");
    request.crmId = (new Date).getTime().toString();


    this.printerService.startNetsgmSocket(request).subscribe(
      (res) => {
        //caller id netgsm için de aktif olduğundan tekrar aktif edilmesin
        if (this.intervalCallerId == null)
          this.startTimerCallerId();
        this.alertifyService.success("NETGSM Santral aktif");
      },
      (err) => {
        this.alertifyService.error("NETGSM Santral:" + err);
      }
    )

  }


  openIncomingCallModal(data) {
    if (!this.authService.isLoggedIn) {
      // Modal zaten açık, yeni bir modal açmayı engelle
      return;
    }
    // Eğer bir modal zaten açıksa, onu kapat
    if (this.currentModalRef) {
      this.currentModalRef.dismiss('calling again');
      this.currentModalRef = null;
    }

    this.currentModalRef = this.modalService.open(IncomingCallDisplayModalComponent, {
      backdrop: true, centered: true, scrollable: true, size: ModalSizeEnum.LARGE
    });

    //modal  veri geçişi
    this.currentModalRef.componentInstance.data = data;
    //modal cevap döndüğünde
    this.currentModalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if (receivedEntry) {
      }

      // modal kapat
      this.currentModalRef.close();

    });


  }

}
