import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-order-status-badge',
  templateUrl: './order-status-badge.component.html',
  styleUrls: ['./order-status-badge.component.css']
})
export class OrderStatusBadgeComponent implements OnInit {

  @Input() id:number;
  constructor() { }

  ngOnInit() {
  }

}
