import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bonus-balance-badge',
  templateUrl: './bonus-balance-badge.component.html',
  styleUrls: ['./bonus-balance-badge.component.css']
})
export class BonusBalanceBadgeComponent implements OnInit {
  @Input() balance: number;
  @Input() showLabel: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

}
