<div class="modal-header  bg-primary">
  <h4 class="modal-title text-white" id="modal-basic-title">{{ 'title.user-authority-control' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>




<div class="modal-body" *ngIf="hasUsers">
  <div class="card">
    <div class="card-body">
      <form #inputForm="ngForm" class="needs-validation" novalidate>
        <!-- Kullanıcı Seçimi -->
        <div class="form-group">
          <label class="custom-form-label" for="userSelect">{{ 'user.userName' | translate }}</label>
          <select id="userSelect" name="user" [(ngModel)]="selectedUserId" class="form-control" required>
            <option [ngValue]="0">{{ 'dropdown.select' | translate }}</option>
            <option *ngFor="let user of users" [ngValue]="user.id">{{ user.screenName }}</option>
          </select>
        </div>

        <!-- Şifre Girişi -->
        <div class="form-group">
          <label class="custom-form-label" for="passwordInput">{{ 'authentication.password' | translate }}</label>
          <input id="passwordInput" type="password" [(ngModel)]="password" name="password" class="form-control" required autocomplete="off" ngbAutoFocus />
        </div>
      </form>
    </div>
  </div>

  <h6 class="text-center text-danger font-weight-bold">{{ 'roleCodeValue.' + roleCode | translate }}</h6>

  <div class="alert alert-info" role="alert">
    {{ 'authentication.roleSearchInstruction' | translate }}
  </div>

  <div class="alert alert-success" role="alert">
    {{ 'authentication.permanentRoleRequest' | translate }}
  </div>
</div>


<div class="modal-body" *ngIf="!hasUsers">
  <div class="alert alert-warning" role="alert">
    {{ 'authentication.there-is-no-authorized-user' | translate }}
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">
    {{'button.close' | translate}}
  </button>

  <button type="button" class="btn btn-success" (click)="openRequestPermissionModal()" *ngIf="hasUsers">
    {{'button.request-permission' | translate}}
  </button>
  <button type="button" class="btn btn-primary" ngbAutofocus [disabled]="isProgressingPayment" (click)="check()" *ngIf="hasUsers">

    <i [hidden]="!isProgressingPayment" class="fa fa-circle-o-notch fa-spin"></i>
    <span [hidden]="isProgressingPayment"> {{ 'button.check' | translate}}</span>
    <span [hidden]="!isProgressingPayment"> {{'progress.please-wait' | translate}}</span>
  </button>
</div>