
import { Injectable } from '@angular/core';
import { VoipQueueStatusResponse } from 'app/models/rest/voip/voip_queue_status_response.model';
import { TelsamActiveCallResponse } from 'app/models/rest/voip/entegrator/telsam-active-call-response.model';
import { VoipVendorEnumTelsamValues } from 'app/enum/voip/voip-vendor.enum';

@Injectable({
  providedIn: 'root'
})
export class VoipVendorOperationalService {

  constructor() { }

  parseData(response: VoipQueueStatusResponse) {
    if (VoipVendorEnumTelsamValues.indexOf(response.voipVendorId) > -1) {
      return parseTelsam(response);
    }
  }
}
function parseTelsam(response: VoipQueueStatusResponse) {

  const json = response.data as string;
  let resultList: TelsamActiveCallResponse[] = JSON.parse(json);
  let result = resultList[0];

  if (!result.success) {
    throw new Error(result.text ?? "Telsam santral servis hatası");
  }

  if (result.count == 0)
    return null;
  let firstCall = result.data.firstOrDefault(x => response.internalNoList == null || response.internalNoList.indexOf(x.dst) > -1);

  if (firstCall == null)
    return null;

  response.phoneNumber = firstCall.src;
  response.internalNo = firstCall.dst;
  response.creationDate = new Date();
  return response;
}

// function parseNetgsm(response: VoipQueueStatusResponse) {
//   const json = response.data as string;
//   let result: NetgsmActiveCallResponse = JSON.parse(json);

//   if (result.status == "Error") {
//     throw new Error(result.message ?? "Netgsm santral servis hatası");
//   }


//   let phoneNumber: string;
//   if (result.queues.length == 0)
//     return null;
//   else {


//     result.queues.forEach(x => {
//       let callers = x.callers;
//       Object.keys(callers).forEach((key) => {
//         phoneNumber = key;
//       });
//     });


//     return phoneNumber;

//   }
// }


