export enum RoleCodeEnum {
  DEVELOPER = "DEVELOPER",
  ADMINISTRATOR_USER = "ADMINISTRATOR_USER",
  SUPPORT = "SUPPORT",
  ALLOW_IF_END_OF_DAY_IS_CLOSE = "ALLOW_IF_END_OF_DAY_IS_CLOSE",
  BILLING_CREATE = "BILLING_CREATE",
  BILLING_DELETE = "BILLING_DELETE",
  BILLING_SETTING_CREATE = "BILLING_SETTING_CREATE",
  BILLING_SETTING_DELETE = "BILLING_SETTING_DELETE",
  BILLING_SETTING_UPDATE = "BILLING_SETTING_UPDATE",
  BILLING_UPDATE = "BILLING_UPDATE",
  BONUS_CREATE = "BONUS_CREATE",
  BONUS_DELETE = "BONUS_DELETE",
  BONUS_UPDATE = "BONUS_UPDATE",
  BULK_PAYMENT = "BULK_PAYMENT",
  CAMPAIGN_APPLY = "CAMPAIGN_APPLY",
  CAMPAIGN_CREATE = "CAMPAIGN_CREATE",
  CAMPAIGN_DELETE = "CAMPAIGN_DELETE",
  CAMPAIGN_UPDATE = "CAMPAIGN_UPDATE",
  CASH_REGISTER_CREATE = "CASH_REGISTER_CREATE",
  CASH_REGISTER_DELETE = "CASH_REGISTER_DELETE",
  CASH_REGISTER_UPDATE = "CASH_REGISTER_UPDATE",
  CASH_REPORT_VIEW_ONE_MONTH = "CASH_REPORT_VIEW_ONE_MONTH",
  CASH_REPORT_VIEW_ONE_WEEK = "CASH_REPORT_VIEW_ONE_WEEK",
  CASH_REPORT_VIEW_THREE_MONTH = "CASH_REPORT_VIEW_THREE_MONTH",
  CASH_REPORT_VIEW_UNLIMETED = "CASH_REPORT_VIEW_UNLIMETED",
  CASH_TRANSFER_CREATE = "CASH_TRANSFER_CREATE",
  CASH_TRANSFER_DELETE = "CASH_TRANSFER_DELETE",
  CASH_TRANSFER_UPDATE = "CASH_TRANSFER_UPDATE",
  CASHIER = "CASHIER",
  CLOSE_DAY = "CLOSE_DAY",
  COMMUNITY_CREATE = "COMMUNITY_CREATE",
  COMMUNITY_DELETE = "COMMUNITY_DELETE",
  COMMUNITY_UPDATE = "COMMUNITY_UPDATE",
  CURRENT_ACCOUNT_CREATE = "CURRENT_ACCOUNT_CREATE",
  CURRENT_ACCOUNT_DELETE = "CURRENT_ACCOUNT_DELETE",
  CURRENT_ACCOUNT_UPDATE = "CURRENT_ACCOUNT_UPDATE",
  CURRENT_ACCOUNT_VIEW = "CURRENT_ACCOUNT_VIEW",
  CURRENT_ACCOUT_IS_ACCOUNTED_CANCEL = "CURRENT_ACCOUT_IS_ACCOUNTED_CANCEL",
  CURRENT_ACCOUT_IS_ACCOUNTED_CREATE = "CURRENT_ACCOUT_IS_ACCOUNTED_CREATE",
  CUSTOMER_BONUS_CREATE = "CUSTOMER_BONUS_CREATE",
  CUSTOMER_BONUS_DELETE = "CUSTOMER_BONUS_DELETE",
  CUSTOMER_BONUS_UPDATE = "CUSTOMER_BONUS_UPDATE",
  CUSTOMER_BONUS_VIEW = "CUSTOMER_BONUS_VIEW",
  CUSTOMER_BULK_PROCESS = "CUSTOMER_BULK_PROCESS",
  CUSTOMER_CAMPAIGN_VIEW = "CUSTOMER_CAMPAIGN_VIEW",
  CUSTOMER_CREATE = "CUSTOMER_CREATE",
  CUSTOMER_DELETE = "CUSTOMER_DELETE",
  CUSTOMER_EMPLOYEE_RELATION_CREATE = "CUSTOMER_EMPLOYEE_RELATION_CREATE",
  CUSTOMER_EMPLOYEE_RELATION_DELETE = "CUSTOMER_EMPLOYEE_RELATION_DELETE",
  CUSTOMER_EMPLOYEE_RELATION_UPDATE = "CUSTOMER_EMPLOYEE_RELATION_UPDATE",
  CUSTOMER_EMPLOYEE_RELATION_VIEW = "CUSTOMER_EMPLOYEE_RELATION_VIEW",
  CUSTOMER_PRODUCT_ATTRIBUTE_CREATE = "CUSTOMER_PRODUCT_ATTRIBUTE_CREATE",
  CUSTOMER_PRODUCT_ATTRIBUTE_DELETE = "CUSTOMER_PRODUCT_ATTRIBUTE_DELETE",
  CUSTOMER_PRODUCT_ATTRIBUTE_UPDATE = "CUSTOMER_PRODUCT_ATTRIBUTE_UPDATE",
  CUSTOMER_PRODUCT_ATTRIBUTE_VIEW = "CUSTOMER_PRODUCT_ATTRIBUTE_VIEW",
  CUSTOMER_TYPE_CREATE = "CUSTOMER_TYPE_CREATE",
  CUSTOMER_TYPE_DELETE = "CUSTOMER_TYPE_DELETE",
  CUSTOMER_TYPE_UPDATE = "CUSTOMER_TYPE_UPDATE",
  CUSTOMER_UPDATE = "CUSTOMER_UPDATE",
  CUSTOMER_VIEW = "CUSTOMER_VIEW",
  DEBTOR_ORDER_DELIVERY_AUTHORIZATION = "DEBTOR_ORDER_DELIVERY_AUTHORIZATION",
  DEVICE_CREATE = "DEVICE_CREATE",
  DEVICE_DELETE = "DEVICE_DELETE",
  DEVICE_PERMISSION_VIEW = "DEVICE_PERMISSION_VIEW",
  DEVICE_UPDATE = "DEVICE_UPDATE",
  DISPLAY_CUSTOMER_PHONE_NUMBER_UNMASK = "DISPLAY_CUSTOMER_PHONE_NUMBER_UNMASK",
  EMPLOYEE_CREATE = "EMPLOYEE_CREATE",
  EMPLOYEE_DELETE = "EMPLOYEE_DELETE",
  EMPLOYEE_DETAIL = "EMPLOYEE_DETAIL",
  EMPLOYEE_PAYMENT_CREATE = "EMPLOYEE_PAYMENT_CREATE",
  EMPLOYEE_PAYMENT_DELETE = "EMPLOYEE_PAYMENT_DELETE",
  EMPLOYEE_PAYMENT_UPDATE = "EMPLOYEE_PAYMENT_UPDATE",
  EMPLOYEE_UPDATE = "EMPLOYEE_UPDATE",
  EXPENSE_CREATE = "EXPENSE_CREATE",
  EXPENSE_CREATE_IN_PAST = "EXPENSE_CREATE_IN_PAST",
  EXPENSE_DELETE = "EXPENSE_DELETE",
  EXPENSE_EXTERNAL_CREATE = "EXPENSE_EXTERNAL_CREATE",
  EXPENSE_EXTERNAL_VIEW = "EXPENSE_EXTERNAL_VIEW",
  EXPENSE_TYPE_CREATE = "EXPENSE_TYPE_CREATE",
  EXPENSE_TYPE_DELETE = "EXPENSE_TYPE_DELETE",
  EXPENSE_TYPE_UPDATE = "EXPENSE_TYPE_UPDATE",
  EXPENSE_UPDATE = "EXPENSE_UPDATE",
  EXPORT_BILLING = "EXPORT_BILLING",
  EXPORT_CUSTOMER_BEST_REPORT = "EXPORT_CUSTOMER_BEST_REPORT",
  EXPORT_CUSTOMER_HAS_CURRENT_ACCOUNT_REPORT = "EXPORT_CUSTOMER_HAS_CURRENT_ACCOUNT_REPORT",
  EXPORT_CUSTOMER_HAS_DEBT_REPORT = "EXPORT_CUSTOMER_HAS_DEBT_REPORT",
  EXPORT_CUSTOMER_LIST = "EXPORT_CUSTOMER_LIST",
  EXPORT_CUSTOMER_ORDERS = "EXPORT_CUSTOMER_ORDERS",
  EXPORT_CUSTOMER_PASSIVE_REPORT = "EXPORT_CUSTOMER_PASSIVE_REPORT",
  EXPORT_CUSTOMER_PAYMENT_MOVEMENTS = "EXPORT_CUSTOMER_PAYMENT_MOVEMENTS",
  EXPORT_EXPENSE = "EXPORT_EXPENSE",
  EXPORT_FILE = "EXPORT_FILE",
  EXPORT_ORDERS = "EXPORT_ORDERS",
  EXPORT_PRODUCT_SALES_BY_ORDER_ITEM_TYPE_REPORT = "EXPORT_PRODUCT_SALES_BY_ORDER_ITEM_TYPE_REPORT",
  HANGER_TYPE_CREATE = "HANGER_TYPE_CREATE",
  HANGER_TYPE_DELETE = "HANGER_TYPE_DELETE",
  HANGER_TYPE_UPDATE = "HANGER_TYPE_UPDATE",
  LABEL_SETTING_CREATE = "LABEL_SETTING_CREATE",
  LABEL_SETTING_DELETE = "LABEL_SETTING_DELETE",
  LABEL_SETTING_UPDATE = "LABEL_SETTING_UPDATE",
  LOYALTY_CARD_CREATE = "LOYALTY_CARD_CREATE",
  LOYALTY_CARD_DELETE = "LOYALTY_CARD_DELETE",
  LOYALTY_CARD_TYPE_CREATE = "LOYALTY_CARD_TYPE_CREATE",
  LOYALTY_CARD_TYPE_DELETE = "LOYALTY_CARD_TYPE_DELETE",
  LOYALTY_CARD_TYPE_UPDATE = "LOYALTY_CARD_TYPE_UPDATE",
  LOYALTY_CARD_UPDATE = "LOYALTY_CARD_UPDATE",
  MAIL_SETTING_CREATE = "MAIL_SETTING_CREATE",
  MAIL_SETTING_DELETE = "MAIL_SETTING_DELETE",
  MAIL_SETTING_UPDATE = "MAIL_SETTING_UPDATE",
  MANAGER = "MANAGER",
  MENU_ADVANCED_REPORT = "MENU_ADVANCED_REPORT",
  MENU_BILLING = "MENU_BILLING",
  MENU_BILLING_SETTING = "MENU_BILLING_SETTING",
  MENU_BONUS = "MENU_BONUS",
  MENU_CALLERID_HISTORY_REPORT = "MENU_CALLERID_HISTORY_REPORT",
  MENU_CAMPAIGN = "MENU_CAMPAIGN",
  MENU_CASH_REGISTER = "MENU_CASH_REGISTER",
  MENU_CASH_REGISTER_PROVISION = "MENU_CASH_REGISTER_PROVISION",
  MENU_CASH_REPORT = "MENU_CASH_REPORT",
  MENU_CASH_TRANSFER = "MENU_CASH_TRANSFER",
  MENU_COMMUNITY = "MENU_COMMUNITY",
  MENU_CUSTOMER_ADD = "MENU_CUSTOMER_ADD",
  MENU_CUSTOMER_APP = "MENU_CUSTOMER_APP",
  MENU_CUSTOMER_BEST_REPORT = "MENU_CUSTOMER_BEST_REPORT",
  MENU_CUSTOMER_BONUS = "MENU_CUSTOMER_BONUS",
  MENU_CUSTOMER_DUPLICATED_REPORT = "MENU_CUSTOMER_DUPLICATED_REPORT",
  MENU_CUSTOMER_HAS_BONUS_REPORT = "MENU_CUSTOMER_HAS_BONUS_REPORT",
  MENU_CUSTOMER_HAS_CURRENT_ACCOUNT_REPORT = "MENU_CUSTOMER_HAS_CURRENT_ACCOUNT_REPORT",
  MENU_CUSTOMER_HAS_DEBT_REPORT = "MENU_CUSTOMER_HAS_DEBT_REPORT",
  MENU_CUSTOMER_LIST = "MENU_CUSTOMER_LIST",
  MENU_CUSTOMER_MANAGEMENT = "MENU_CUSTOMER_MANAGEMENT",
  MENU_CUSTOMER_ORDER_STATISTIC = "MENU_CUSTOMER_ORDER_STATISTIC",
  MENU_CUSTOMER_PASSIVE_REPORT = "MENU_CUSTOMER_PASSIVE_REPORT",
  MENU_CUSTOMER_SEARCH = "MENU_CUSTOMER_SEARCH",
  MENU_CUSTOMER_SEARCH_ADVANCE = "MENU_CUSTOMER_SEARCH_ADVANCE",
  MENU_CUSTOMER_TYPE = "MENU_CUSTOMER_TYPE",
  MENU_CUSTOMER_UNDELIVERED_ORDER_REPORT = "MENU_CUSTOMER_UNDELIVERED_ORDER_REPORT",
  MENU_DASHBOARD = "MENU_DASHBOARD",
  MENU_DEFINITION = "MENU_DEFINITION",
  MENU_DEVELOPMENT_DEMAND = "MENU_DEVELOPMENT_DEMAND",
  MENU_DEVICE = "MENU_DEVICE",
  MENU_DEVICE_SETTING = "MENU_DEVICE_SETTING",
  MENU_DISPATCH_ORDER_ITEMS = "MENU_DISPATCH_ORDER_ITEMS",
  MENU_EMPLOYEE = "MENU_EMPLOYEE",
  MENU_EMPLOYEE_PAYMENT_DETAIL = "MENU_EMPLOYEE_PAYMENT_DETAIL",
  MENU_EXPENSE = "MENU_EXPENSE",
  MENU_EXPENSE_TYPE = "MENU_EXPENSE_TYPE",
  MENU_FAVORITE = "MENU_FAVORITE",
  MENU_GENERAL_LOG_REPORT = "MENU_GENERAL_LOG_REPORT",
  MENU_HANGER_TYPE = "MENU_HANGER_TYPE",
  MENU_LABEL_SETTING = "MENU_LABEL_SETTING",
  MENU_LOGOUT = "MENU_LOGOUT",
  MENU_LOYALTY = "MENU_LOYALTY",
  MENU_LOYALTY_CARD = "MENU_LOYALTY_CARD",
  MENU_LOYALTY_CARD_TYPE = "MENU_LOYALTY_CARD_TYPE",
  MENU_MAIL_SETTING = "MENU_MAIL_SETTING",
  MENU_NOTE = "MENU_NOTE",
  MENU_NOTIFICATION_PREFERENCE = "MENU_NOTIFICATION_PREFERENCE",
  MENU_ONLINE_COLLECTION = "MENU_ONLINE_COLLECTION",
  MENU_ONLINE_MERCHANT = "MENU_ONLINE_MERCHANT",
  MENU_OPERATIONAL_DEMAND = "MENU_OPERATIONAL_DEMAND",
  MENU_ORDER_ADDITIONAL_DISCOUNT = "MENU_ORDER_ADDITIONAL_DISCOUNT",
  MENU_ORDER_HANGER_REFUND = "MENU_ORDER_HANGER_REFUND",
  MENU_ORDER_ITEM_TYPE = "MENU_ORDER_ITEM_TYPE",
  MENU_ORDER_LIST = "MENU_ORDER_LIST",
  MENU_ORDER_SEARCH = "MENU_ORDER_SEARCH",
  MENU_OWNER_SALES_PRODUCT = "MENU_OWNER_SALES_PRODUCT",
  MENU_PACK = "MENU_PACK",
  MENU_PACK_PROCESS = "MENU_PACK_PROCESS",
  MENU_PAYMENT_MOVEMENT = "MENU_PAYMENT_MOVEMENT",
  MENU_PAYROLL = "MENU_PAYROLL",
  MENU_PAYROLL_SETTING = "MENU_PAYROLL_SETTING",
  MENU_POS_DEVICE = "MENU_POS_DEVICE",
  MENU_PRICE_LIST = "MENU_PRICE_LIST",
  MENU_PRINTER = "MENU_PRINTER",
  MENU_PROCESS_ORDER_ITEMS = "MENU_PROCESS_ORDER_ITEMS",
  MENU_PRODUCT = "MENU_PRODUCT",
  MENU_PRODUCT_PACK = "MENU_PRODUCT_PACK",
  MENU_PRODUCT_PRICE = "MENU_PRODUCT_PRICE",
  MENU_PRODUCT_SALES_BY_ORDER_ITEM_TYPE_REPORT = "MENU_PRODUCT_SALES_BY_ORDER_ITEM_TYPE_REPORT",
  MENU_PRODUCT_SCREEN_ORDER = "MENU_PRODUCT_SCREEN_ORDER",
  MENU_PUSH_NOTIFICATION_TEMPLATE = "MENU_PUSH_NOTIFICATION_TEMPLATE",
  MENU_RECEIPT_SETTING = "MENU_RECEIPT_SETTING",
  MENU_REFRESH_TOKEN_HISTORY_REPORT = "MENU_REFRESH_TOKEN_HISTORY_REPORT",
  MENU_REGION = "MENU_REGION",
  MENU_REMINDER = "MENU_REMINDER",
  MENU_REPORT = "MENU_REPORT",
  MENU_SEARCH_ORDER_ITEMS = "MENU_SEARCH_ORDER_ITEMS",
  MENU_SEGMENT_VALUE = "MENU_SEGMENT_VALUE",
  MENU_SEND_SMS = "MENU_SEND_SMS",
  MENU_SERVICE_CAR = "MENU_SERVICE_CAR",
  MENU_SERVICE_ORDERS = "MENU_SERVICE_ORDERS",
  MENU_SETTING = "MENU_SETTING",
  MENU_SMS_LOG_REPORT = "MENU_SMS_LOG_REPORT",
  MENU_SMS_SETTING = "MENU_SMS_SETTING",
  MENU_SMS_TEMPLATE = "MENU_SMS_TEMPLATE",
  MENU_STOCK_TRACKING = "MENU_STOCK_TRACKING",
  MENU_STOCK_TRACKING_ORDER_RELATION = "MENU_STOCK_TRACKING_ORDER_RELATION",
  MENU_STORAGE_COST_SETTING = "MENU_STORAGE_COST_SETTING",
  MENU_STORE = "MENU_STORE",
  MENU_STORE_CITY_COVERAGE = "MENU_STORE_CITY_COVERAGE",
  MENU_STORE_DAILY_REPORT = "MENU_STORE_DAILY_REPORT",
  MENU_STORE_HANGER_TYPE = "MENU_STORE_HANGER_TYPE",
  MENU_STORE_MANAGEMENT = "MENU_STORE_MANAGEMENT",
  MENU_STORE_SETTING = "MENU_STORE_SETTING",
  MENU_STORE_TEMPLATE = "MENU_STORE_TEMPLATE",
  MENU_SUPPLIER = "MENU_SUPPLIER",
  MENU_USER = "MENU_USER",
  MENU_USER_ROLE = "MENU_USER_ROLE",
  MENU_VOIP = "MENU_VOIP",
  MENU_VOIP_INTERNAL = "MENU_VOIP_INTERNAL",
  MENU_WEB_IMAGE_URL = "MENU_WEB_IMAGE_URL",
  MENU_ZREPORT = "MENU_ZREPORT",
  NOTE_CREATE = "NOTE_CREATE",
  NOTE_DELETE = "NOTE_DELETE",
  NOTE_UPDATE = "NOTE_UPDATE",
  ONLINE_COLLECTION_CREATE = "ONLINE_COLLECTION_CREATE",
  ONLINE_COLLECTION_DELETE = "ONLINE_COLLECTION_DELETE",
  ONLINE_COLLECTION_UPDATE = "ONLINE_COLLECTION_UPDATE",
  ONLINE_MERCHANT_CREATE = "ONLINE_MERCHANT_CREATE",
  ONLINE_MERCHANT_DELETE = "ONLINE_MERCHANT_DELETE",
  ONLINE_MERCHANT_UPDATE = "ONLINE_MERCHANT_UPDATE",
  OPEN_DAY = "OPEN_DAY",
  ORDER_ADDITIONAL_DISCOUNT_CREATE = "ORDER_ADDITIONAL_DISCOUNT_CREATE",
  ORDER_ADDITIONAL_DISCOUNT_DELETE = "ORDER_ADDITIONAL_DISCOUNT_DELETE",
  ORDER_ADDITIONAL_DISCOUNT_UPDATE = "ORDER_ADDITIONAL_DISCOUNT_UPDATE",
  ORDER_CANCEL = "ORDER_CANCEL",
  ORDER_CANCEL_FOR_PICKUP = "ORDER_CANCEL_FOR_PICKUP",
  ORDER_CANCEL_IN_SAME_DAY = "ORDER_CANCEL_IN_SAME_DAY",
  ORDER_CANCEL_SERVICE = "ORDER_CANCEL_SERVICE",
  ORDER_CHANGE_DATE = "ORDER_CHANGE_DATE",
  ORDER_CHANGE_STORE = "ORDER_CHANGE_STORE",
  ORDER_COMPLETED = "ORDER_COMPLETED",
  ORDER_CREATE = "ORDER_CREATE",
  ORDER_DELETE = "ORDER_DELETE",
  ORDER_DELIVERED = "ORDER_DELIVERED",
  ORDER_HANGER_REFUND_CREATE = "ORDER_HANGER_REFUND_CREATE",
  ORDER_HANGER_REFUND_DELETE = "ORDER_HANGER_REFUND_DELETE",
  ORDER_HANGER_REFUND_UPDATE = "ORDER_HANGER_REFUND_UPDATE",
  ORDER_HANGER_REFUND_VIEW = "ORDER_HANGER_REFUND_VIEW",
  ORDER_HISTORY = "ORDER_HISTORY",
  ORDER_ITEM_DELIVERY = "ORDER_ITEM_DELIVERY",
  ORDER_ITEM_DETAIL_SCREEN_PRICE_MODIFY_LESS = "ORDER_ITEM_DETAIL_SCREEN_PRICE_MODIFY_LESS",
  ORDER_ITEM_DETAIL_SCREEN_PRICE_MODIFY_MORE = "ORDER_ITEM_DETAIL_SCREEN_PRICE_MODIFY_MORE",
  ORDER_ITEM_IMAGE_CREATE = "ORDER_ITEM_IMAGE_CREATE",
  ORDER_ITEM_IMAGE_DELETE = "ORDER_ITEM_IMAGE_DELETE",
  ORDER_ITEM_IMAGE_UPDATE = "ORDER_ITEM_IMAGE_UPDATE",
  ORDER_ITEM_IMAGE_VIEW = "ORDER_ITEM_IMAGE_VIEW",
  ORDER_ITEM_NOT_READY = "ORDER_ITEM_NOT_READY",
  ORDER_ITEM_READY = "ORDER_ITEM_READY",
  ORDER_ITEM_TYPE_CREATE = "ORDER_ITEM_TYPE_CREATE",
  ORDER_ITEM_TYPE_DELETE = "ORDER_ITEM_TYPE_DELETE",
  ORDER_ITEM_TYPE_UPDATE = "ORDER_ITEM_TYPE_UPDATE",
  ORDER_MODIFY = "ORDER_MODIFY",
  ORDER_MODIFY_AMOUNT_AFTER_CREATED = "ORDER_MODIFY_AMOUNT_AFTER_CREATED",
  ORDER_MODIFY_IN_SAME_DAY = "ORDER_MODIFY_IN_SAME_DAY",
  ORDER_MODIFY_LESS_AMOUNT_AFTER_CREATED = "ORDER_MODIFY_LESS_AMOUNT_AFTER_CREATED",
  ORDER_READY = "ORDER_READY",
  ORDER_SEND_SERVICE = "ORDER_SEND_SERVICE",
  ORDER_STORAGE_COST_CREATE = "ORDER_STORAGE_COST_CREATE",
  ORDER_STORAGE_COST_DELETE = "ORDER_STORAGE_COST_DELETE",
  ORDER_STORAGE_COST_UPDATE = "ORDER_STORAGE_COST_UPDATE",
  ORDER_UNCOMPLETED = "ORDER_UNCOMPLETED",
  ORDER_UPDATE = "ORDER_UPDATE",
  ORDER_VIEW = "ORDER_VIEW",
  PACK_CREATE = "PACK_CREATE",
  PACK_DELETE = "PACK_DELETE",
  PACK_UPDATE = "PACK_UPDATE",
  PAYMENT_DETAIL_CHANGE_DATE = "PAYMENT_DETAIL_CHANGE_DATE",
  PAYMENT_DETAIL_CHANGE_TYPE = "PAYMENT_DETAIL_CHANGE_TYPE",
  PAYMENT_DETAIL_CHANGE_TYPE_IN_SAME_DATE = "PAYMENT_DETAIL_CHANGE_TYPE_IN_SAME_DATE",
  PAYMENT_DETAIL_CREATE = "PAYMENT_DETAIL_CREATE",
  PAYMENT_DETAIL_CREATE_BONUS = "PAYMENT_DETAIL_CREATE_BONUS",
  PAYMENT_DETAIL_CREATE_CASH = "PAYMENT_DETAIL_CREATE_CASH",
  PAYMENT_DETAIL_CREATE_CHEQUE = "PAYMENT_DETAIL_CREATE_CHEQUE",
  PAYMENT_DETAIL_CREATE_CREDIT_CARD = "PAYMENT_DETAIL_CREATE_CREDIT_CARD",
  PAYMENT_DETAIL_CREATE_CURRENT_ACCOUNT = "PAYMENT_DETAIL_CREATE_CURRENT_ACCOUNT",
  PAYMENT_DETAIL_CREATE_EFT = "PAYMENT_DETAIL_CREATE_EFT",
  PAYMENT_DETAIL_CREATE_FREE = "PAYMENT_DETAIL_CREATE_FREE",
  PAYMENT_DETAIL_CREATE_VOUCHER = "PAYMENT_DETAIL_CREATE_VOUCHER",
  PAYMENT_DETAIL_DELETE = "PAYMENT_DETAIL_DELETE",
  PAYMENT_DETAIL_UPDATE = "PAYMENT_DETAIL_UPDATE",
  PAYMENT_DETAIL_VIEW = "PAYMENT_DETAIL_VIEW",
  PAYROLL_CREATE = "PAYROLL_CREATE",
  PAYROLL_UPDATE = "PAYROLL_UPDATE",
  POS_DEVICE_CREATE = "POS_DEVICE_CREATE",
  POS_DEVICE_DELETE = "POS_DEVICE_DELETE",
  POS_DEVICE_UPDATE = "POS_DEVICE_UPDATE",
  PRICE_LIST_CREATE = "PRICE_LIST_CREATE",
  PRICE_LIST_DELETE = "PRICE_LIST_DELETE",
  PRICE_LIST_UPDATE = "PRICE_LIST_UPDATE",
  PRODUCT_CREATE = "PRODUCT_CREATE",
  PRODUCT_DELETE = "PRODUCT_DELETE",
  PRODUCT_PRICE_CREATE = "PRODUCT_PRICE_CREATE",
  PRODUCT_PRICE_DELETE = "PRODUCT_PRICE_DELETE",
  PRODUCT_PRICE_UPDATE = "PRODUCT_PRICE_UPDATE",
  PRODUCT_UPDATE = "PRODUCT_UPDATE",
  PRODUCT_CHANGE_SCREEN_ORDER_BY_SALES = "PRODUCT_CHANGE_SCREEN_ORDER_BY_SALES",
  PUSH_NOTIFICATION_PREFERENCE_CREATE = "PUSH_NOTIFICATION_PREFERENCE_CREATE",
  PUSH_NOTIFICATION_PREFERENCE_DELETE = "PUSH_NOTIFICATION_PREFERENCE_DELETE",
  PUSH_NOTIFICATION_PREFERENCE_UPDATE = "PUSH_NOTIFICATION_PREFERENCE_UPDATE",
  PUSH_NOTIFICATION_TEMPLATE_CREATE = "PUSH_NOTIFICATION_TEMPLATE_CREATE",
  PUSH_NOTIFICATION_TEMPLATE_DELETE = "PUSH_NOTIFICATION_TEMPLATE_DELETE",
  PUSH_NOTIFICATION_TEMPLATE_UPDATE = "PUSH_NOTIFICATION_TEMPLATE_UPDATE",
  RECEIPT_SETTING_CREATE = "RECEIPT_SETTING_CREATE",
  RECEIPT_SETTING_DELETE = "RECEIPT_SETTING_DELETE",
  RECEIPT_SETTING_UPDATE = "RECEIPT_SETTING_UPDATE",
  REGION_CREATE = "REGION_CREATE",
  REGION_DELETE = "REGION_DELETE",
  REGION_UPDATE = "REGION_UPDATE",
  REMINDER_CREATE = "REMINDER_CREATE",
  REMINDER_DELETE = "REMINDER_DELETE",
  REMINDER_UPDATE = "REMINDER_UPDATE",
  SAVE_CUSTOMER_PHONES_TO_DEVICE = "SAVE_CUSTOMER_PHONES_TO_DEVICE",
  SERVICE_CAR_CREATE = "SERVICE_CAR_CREATE",
  SERVICE_CAR_DELETE = "SERVICE_CAR_DELETE",
  SERVICE_CAR_UPDATE = "SERVICE_CAR_UPDATE",
  SMS_SETTING_CREATE = "SMS_SETTING_CREATE",
  SMS_SETTING_DELETE = "SMS_SETTING_DELETE",
  SMS_SETTING_UPDATE = "SMS_SETTING_UPDATE",
  SMS_TEMPLATE_CREATE = "SMS_TEMPLATE_CREATE",
  SMS_TEMPLATE_DELETE = "SMS_TEMPLATE_DELETE",
  SMS_TEMPLATE_UPDATE = "SMS_TEMPLATE_UPDATE",
  STOCK_TRACKING_CREATE = "STOCK_TRACKING_CREATE",
  STOCK_TRACKING_DELETE = "STOCK_TRACKING_DELETE",
  STOCK_TRACKING_ORDER_RELATION_CREATE = "STOCK_TRACKING_ORDER_RELATION_CREATE",
  STOCK_TRACKING_ORDER_RELATION_DELETE = "STOCK_TRACKING_ORDER_RELATION_DELETE",
  STOCK_TRACKING_ORDER_RELATION_UPDATE = "STOCK_TRACKING_ORDER_RELATION_UPDATE",
  STOCK_TRACKING_UPDATE = "STOCK_TRACKING_UPDATE",
  STORE_ALLOW_DEVICE_AUTH = "STORE_ALLOW_DEVICE_AUTH",
  STORE_CITY_COVERAGE_CREATE = "STORE_CITY_COVERAGE_CREATE",
  STORE_CITY_COVERAGE_DELETE = "STORE_CITY_COVERAGE_DELETE",
  STORE_CITY_COVERAGE_UPDATE = "STORE_CITY_COVERAGE_UPDATE",
  STORE_CREATE = "STORE_CREATE",
  STORE_DELETE = "STORE_DELETE",
  STORE_HANGER_TYPE_CREATE = "STORE_HANGER_TYPE_CREATE",
  STORE_HANGER_TYPE_DELETE = "STORE_HANGER_TYPE_DELETE",
  STORE_HANGER_TYPE_UPDATE = "STORE_HANGER_TYPE_UPDATE",
  STORE_SETTING_CREATE = "STORE_SETTING_CREATE",
  STORE_SETTING_DELETE = "STORE_SETTING_DELETE",
  STORE_SETTING_UPDATE = "STORE_SETTING_UPDATE",
  STORE_TEMPLATE_CREATE = "STORE_TEMPLATE_CREATE",
  STORE_TEMPLATE_DELETE = "STORE_TEMPLATE_DELETE",
  STORE_TEMPLATE_UPDATE = "STORE_TEMPLATE_UPDATE",
  STORE_UPDATE = "STORE_UPDATE",
  SUPPLIER_CREATE = "SUPPLIER_CREATE",
  SUPPLIER_DELETE = "SUPPLIER_DELETE",
  SUPPLIER_UPDATE = "SUPPLIER_UPDATE",
  TEST = "TEST",
  UNLIMETED_DISCOUNT_AUTH = "UNLIMETED_DISCOUNT_AUTH",
  UPDATE_ORDER_SERVICE_FEE = "UPDATE_ORDER_SERVICE_FEE",
  USER_CREATE = "USER_CREATE",
  USER_DELETE = "USER_DELETE",
  USER_ROLE_CODE_CREATE = "USER_ROLE_CODE_CREATE",
  USER_ROLE_CODE_DELETE = "USER_ROLE_CODE_DELETE",
  USER_ROLE_CODE_UPDATE = "USER_ROLE_CODE_UPDATE",
  USER_ROLE_CODE_VIEW = "USER_ROLE_CODE_VIEW",
  USER_UPDATE = "USER_UPDATE",
  VOIP_CREATE = "VOIP_CREATE",
  VOIP_DELETE = "VOIP_DELETE",
  VOIP_INTERNAL_CREATE = "VOIP_INTERNAL_CREATE",
  VOIP_INTERNAL_DELETE = "VOIP_INTERNAL_DELETE",
  VOIP_INTERNAL_UPDATE = "VOIP_INTERNAL_UPDATE",
  VOIP_UPDATE = "VOIP_UPDATE",
  WEB_IMAGE_URL_CREATE = "WEB_IMAGE_URL_CREATE",
  WEB_IMAGE_URL_DELETE = "WEB_IMAGE_URL_DELETE",
  WEB_IMAGE_URL_UPDATE = "WEB_IMAGE_URL_UPDATE",
  ZREPORT_CREATE = "ZREPORT_CREATE",
  ZREPORT_DELETE = "ZREPORT_DELETE",
  ZREPORT_UPDATE = "ZREPORT_UPDATE",

  EXPORT_PAYROLL = "EXPORT_PAYROLL",
  EXPORT_CUSTOMER_HAS_BONUS_REPORT = "EXPORT_CUSTOMER_HAS_BONUS_REPORT",
  EXPORT_STORE_REPORT = "EXPORT_STORE_REPORT",
  ORDER_COMMISSION_VIEW = "ORDER_COMMISSION_VIEW",
  CARRIED_FORWARD_DEBT_VIEW = "CARRIED_FORWARD_DEBT_VIEW",

}
