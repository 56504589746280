import { BaseEntity } from "../entity/baseEntity";

export class HelpVideoUrlModel extends BaseEntity {
  id?: number;
  title?: string;
  description?: string;
  url?: string;
  tags?: string;
  screenOrderNo?: number;
}
