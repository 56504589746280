<div class="modal-header text-white" [ngClass]="{'bg-primary':modalType==yesNoModalTypeEnum.PRIMARY,
'bg-danger':modalType==yesNoModalTypeEnum.DANGER,
'bg-warning':modalType==yesNoModalTypeEnum.WARNING,
'bg-success':modalType==yesNoModalTypeEnum.SUCCESS}">
  <h4 class="modal-title text-white" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="btn-close text-black" aria-label="Close" (click)="close()">

  </button>
</div>

<div class="modal-body">
  <!-- Icon Section -->
  <div class="text-center" [ngClass]="{
  'text-primary': modalType == yesNoModalTypeEnum.PRIMARY,
  'text-danger': modalType == yesNoModalTypeEnum.DANGER,
  'text-warning': modalType == yesNoModalTypeEnum.WARNING,
  'text-success': modalType == yesNoModalTypeEnum.SUCCESS
  }">
    <i class="fa fa-4x fa-check mb-3 animated rotateIn "></i>
  </div>
  <div [ngClass]="{'alert-primary': modalType == yesNoModalTypeEnum.PRIMARY,
  'alert-danger': modalType == yesNoModalTypeEnum.DANGER,
  'alert-warning': modalType == yesNoModalTypeEnum.WARNING,
  'alert-success': modalType == yesNoModalTypeEnum.SUCCESS}" class="alert" role="alert">



    <!-- Message Section -->
    <p [innerHTML]="message | newline"></p>
  </div>


  <form #createForm="ngForm" class="needs-validation" novalidate>
    <div class="card" *ngIf="modalInputType==yesNoModalInputTypeEnum.TEXT">
      <div class="card-body">

        <!-- Notes -->
        <div class="col">
          <input #notesInput="ngModel" type="text" class="form-control" name="notesInput" [(ngModel)]="notes" [placeholder]="placeholder" required
            [class.is-invalid]="notesInput.invalid && notesInput.touched" ngbAutoFocus />
          <!-- <label class="custom-form-label"  for="notesInput">{{'common.notes' | translate}}</label> -->
          <app-field-error-display [displayError]="notesInput.invalid && notesInput.touched" errorMsg="{{'message.error.cannot-be-blank' | translate}}">
          </app-field-error-display>

        </div>

      </div>
    </div>
  </form>
</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-outline-primary waves-effect" [ngClass]="{'btn-outline-primary':modalType==yesNoModalTypeEnum.PRIMARY,
'btn-outline-danger':modalType==yesNoModalTypeEnum.DANGER,
'btn-outline-warning':modalType==yesNoModalTypeEnum.WARNING,
'btn-outline-success':modalType==yesNoModalTypeEnum.SUCCESS}" (click)="close()">
    {{'button.no' | translate}} <i class="fa fa-window-close ml-1 "></i>
  </button>
  <button type="button" class="btn btn-primary" [disabled]="createForm.form.invalid" [ngClass]="{'btn-primary':modalType==yesNoModalTypeEnum.PRIMARY,
'btn-danger':modalType==yesNoModalTypeEnum.DANGER,
'btn-warning':modalType==yesNoModalTypeEnum.WARNING,
'btn-success':modalType==yesNoModalTypeEnum.SUCCESS}" (click)="accept()">
    {{'button.yes' | translate}} <i class="fa fa-check ml-1 "></i>
  </button>
</div>