import { Injectable } from '@angular/core';
import { isNullOrEmpty } from 'app/helper/utils';
import { StorageConstants } from 'app/constant/storage.constant';
import { DeviceLocalStorageData } from 'app/models/rest/user/device-local-storage-data.model';
import { LocalLogModel } from 'app/models/dto/local-log.model';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }
  getString(key: string, defValue?: string) {
    let value = sessionStorage.getItem(key);
    return isNullOrEmpty(value) ? defValue : value;
  }
  setString(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  getNumber(key: string, defValue?: number) {
    let value = sessionStorage.getItem(key);
    return isNullOrEmpty(value) ? defValue : Number(value);
  }

  setNumber(key: string, value: number) {
    sessionStorage.setItem(key, value == null ? null : value.toString());
  }


  getBoolean(key: string, defValue?: boolean) {
    let value = sessionStorage.getItem(key);
    return isNullOrEmpty(value) ? defValue : Boolean(value);
  }

  setBoolean(key: string, value: boolean) {
    sessionStorage.setItem(key, value == null ? null : value.toString());
  }

  getJSONData(key: string) {
    let value = sessionStorage.getItem(key);

    return JSON.parse(value);
  }
  setJSONData(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getDeviceStorageData() {

    let data = this.getJSONData(StorageConstants.DEVICE_LOCAL_STORAGE);
    if (data == null) {
      data = new DeviceLocalStorageData();
      this.setJSONData(StorageConstants.DEVICE_LOCAL_STORAGE, data);

    }
    return data;
  }

  remove(key: string) {
    sessionStorage.removeItem(key);
  }


  addLocalLog(logType: string, message?: string) {

    let dataJson = this.getJSONData(StorageConstants.LOCAL_LOG);

    let dataList: LocalLogModel[] = [];
    if (dataJson == null) {
      dataList = [];
    }
    else {
      dataList = dataJson;
    }

    let newLog: LocalLogModel = new LocalLogModel();
    newLog.logDate = new Date();
    newLog.logType = logType;
    newLog.message = message;

    if (dataList.length > 100)
      dataList.remove(dataList[0]);
    dataList.push(newLog);

    this.setJSONData(StorageConstants.LOCAL_LOG, dataList);
  }

}
