<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ title}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>

<div class="modal-body  text-center">
  <form #createForm="ngForm" class="needs-validation" novalidate>
    <div class="form-group">

      <div class="col-sm-12">
        <p-calendar #startDate="ngModel" name="startDate" [(ngModel)]="data" [showTime]="true" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
          [dateFormat]="dateFormatEnum.DATE_TIME_PICKER_INPUT_FORMAT" [firstDayOfWeek]="'firstDayOfWeek'| translate">
        </p-calendar>
      </div>

      <app-field-error-display [displayError]="startDate.invalid && startDate.touched" errorMsg="{{'message.error.cannot-be-blank' | translate}}">
      </app-field-error-display>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">
    {{'button.close' | translate}}
  </button>
  <button class="btn btn-primary me-2" (click)="save()">
    <i class="fa fa-add" aria-hidden="true"></i>
    {{'button.save' | translate}}
  </button>
</div>